import { useRef } from "react";
import Card from "../Card";
import IconBags from "../Icons/Bags";
import IconGem from "../Icons/Gem";
import IconTelescope from "../Icons/Telescope";
import classes from "./index.module.scss";

const { loans, loans__title, loans__subtext, loans__cards } = classes;

function MainLoans({ data, blockIndex = -1, editable = false, onChange = () => {}, onClick = () => {} }) {

   const defaultSubText = useRef(data.post_text);

   const icons = [<IconBags />, <IconTelescope />, <IconGem />];
   
   const changeSubText = (e) => {
      onChange(blockIndex, "post_text", e.target.innerHTML);
   }

   const startEditingCard = () => {
      onClick(blockIndex, "card");
   }

   return (
      <section className={loans}>
         <h2 className={loans__title}>{data.title}</h2>
         <div className={loans__cards}>
            {data.loans.map((item, key) => (
               <Card
                  amount={item.amount}
                  duration={item.duration}
                  rate={item.rate}
                  icon={icons[key]}
                  key={key}

                  editable = {editable}
                  onChange={(param, value) => onChange(blockIndex, "loans." + key + "." + param, value)}
               />
            ))}
         </div>
         <span
            className={loans__subtext}
            
            contentEditable={editable} suppressContentEditableWarning={true}
            value={data.post_text}
            onInput={changeSubText}
            onBlur={changeSubText}
         >
            {defaultSubText.current}
         </span>
      </section>
   );
}

export default MainLoans;
