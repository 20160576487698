import { useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";

import scss from "./index.module.scss";

const { help, help__title, help__description, list, list__item, form, form__textarea } =
   scss;

function Support() {
   const [topic, setTopic] = useState("");
   const [text, setText] = useState("");
   const [topicError, setTopicError] = useState("");
   const [textError, setTextError] = useState("");
   const [wait, setWait] = useState(false);

   const onSubmit = async (e) => {
      setWait(true);
      e.preventDefault();
      setTextError(validate(text ? text : "") ? "" : "Поле содержет ошибку или пустое");
      setTopicError(
         validate(topic ? topic : "") ? "" : "Поле содержет ошибку или пустое"
      );
      if (topic && text) {
         const auth = checkAuth();
         if (auth) {
            const body = new FormData();
            body.append("topic", topic);
            body.append("msg", text);
            const response = await new api("manage/support", auth, {
               method: "POST",
               body,
            }).send();
            if (response.status === "success") {
               setTopic("");
               setText("");
               setTextError("");
               setTopicError("");
            }
         }
      }
      setWait(false);
   };

   const validate = (text) => {
      let regexp = /^[A-Za-zА-Яа-яЁё0-9\s\.\,\-\(\)]{3,}$/.test(text);
      return regexp ? true : false;
   };

   return (
      <section className={help}>
         <h2 className={help__title}>Техническая поддержка</h2>
         <p className={help__description}>
            Опишите проблему, с которой вы столкнулись в процессе использования системы
            модерации. Для наиболее полного понимания проблемы, с которой вы столкнулись
            нам будут необходимы несколько моментов.
         </p>
         <ol className={list}>
            <li className={list__item}>
               Опишите как можно подробнее ваши действия до возникновения ошибки.
               (Например: "1. Я вошел в систему. 2. Нажал на кнопку 'Заявки'... и т.д.")
            </li>
            <li className={list__item}>
               Используйте нумерацию и пунктуацию, чтобы описать проблему или проблемы.
            </li>
            <li className={list__item}>
               Мы постараемся как можно скорее разобраться с проблемой.
            </li>
         </ol>
         <form className={form} action="" method="post" onSubmit={onSubmit}>
            <Input
               label={"Тема обращения"}
               placeholder={"Ошибка отображения страницы"}
               type={"text"}
               value={topic}
               onChange={setTopic}
               error={topicError}
               disabled={wait}
            />
            <Input
               className={form__textarea}
               label={"Опишите проблему"}
               type="textarea"
               value={text}
               onChange={(e) => setText(e[0])}
               error={textError}
               disabled={wait}
            />
            <Button isWaiting={wait} type="submit">
               Отправить заявку
            </Button>
         </form>
      </section>
   );
}

export default Support;
