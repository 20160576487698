const IconLoadPhoto = props => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M32.0007 33.3333C33.4673 33.3333 34.7233 32.8107 35.7687 31.7653C36.8122 30.7218 37.334 29.4667 37.334 28C37.334 26.5333 36.8122 25.2773 35.7687 24.232C34.7233 23.1884 33.4673 22.6667 32.0007 22.6667C30.534 22.6667 29.2789 23.1884 28.2353 24.232C27.19 25.2773 26.6673 26.5333 26.6673 28C26.6673 29.4667 27.19 30.7218 28.2353 31.7653C29.2789 32.8107 30.534 33.3333 32.0007 33.3333Z" fill="#808080"/>
        <path d="M21.334 42.5333C21.334 43.3434 21.9906 44 22.8007 44H41.2007C42.0107 44 42.6673 43.3434 42.6673 42.5333C42.6673 40.5333 41.6895 38.944 39.734 37.7653C37.7784 36.5884 35.2007 36 32.0007 36C28.8007 36 26.2229 36.5884 24.2673 37.7653C22.3118 38.944 21.334 40.5333 21.334 42.5333Z" fill="#808080"/>
        <path d="M32.0007 33.3333C33.4673 33.3333 34.7233 32.8107 35.7687 31.7653C36.8122 30.7218 37.334 29.4667 37.334 28C37.334 26.5333 36.8122 25.2773 35.7687 24.232C34.7233 23.1884 33.4673 22.6667 32.0007 22.6667C30.534 22.6667 29.2789 23.1884 28.2353 24.232C27.19 25.2773 26.6673 26.5333 26.6673 28C26.6673 29.4667 27.19 30.7218 28.2353 31.7653C29.2789 32.8107 30.534 33.3333 32.0007 33.3333Z" stroke="#808080" strokeWidth="2.66667"/>
        <path d="M21.334 42.5333C21.334 43.3434 21.9906 44 22.8007 44H41.2007C42.0107 44 42.6673 43.3434 42.6673 42.5333C42.6673 40.5333 41.6895 38.944 39.734 37.7653C37.7784 36.5884 35.2007 36 32.0007 36C28.8007 36 26.2229 36.5884 24.2673 37.7653C22.3118 38.944 21.334 40.5333 21.334 42.5333Z" stroke="#808080" strokeWidth="2.66667"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.6673 51.5653C9.20065 51.5653 7.94554 51.0436 6.90198 50C5.85665 48.9547 5.33398 47.6987 5.33398 46.232V22.6667C5.33398 21.2 5.85665 19.9449 6.90198 18.9013C7.94554 17.856 9.20065 17.3333 10.6673 17.3333H19.0673L22.4007 13.7333C22.8895 13.1556 23.478 12.7218 24.166 12.432C24.8558 12.144 25.5784 12 26.334 12H37.6673C38.4229 12 39.1455 12.144 39.8353 12.432C40.5233 12.7218 41.1118 13.1556 41.6007 13.7333L44.934 17.3333H53.334C54.8007 17.3333 56.0567 17.856 57.102 18.9013C58.1455 19.9449 58.6673 21.2 58.6673 22.6667V46.232C58.6673 47.6987 58.1455 48.9547 57.102 50C56.0567 51.0436 54.8007 51.5653 53.334 51.5653H10.6673ZM42.6673 42.5333C42.6673 43.3434 42.0107 44 41.2007 44H22.8007C21.9906 44 21.334 43.3434 21.334 42.5333C21.334 40.5333 22.3118 38.944 24.2673 37.7653C26.2229 36.5884 28.8007 36 32.0007 36C35.2007 36 37.7784 36.5884 39.734 37.7653C41.6895 38.944 42.6673 40.5333 42.6673 42.5333ZM35.7687 31.7653C34.7233 32.8107 33.4673 33.3333 32.0007 33.3333C30.534 33.3333 29.2789 32.8107 28.2353 31.7653C27.19 30.7218 26.6673 29.4667 26.6673 28C26.6673 26.5333 27.19 25.2773 28.2353 24.232C29.2789 23.1884 30.534 22.6667 32.0007 22.6667C33.4673 22.6667 34.7233 23.1884 35.7687 24.232C36.8122 25.2773 37.334 26.5333 37.334 28C37.334 29.4667 36.8122 30.7218 35.7687 31.7653Z" stroke="#808080" strokeWidth="2.66667"/>
    </svg>
);

export default IconLoadPhoto