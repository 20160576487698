import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import IconClock from "../../components/Icons/Clock";
import IconOops from "../../components/Icons/IconOops";

import scss from "./index.module.scss";

const { confirmed, confirmed__card, confirmed__icon, confirmed__title, confirmed__text } =
   scss;

function Confirmed({
   title = "Ваша заявка принята в работу",
   text = "Как только решение по Вашей заявке будет принято, мы оповестим Вас об этом в SMS. Вы можете следить за статусом вашей заявки в личном кабинете.",
   buttonText = "Перейти в личный кабинет",
   buttonLink = "/profile",
}) {
   const navigation = useNavigate();
   const [access] = useState(localStorage.getItem("resultPage"));

   const [content, setContent] = useState({
      title,
      text,
      buttonText,
      buttonLink,
   });

   useEffect(() => {
      localStorage.removeItem("resultPage");
      if (!access) {
         navigation("/404");
      } else if (access !== true) {
         try {
            const data = JSON.parse(access);

            if (Object.keys(data).length > 0) {
               setContent({
                  title: data.title ? data.title : "Упс...",
                  text: data.text ? data.text : "",
                  buttonText: data.buttonText
                     ? data.buttonText
                     : "Вернуться в личный кабинет",
                  buttonLink: data.buttonLink ? data.buttonLink : "/profile",
               });
            }
         } catch {}
      }
   }, []);

   return access ? (
      <section className={confirmed}>
         <article className={confirmed__card}>
            {content.title === "Личный кабинет заблокирован" ? (
               <IconOops className={confirmed__icon} />
            ) : (
               <IconClock className={confirmed__icon} />
            )}
            <h2
               className={confirmed__title}
               dangerouslySetInnerHTML={{ __html: content.title }}
            />
            <p
               className={confirmed__text}
               dangerouslySetInnerHTML={{ __html: content.text }}
            />
            <Button
               href={content.buttonLink}
               onClick={() => localStorage.removeItem("resultPage")}
            >
               {content.buttonText}
            </Button>
         </article>
      </section>
   ) : null;
}

export default Confirmed;
