function IconPushpin(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="none" viewBox="0 0 200 200" {...props}>
         <g clipPath="url(#clip0_910_5123)">
            <g filter="url(#filter0_f_910_5123)" opacity="0.35">
               <path
                  fill="#A69AAD"
                  d="M106.131 97.506a6.131 6.131 0 10-12.262 0v86.15a6.132 6.132 0 0012.262 0v-86.15z"
               ></path>
               <path
                  fill="url(#paint0_linear_910_5123)"
                  d="M106.131 97.506a6.131 6.131 0 10-12.262 0v86.15a6.132 6.132 0 0012.262 0v-86.15z"
               ></path>
               <path
                  fill="url(#paint1_radial_910_5123)"
                  d="M106.131 97.506a6.131 6.131 0 10-12.262 0v86.15a6.132 6.132 0 0012.262 0v-86.15z"
               ></path>
               <path
                  fill="url(#paint2_radial_910_5123)"
                  d="M100 115.738c27.725 0 50.2-22.476 50.2-50.2 0-27.725-22.475-50.2-50.2-50.2s-50.2 22.475-50.2 50.2c0 27.724 22.475 50.2 50.2 50.2z"
               ></path>
               <path
                  fill="url(#paint3_radial_910_5123)"
                  d="M100 115.738c27.725 0 50.2-22.476 50.2-50.2 0-27.725-22.475-50.2-50.2-50.2s-50.2 22.475-50.2 50.2c0 27.724 22.475 50.2 50.2 50.2z"
               ></path>
               <path
                  fill="url(#paint4_radial_910_5123)"
                  d="M100 115.738c27.725 0 50.2-22.476 50.2-50.2 0-27.725-22.475-50.2-50.2-50.2s-50.2 22.475-50.2 50.2c0 27.724 22.475 50.2 50.2 50.2z"
               ></path>
               <path
                  fill="url(#paint5_radial_910_5123)"
                  d="M100.2 115.4c27.725 0 50.2-22.475 50.2-50.2S127.925 15 100.2 15 50 37.475 50 65.2s22.475 50.2 50.2 50.2z"
               ></path>
               <path
                  fill="url(#paint6_linear_910_5123)"
                  d="M126.936 59.15c5.641-4.352 5.813-13.585.384-20.623-5.429-7.038-14.403-9.215-20.044-4.864-5.641 4.352-5.813 13.585-.384 20.623 5.429 7.037 14.403 9.215 20.044 4.863z"
               ></path>
               <path
                  fill="url(#paint7_linear_910_5123)"
                  d="M103.769 120.494a1.886 1.886 0 00-1.885-1.888c-1.04 0-1.884.845-1.884 1.888v61.994c0 1.042.844 1.887 1.884 1.887a1.886 1.886 0 001.885-1.887v-61.994z"
               ></path>
            </g>
            <g filter="url(#filter1_i_910_5123)">
               <path
                  fill="#A69AAD"
                  d="M106.131 94.506a6.131 6.131 0 10-12.262 0v86.15a6.131 6.131 0 0012.262 0v-86.15z"
               ></path>
               <path
                  fill="url(#paint8_linear_910_5123)"
                  d="M106.131 94.506a6.131 6.131 0 10-12.262 0v86.15a6.131 6.131 0 0012.262 0v-86.15z"
               ></path>
               <path
                  fill="url(#paint9_radial_910_5123)"
                  d="M106.131 94.506a6.131 6.131 0 10-12.262 0v86.15a6.131 6.131 0 0012.262 0v-86.15z"
               ></path>
            </g>
            <path
               fill="url(#paint10_radial_910_5123)"
               d="M100 112.737c27.725 0 50.2-22.475 50.2-50.2 0-27.724-22.475-50.2-50.2-50.2s-50.2 22.476-50.2 50.2c0 27.725 22.475 50.2 50.2 50.2z"
            ></path>
            <path
               fill="url(#paint11_radial_910_5123)"
               d="M100 112.737c27.725 0 50.2-22.475 50.2-50.2 0-27.724-22.475-50.2-50.2-50.2s-50.2 22.476-50.2 50.2c0 27.725 22.475 50.2 50.2 50.2z"
            ></path>
            <path
               fill="url(#paint12_radial_910_5123)"
               d="M100 112.737c27.725 0 50.2-22.475 50.2-50.2 0-27.724-22.475-50.2-50.2-50.2s-50.2 22.476-50.2 50.2c0 27.725 22.475 50.2 50.2 50.2z"
            ></path>
            <path
               fill="url(#paint13_radial_910_5123)"
               d="M100.2 112.4c27.725 0 50.2-22.475 50.2-50.2S127.925 12 100.2 12 50 34.475 50 62.2s22.475 50.2 50.2 50.2z"
            ></path>
            <path
               fill="url(#paint14_linear_910_5123)"
               d="M123.874 55.365c5.641-4.351 5.813-13.584.384-20.622-5.429-7.038-14.403-9.215-20.044-4.864-5.641 4.352-5.813 13.585-.384 20.623 5.429 7.037 14.403 9.215 20.044 4.863z"
            ></path>
            <g filter="url(#filter2_f_910_5123)">
               <path
                  fill="url(#paint15_linear_910_5123)"
                  d="M103.769 117.494a1.887 1.887 0 00-1.885-1.888c-1.04 0-1.884.846-1.884 1.888v61.994c0 1.042.844 1.887 1.884 1.887a1.886 1.886 0 001.885-1.887v-61.994z"
               ></path>
            </g>
         </g>
         <defs>
            <filter
               id="filter0_f_910_5123"
               width="160.6"
               height="234.788"
               x="19.8"
               y="-15"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5123" stdDeviation="15"></feGaussianBlur>
            </filter>
            <filter
               id="filter1_i_910_5123"
               width="17.262"
               height="113.413"
               x="93.869"
               y="88.375"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="5" dy="85"></feOffset>
               <feGaussianBlur stdDeviation="7.5"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.552941 0 0 0 0 0.447059 0 0 0 0 0.584314 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_5123"></feBlend>
            </filter>
            <filter
               id="filter2_f_910_5123"
               width="23.769"
               height="85.769"
               x="90"
               y="105.606"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5123" stdDeviation="5"></feGaussianBlur>
            </filter>
            <linearGradient
               id="paint0_linear_910_5123"
               x1="191.112"
               x2="116.662"
               y1="160.371"
               y2="160.371"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C6BECB"></stop>
               <stop offset="1" stopColor="#C6BECB" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint1_radial_910_5123"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 87.3936 -189.85 0 100.1 102.394)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.9" stopColor="#9F7BBF" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#9F7BBF"></stop>
            </radialGradient>
            <radialGradient
               id="paint2_radial_910_5123"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-69.56777 -52.6748 99.48207 -131.3863 143.133 145.145)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FD2C8D"></stop>
               <stop offset="1" stopColor="#FA356F"></stop>
            </radialGradient>
            <radialGradient
               id="paint3_radial_910_5123"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-71.3124 0 0 -170.591 121.111 102.394)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.434" stopColor="#CD284F" stopOpacity="0"></stop>
               <stop offset="0.949" stopColor="#A73C52"></stop>
            </radialGradient>
            <radialGradient
               id="paint4_radial_910_5123"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 130.852 -75.3128 0 100.1 61.983)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.691" stopColor="#CC2272" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#BB438D"></stop>
            </radialGradient>
            <radialGradient
               id="paint5_radial_910_5123"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(116.258 36.805 75.85) scale(43.2781 32.6861)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FF5EA3"></stop>
               <stop offset="1" stopColor="#FF5EA3" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint6_linear_910_5123"
               x1="132.195"
               x2="62.134"
               y1="44.459"
               y2="165.991"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFFDFF"></stop>
               <stop offset="1" stopColor="#FFFDFF" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint7_linear_910_5123"
               x1="101.888"
               x2="101.888"
               y1="118.606"
               y2="184.369"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#BDAFC1"></stop>
               <stop offset="1" stopColor="#C3B8CE"></stop>
            </linearGradient>
            <linearGradient
               id="paint8_linear_910_5123"
               x1="111.094"
               x2="102.019"
               y1="170.225"
               y2="170.225"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C6BECB"></stop>
               <stop offset="1" stopColor="#C6BECB" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint9_radial_910_5123"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 49.2062 -23.1415 0 100 137.581)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.9" stopColor="#9F7BBF" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#9F7BBF"></stop>
            </radialGradient>
            <radialGradient
               id="paint10_radial_910_5123"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-69.42985 -30.2567 43.4857 -99.78635 142.947 87.094)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FD2C8D"></stop>
               <stop offset="1" stopColor="#FA356F"></stop>
            </radialGradient>
            <radialGradient
               id="paint11_radial_910_5123"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-71.1706 0 0 -97.9894 120.969 62.537)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.434" stopColor="#CD284F" stopOpacity="0"></stop>
               <stop offset="0.949" stopColor="#A73C52"></stop>
            </radialGradient>
            <radialGradient
               id="paint12_radial_910_5123"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(90 30.338 69.662) scale(75.1631)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.691" stopColor="#CC2272" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#BB438D"></stop>
            </radialGradient>
            <radialGradient
               id="paint13_radial_910_5123"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(130.601 49.714 51.5) scale(29.363 27.6179)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FF5EA3"></stop>
               <stop offset="1" stopColor="#FF5EA3" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint14_linear_910_5123"
               x1="114.045"
               x2="121.25"
               y1="29.147"
               y2="55.74"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFFDFF"></stop>
               <stop offset="1" stopColor="#FFFDFF" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint15_linear_910_5123"
               x1="101.888"
               x2="101.888"
               y1="115.606"
               y2="181.369"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#BDAFC1"></stop>
               <stop offset="1" stopColor="#C3B8CE"></stop>
            </linearGradient>
            <clipPath id="clip0_910_5123">
               <path fill="#fff" d="M0 0H200V200H0z"></path>
            </clipPath>
         </defs>
      </svg>
   );
}

export default IconPushpin;
