import { useEffect, useState } from "react";

import Input from "../../components/Input";
import InputRadio from "../../components/InputRadio";
import DropDownList from "../../components/DropDownList";

import css from "./styles.module.scss";
import InputRange from "../../components/InputRange";
import CheckBox from "../../components/Checkbox";

const {
   application__stepBlock,
   application__sign,
   application__signHeader,
   application__signBlock,
   application__signBlockCheck,
   application__signBlockText,
   application__signNote,
} = css;

const ApplicationStep3 = ({ onChange = () => {}, data = {}, errors = {}, settings = {}, hasSend = false }) => {
   const [jobType, setJobType] = useState(data.jobType || data.jobType === 0 ? data.jobType : 0);
   const jobTypeArray = ["Работаю", "Не работаю", "Пенсионер"];

   const [jobPlace, setJobPlace] = useState(data.jobPlace ? data.jobPlace : "");
   const [jobPosition, setJobPosition] = useState(data.jobPosition ? data.jobPosition : "");

   const [jobAddress, setJobAddress] = useState(data.jobAddress ? data.jobAddress : "");

   const [jobPhone, setJobPhone] = useState(data.jobPhone ? data.jobPhone : "");
   const [nextIncome, setNextIncome] = useState(data.nextIncome || data.nextIncome === 0 ? data.nextIncome : 0);
   const nextIncomeArray = [
      "1го числа",
      "2го числа",
      "3го числа",
      "4го числа",
      "5го числа",
      "6го числа",
      "7го числа",
      "8го числа",
      "9го числа",
      "10го числа",
      "11го числа",
      "12го числа",
      "13го числа",
      "14го числа",
      "15го числа",
      "16го числа",
      "17го числа",
      "18го числа",
      "19го числа",
      "20го числа",
      "21го числа",
      "22го числа",
      "23го числа",
      "24го числа",
      "25го числа",
      "26го числа",
      "27го числа",
      "28го числа",
      "29го числа",
      "30го числа",
      "31го числа",
   ];

   const [income, setIncome] = useState(data.income || data.income === 0 ? data.income : 0);
   const incomeArray = [
      "До 10 000 ₽",
      "10 000 ₽ - 20 000 ₽",
      "20 000 ₽ - 35 000 ₽",
      "35 000 ₽ - 50 000 ₽",
      "Более 50 000 ₽",
   ];

   const [loans, setLoans] = useState(data.loans || data.loans === 0 ? data.loans : 1);
   const loansArray = [
      "Нет платежей по кредитам",
      "До 10 000 ₽",
      "10 000 ₽ - 20 000 ₽",
      "20 000 ₽ - 35 000 ₽",
      "35 000 ₽ - 50 000 ₽",
      "Более 50 000 ₽",
   ];

   const [amount, setAmount] = useState(data.amount ? data.amount : 10000);
   const amountArray = [];
   for (let i = 2000; i <= 30000; i += 1000) amountArray.push(i);

   const [duration, setDuration] = useState(data.duration || data.duration === 0 ? data.duration : 0);
   const durationArray = [];
   for (let i = 7; i <= 30; i++) durationArray.push(i);

   const [rule, setRule] = useState(false);
   const [ruleKomf, setRuleKomf] = useState(false);
   const [ruleUstupka, setRuleUstupka] = useState(false);
   const [rulePersDate, setRulePersDate] = useState(false);
   const [ruleAsp, setRuleAsp] = useState(false);

   useEffect(() => {
      onChange({
         jobType,
         jobPlace,
         jobPosition,
         jobAddress,
         jobPhone,
         nextIncome,
         income,
         loans,
         amount,
         duration,
         rule,
         ruleKomf,
         ruleUstupka,
         rulePersDate,
         ruleAsp,
      });
   }, [
      jobType,
      jobPlace,
      jobPosition,
      jobAddress,
      jobPhone,
      nextIncome,
      income,
      loans,
      amount,
      duration,
      rule,
      ruleKomf,
      ruleUstupka,
      rulePersDate,
      ruleAsp,
   ]);

   return (
      <>
         <div className={application__stepBlock}>
            <DropDownList label={"Вид занятости"} options={jobTypeArray} value={jobType} onChange={setJobType} />
         </div>
         {jobType === 0 ? (
            <>
               <div className={application__stepBlock}>
                  <Input
                     label={"Место работы"}
                     placeholder={'ООО "Рога и копыта"'}
                     value={jobPlace}
                     onChange={setJobPlace}
                     error={errors.jobPlace ? errors.jobPlace : null}
                     showErrorOnBlur={!hasSend}
                  />
                  <Input
                     label={"Занимаемая должность"}
                     placeholder="Охраник"
                     value={jobPosition}
                     onChange={setJobPosition}
                     error={errors.jobPosition ? errors.jobPosition : null}
                     showErrorOnBlur={!hasSend}
                  />
               </div>
               <div className={application__stepBlock}>
                  <Input
                     label={"Адрес организации"}
                     placeholder="Москва, ул. Тверская, д. 1"
                     value={jobAddress}
                     onChange={setJobAddress}
                     error={errors.jobAddress ? errors.jobAddress : null}
                     showErrorOnBlur={!hasSend}
                  />
               </div>
               <div className={application__stepBlock}>
                  <Input
                     label={"Номер телефона организации"}
                     placeholder="+7 (900) 800-70-60"
                     value={jobPhone}
                     onChange={setJobPhone}
                     mask="+7 (XXX) XXX-XX-XX"
                     regex={/^[0-9]$/}
                     error={errors.jobPhone ? errors.jobPhone : null}
                     showErrorOnBlur={!hasSend}
                  />
                  <DropDownList
                     label={"Ближайшая выплата"}
                     options={nextIncomeArray}
                     value={nextIncome}
                     onChange={setNextIncome}
                  />
               </div>
            </>
         ) : (
            ""
         )}
         <div className={application__stepBlock}>
            <DropDownList label={"Ежемесячный доход"} options={incomeArray} value={income} onChange={setIncome} />
         </div>
         <div className={application__stepBlock}>
            <DropDownList label={"Кредитные обязательства"} options={loansArray} value={loans} onChange={setLoans} />
         </div>
         <div className={application__stepBlock}>
            <InputRange
               label={"Какая сумма вам требуется?"}
               length={settings.max_amount ? settings.max_amount : 30000}
               showValue={parseInt(amount).toLocaleString("ru-RU") + " ₽"}
               step={1000}
               min={settings.min_amount ? settings.min_amount : 2000}
               value={amount}
               onChange={setAmount}
            />
            <InputRange
               label={"На какой срок?"}
               length={settings.max_duration ? settings.max_duration : 30}
               showValue={duration + " дней"}
               step={1}
               min={settings.min_duration ? settings.min_duration : 7}
               value={duration}
               onChange={setDuration}
            />
         </div>

         <div className={application__sign}>
            <h2 className={application__signHeader}>Подписание заявки</h2>

            <label className={application__signBlock}>
               <CheckBox
                  className={application__signBlockCheck}
                  value={rule || (ruleAsp && ruleKomf && rulePersDate && ruleUstupka)}
                  onChange={setRule}
               />
               <p className={application__signBlockText}>Я согласен со всеми условиями</p>
            </label>

            <label className={application__signBlock}>
               <CheckBox className={application__signBlockCheck} value={rule || ruleKomf} onChange={setRuleKomf} />
               <p className={application__signBlockText}>
                  Настоящим подтверждаю, что полностью ознакомлен с условиями предоставления микрозайма, а так же с&nbsp;
                  <a href="/upload/documents/publichnaya-oferta.pdf" target="_blank" rel="noopener noreferrer">
                     публичной офертой
                  </a>{" "}
                  и общими условиями.
               </p>
            </label>

            <label className={application__signBlock}>
               <CheckBox
                  className={application__signBlockCheck}
                  value={rule || ruleUstupka}
                  onChange={setRuleUstupka}
               />
               <p className={application__signBlockText}>
                  Настоящим соглашаюсь на уступку прав (требований) по договору третьим лицам при условии соблюдения
                  Обществом требований действующего законодательства.
               </p>
            </label>

            <label className={application__signBlock}>
               <CheckBox
                  className={application__signBlockCheck}
                  value={rule || rulePersDate}
                  onChange={setRulePersDate}
               />
               <p className={application__signBlockText}>
                  Настоящим выражаю своё согласие на обработку{" "}
                  <a href="/file.pdf" target="_blank" rel="noopener noreferrer">
                     персональных данных и на запрос кредитного отчёта в бюро кредитных историй
                  </a>
                  .
               </p>
            </label>

            <label className={application__signBlock}>
               <CheckBox className={application__signBlockCheck} value={rule || ruleAsp} onChange={setRuleAsp} />
               <p className={application__signBlockText}>
                  Настоящим выражаю своё согласие на принятие условий{" "}
                  <a href="/file.pdf" target="_blank" rel="noopener noreferrer">
                     Соглашения об использовании АСП
                  </a>
                  .
               </p>
            </label>

            <p className={application__signNote}>
               Я даю своё согласие на то, что заполнив свединия в Анкете-заявлений на получение потребительского займа,
               я подтверждаю достоверность предоставленных мною сведений и считаю анкету подписанной со своей стороны.
            </p>
         </div>
      </>
   );
};

export default ApplicationStep3;
