import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";

import Button from "../../components/Button";
import EmptyData from "../../components/EmptyData";
import IconCopy from "../../components/Icons/Copy";
import IconPage from "../../components/Icons/Page";
import PACardWrapper from "../../components/PersonalArea/CardWrapper";
import Table from "../../components/Table";

import checkAuth from "../../helpers/checkAuth";
import api from "../../helpers/fetch";
import TextOverflow from "../../helpers/text-overflow";

import scss from "./index.module.scss";
import logOut from "../../helpers/logout";

const {
   aboutLoan,
   aboutLoan__header,
   aboutLoan__menu,
   aboutLoan__text,
   aboutLoan__subtext,
   aboutLoan__icon,
   aboutLoan__title,
   aboutLoan__dashboard,
   aboutLoan__cards,
   aboutLoan__card,
   aboutLoan__cardTitle,
   aboutLoan__cardData,
   aboutLoan__payCard,
   aboutLoan__document,
   list,
   list__item,
   list__icon,
   list__text,
   cards__pay,
   cards__title,
   cards__title_black,
   cards__grid,
   cards__actions,
} = scss;

function AboutLoan() {
   const navigate = useNavigate();
   const params = useParams();

   const [loan, setLoan] = useState(false);
   const [transactionSort, setTransactionSort] = useState(null);

   useEffect(() => {
      getLoan();
   }, []);

   const paymentsToRows = (payments = []) => {
      const rows = [];
      payments.forEach((payment) => {
         const paymentStatus =
            payment.status === "confirmed" ? "success" : payment.status === "denied" ? "error" : "text";

         const paymentStatusText =
            payment.status === "confirmed"
               ? "Подтверждён"
               : payment.status === "denied"
               ? "Отклонён"
               : "Обрабатывается";

         rows.push([
            {
               type: "text",
               value: "№ " + payment.number,
            },
            {
               type: "money",
               value: payment.amount,
            },
            {
               type: "date",
               value: payment.date,
            },
            {
               type: paymentStatus,
               value: paymentStatusText,
            },
         ]);
      });

      return rows;
   };

   const getLoan = async () => {
      const auth = checkAuth();

      if (!auth) logOut();

      let queryParams = "";

      if (transactionSort === null) {
         setTransactionSort(["date_created", "asc"]);
      } else {
         queryParams = "?sort_param=" + transactionSort[0] + "&sort_direction=" + transactionSort[1];
      }

      const resp = await new api("loan" + (params.idLoan ? "/" + params.idLoan : "") + queryParams, auth).send();

      if (resp.status === "success") {
         if (queryParams === "") {
            resp.data.payments = paymentsToRows(resp.data.payments);
            setLoan(resp.data);
         } else {
            const loanTmp = loan;
            loanTmp.payments = paymentsToRows(resp.data.payments);
            setLoan(loanTmp);
         }
      } else {
         navigate("/404");
      }
   };

   useEffect(() => {
      if (loan) {
         getLoan();
      }
   }, [transactionSort]);

   const copyLoanNumber = () => {
      navigator.clipboard.writeText(loan.number);
   };

   return (
      <div className={aboutLoan}>
         {!loan ? (
            <div style={{ minHeight: "70vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
               <ReactLoading type="spin" color="#2D9614" />
            </div>
         ) : (
            <>
               <section className={aboutLoan__header}>
                  <div className={aboutLoan__menu}>
                     <div className={aboutLoan__text}>
                        <div className={aboutLoan__subtext}>
                           <span>Займ №{loan.number}</span>
                           <IconCopy className={aboutLoan__icon} onClick={copyLoanNumber} />
                        </div>
                        <h2 className={aboutLoan__title}>Информация по займу</h2>
                     </div>
                     <Button type="button" variant={"gray"} onClick={() => navigate(-1)}>
                        Назад
                     </Button>
                  </div>
                  <div className={aboutLoan__dashboard}>
                     <div className={aboutLoan__cards}>
                        <article className={aboutLoan__card}>
                           <h3 className={aboutLoan__cardTitle}>Статус</h3>
                           <span className={aboutLoan__cardData}>
                              {loan.status === "signed" ? "Активен" : ""}
                              {loan.status === "completed" ? "Выплачен" : ""}
                           </span>
                        </article>
                        <article className={aboutLoan__card}>
                           <h3 className={aboutLoan__cardTitle}>Сумма займа</h3>
                           <span className={aboutLoan__cardData}>
                              {parseFloat(loan.total_amount / 100).toLocaleString("ru-RU")} ₽
                           </span>
                        </article>
                        <article className={aboutLoan__card}>
                           <h3 className={aboutLoan__cardTitle}>Ставка в день</h3>
                           <span className={aboutLoan__cardData}>{parseFloat(loan.rate).toLocaleString("ru-RU")}%</span>
                        </article>
                     </div>
                     <div className={aboutLoan__payCard}>
                        <PACardWrapper className={cards__pay}>
                           <h3 className={cards__title + " " + cards__title_black}>Минимальный платёж</h3>
                           <div className={cards__grid}>
                              {loan.status === "signed" ? (
                                 <>
                                    {loan.next_payment_amount > 0 ? (
                                       <span>
                                          Сумма&nbsp;платежа:
                                          <br />
                                          <strong>
                                             {parseFloat(
                                                loan.next_payment_amount > 0 ? loan.next_payment_amount / 100 : 0
                                             ).toLocaleString("ru-RU")}{" "}
                                             ₽
                                          </strong>
                                       </span>
                                    ) : (
                                       ""
                                    )}
                                    {loan.next_payment_date > 0 ? (
                                       <span>
                                          Оплатить&nbsp;до:
                                          <br />
                                          <strong>
                                             {new Date(loan.next_payment_date).toLocaleDateString("ru-RU")}
                                          </strong>
                                       </span>
                                    ) : (
                                       ""
                                    )}
                                 </>
                              ) : (
                                 ""
                              )}
                           </div>
                           <div className={cards__actions}>
                              <Button
                                 href={"/payment-method"}
                                 variant={"gray"}
                                 disabled={loan.next_payment_amount <= 0 || loan.status === "completed"}
                              >
                                 {loan.next_payment_amount > 0 && loan.status === "signed"
                                    ? "Внести платёж"
                                    : "Платёж оплачен"}
                              </Button>
                              <Button href="/payment-method/total" disabled={loan.status !== "signed"}>
                                 {loan.status === "signed" ? "Погасить заём" : "Займ погашен"}
                              </Button>
                           </div>
                        </PACardWrapper>
                     </div>
                  </div>
               </section>
               <section className={aboutLoan__document}>
                  <div className={aboutLoan__menu}>
                     <div className={aboutLoan__text}>
                        <h2 className={aboutLoan__title}>Документы</h2>
                        <div className={aboutLoan__subtext}>
                           <span>Пожалуйста ознакомитесь с пакетом документов, который для вас сформирован:</span>
                        </div>
                     </div>
                  </div>
                  <div className={list}>
                     {loan.documents.map(({ name, url }, i) => (
                        <a
                           className={list__item}
                           href={url}
                           target={"_blank"}
                           rel="noreferrer"
                           title={'Скачать "' + name + '"'}
                           key={i}
                        >
                           <IconPage className={list__icon} />
                           <p className={list__text}>{TextOverflow(name, 120)}</p>
                        </a>
                     ))}
                  </div>
               </section>
               <section className={aboutLoan__document}>
                  <div className={aboutLoan__menu}>
                     <div className={aboutLoan__text}>
                        <h2 className={aboutLoan__title}>Платежи</h2>
                     </div>
                  </div>
                  <Table
                     className=""
                     colums={[
                        {
                           paramName: "id_loan_transaction",
                           name: "Номер транзакции",
                        },
                        {
                           paramName: "amount",
                           name: "Сумма",
                        },
                        {
                           paramName: "date_created",
                           name: "Дата платежа",
                        },
                        {
                           paramName: "status",
                           name: "Статус",
                        },
                     ]}
                     rows={loan?.payments}
                     baseColumnsWidth={[null, null, 260, 200]}
                     onSort={(param, direction) => setTransactionSort([param, direction])}
                     sortParam={transactionSort[0]}
                     sortDirection={transactionSort[1]}
                     blockOnEmpty={
                        <EmptyData
                           title="Нет платежей"
                           text="Вы ещё не внесли ни одного платежа. Как только Вы внесёте свой первый платёж, он появится в этом списке."
                        />
                     }
                  />
               </section>
            </>
         )}
      </div>
   );
}

export default AboutLoan;
