import { Link } from "react-router-dom";
import formatPhone from "../../helpers/formatPhone";
import IconDisclaimer from "../Icons/Disclaimer";
import IconLogo from "../Icons/Logo";
import IconSbp from "../Icons/Sbp";
import classes from "./index.module.scss";

const {
   footer,
   footer__aboutCompany,
   footer__grid,
   footer__cel,
   footer__info,
   footer__links,
   footer__contacts,
   footer__pictures,
   footer__age,
   footer__sbp,
   logo,
   age,
   sbp,
   bold,
} = classes;

const initialState = window.initialState
   ? window.initialState
   : {
        settings: {
           about: {
              inn: 7805772796,
              ogrn: 1207800157465,
              license_cb: "№2103140009674, выдана 28.04.2021 года",
              min_loan: 10000,
              max_loan: 30000,
              min_interest: 0,
              max_interest: 1,
              min_duration: 10,
              max_duration: 30,
              min_psk: 0,
              max_psk: 365,
              address: "Адрес: 195221, город Санкт-Петербург, пр-кт Металлистов, д. 75 литера А, кв. 12",
              phone: 8126288181,
              email: "support@slon.finance",
              work_time: "ежедневно: с 06:00 до 22:00 (по Москве).",
              footer_text: [
                 "Компания внесена в госреестр Центрального банка России и осуществляет свою деятельность в рамках Федеральных законов РФ. ЦБ РФ контролирует работу МФО и оказывает содействие физическим лицам, в онлайн-режиме доступна интернет-приёмная Банка России.",
                 "Адрес местонахождения и почтовый адрес организации: 195221, город Санкт-Петербург, пр-кт Металлистов, д. 75 литера А, кв. 12. Номер телефона службы обеспечения деятельности финансового уполномоченного - +7 (812) 628-81-81.",
              ],
              copyright: [
                 "© 2020-2023 Общество с ограниченной ответственностью Микрокредитная компания «Домашний Инвестор» (сокращенно – ООО МКК «Домашний Инвестор»), осуществляет деятельность под контролем Центрального Банка РФ.",
              ],
           },
        },
     };

function Footer() {
   let about = initialState.settings.about ? initialState.settings.about : [];

   return (
      <footer className={footer}>
         <div className={footer__aboutCompany}>
            {about.footer_text.map((text, i) => (
               <p key={i}>{text}</p>
            ))}
         </div>
         <div className={footer__grid}>
            <div className={footer__cel}>
               <p className={bold}>Сумма займа</p>
               <p>
                  от {parseFloat(about.min_loan).toLocaleString("ru-RU")} до{" "}
                  {parseFloat(about.max_loan).toLocaleString("ru-RU")} рублей
               </p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>Срок займа</p>
               <p>
                  от {about.min_duration} до {about.max_duration} календарных дней
               </p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>Процентная ставка</p>
               <p>
                  от {about.min_interest}% до {about.max_interest}% в день
               </p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>ПСК (полная стоимость кредита)</p>
               <p>
                  от {about.min_psk}% до {about.max_psk}% годовых
               </p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>ИНН</p>
               <p>{about.inn}</p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>ОРГН</p>
               <p>{about.ogrn}</p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>Лицензия Центрального Банка</p>
               <p>{about.license_cb}</p>
            </div>
         </div>
         <div className={footer__info}>
            <div className={footer__links}>
               <Link to={"/o-nas"}>О компании</Link>
               <Link to={"/dokumenti"}>Документы</Link>
            </div>
            <div className={footer__contacts}>
               <p>{about.copyright[0]}</p>
               <address>Адрес: {about.address}</address>
               <p>
                  Телефон для связи: <a href={"tel: +7" + about.phone}>{formatPhone(about.phone)}</a>
               </p>
               <p>График работы: {about.work_time}</p>
               <p>
                  Служба поддержки клиентов: <a href={"mailto: " + about.email}>{about.email}</a>
               </p>
            </div>
         </div>
         <div className={footer__pictures}>
            <Link aria-label="Главная" to={"/"} title="Главная">
               <IconLogo className={logo} />
            </Link>
            <div className={footer__age}>
               <IconDisclaimer className={age} />
            </div>
            <div className={footer__sbp}>
               <IconSbp className={sbp} />
            </div>
         </div>
      </footer>
   );
}

export default Footer;
