import Button from "../Button";
import IconWatch from "../Icons/Watch";

import css from "./styles.module.scss";

const { exception, exception__icon, exception__title, exception__text, red } = css;

const EmptyData = ({
   title = "",
   text = "",
   buttonText = "",
   buttonLink = "",
   showButton = false,
   redButton,
   onClick,
}) => {
   return (
      <div className={exception}>
         <IconWatch className={exception__icon} />
         <h3 className={exception__title}>{title}</h3>
         <p className={exception__text} dangerouslySetInnerHTML={{ __html: text }} />

         {showButton ? (
            <Button className={redButton ? red : ""} href={buttonLink} onClick={onClick}>
               {buttonText}
            </Button>
         ) : (
            ""
         )}
      </div>
   );
};

export default EmptyData;
