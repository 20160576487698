import { useRef } from "react";
import Button from "../Button";
import IconAttach from "../Icons/Attach";
import Picture from "../Picture";
import ToolTipWrapper, { createInputToolTip } from "../ToolTipWrapper";
import classes from "./index.module.scss";

const { intro, intro__content, intro__image, intro__article, intro__title, intro__text, intro__button } = classes;

const MainIntro = ({ data, blockIndex = -1, editable = false, onChange = () => {}, onClick = () => {} }) => {

   const defaultTitle = useRef(data.title);
   const defaultDescription = useRef(data.description);

   const changeHeader = (e) => {
      onChange(blockIndex, "title", e.target.textContent);
   }
   
   const changeText = (e) => {
      onChange(blockIndex, "description", e.target.innerHTML);
   }

   const startEditingButton = () => {
      onClick(blockIndex, "button");
   }

   return (
      <section className={intro}>
         <div className={intro__content}>
            <article className={intro__article}>
               <h2
                  className={intro__title}
                  contentEditable={editable} suppressContentEditableWarning={true}
                  value={data.title}
                  onInput={changeHeader}
                  onBlur={changeHeader}
               >{defaultTitle.current}</h2>
               <div
                  className={intro__text}
                  contentEditable={editable} suppressContentEditableWarning={true}
                  dangerouslySetInnerHTML={{ __html: defaultDescription.current }}
                  value={data.description}
                  onInput={changeText}
                  onBlur={changeText}
               />
            </article>
            <ToolTipWrapper
               showOnHover={editable}
               tooltipContent={createInputToolTip(
                  <IconAttach />,
                  "Ссылка",
                  "text",
                  data.button_link,
                  val => onChange(blockIndex, "button_link", val)
               )}
            >
               <Button
                  href={data.button_link}
                  className={intro__button}

                  contentEditable={editable}
                  value={data.button_text}
                  onChange={e => onChange(blockIndex, "button_text", e.target.textContent)}
               >
                  {data.button_text}
               </Button>
            </ToolTipWrapper>
         </div>
         <Picture
            className={intro__image}
            fileName={"illustration"}
            alt={"Большой и серый слон выходит из-за деревьев."}
         />
      </section>
   );
};

export default MainIntro;
