import { useRef } from "react";
import css from "./styles.module.scss";

const {
    circleAdvantages,
    circleAdvantages__title,
    circleAdvantages__items,
    circleAdvantages__item,
    circleAdvantages__circle,
    circleAdvantages__circleTextMain,
    circleAdvantages__circleTextSub,
    circleAdvantages_text,

    icon,
    icon_247,

    icon__1,
    icon__2,
    icon__3
} = css;

const CircleAdvantages = ({
    data,

    blockIndex = -1,
    onChange = () => {},
    editable = false
}) => {
    const {
        title,
        items
    } = data;

    const icons = {
        "24_7": () => <div className={icon + " " + icon_247}>
            <span className={icon__1}>24</span>
            <span className={icon__2}>/</span>
            <span className={icon__3}>7</span>
        </div>
    };

    const defaultHeader = useRef(title).current;
    const defaultItems = [];

    items.forEach(({circle_main, circle_sub, text}) => {
        defaultItems.push({
            CircleMain: () => useRef(circle_main).current,
            CircleSub: () => useRef(circle_sub).current,
            Text: () => useRef(text).current,
        })
    });

    return (
        <section className={circleAdvantages}>
            <h2
                className={circleAdvantages__title}

                contentEditable={editable} suppressContentEditableWarning={true}
                value={title}
                onChange={e => onChange(blockIndex, "title", e.target.textContent)}
                onBlur={e => onChange(blockIndex, "title", e.target.textContent)}
            >
                {defaultHeader}
            </h2>
            <div className={circleAdvantages__items}>
                {
                    items.map(({circle_icon, circle_main, circle_sub, text}, i) => (
                        <div className={circleAdvantages__item} key={i}>
                            <div className={circleAdvantages__circle}>
                                {
                                    circle_icon !== null ? (
                                        icons[circle_icon] ? icons[circle_icon]() : ""
                                    ) : (
                                        <>
                                            <h3
                                                className={circleAdvantages__circleTextMain}

                                                contentEditable={editable} suppressContentEditableWarning={true}
                                                value={circle_main}
                                                onChange={e => onChange(blockIndex, "items." + i + ".circle_main", e.target.textContent)}
                                                onBlur={e => onChange(blockIndex, "items." + i + ".circle_main", e.target.textContent)}
                                            >
                                                {defaultItems[i].CircleMain()}
                                            </h3>
                                            <p
                                                className={circleAdvantages__circleTextSub}

                                                contentEditable={editable} suppressContentEditableWarning={true}
                                                value={circle_sub}
                                                onChange={e => onChange(blockIndex, "items." + i + ".circle_sub", e.target.textContent)}
                                                onBlur={e => onChange(blockIndex, "items." + i + ".circle_sub", e.target.textContent)}
                                            >
                                                {defaultItems[i].CircleSub()}
                                            </p>
                                        </>
                                    )
                                }
                            </div>

                            <p
                                className={circleAdvantages_text}

                                contentEditable={editable} suppressContentEditableWarning={true}
                                value={text}
                                onChange={e => onChange(blockIndex, "items." + i + ".text", e.target.textContent)}
                                onBlur={e => onChange(blockIndex, "items." + i + ".text", e.target.textContent)}
                            >{defaultItems[i].Text()}</p>
                        </div>
                    ))
                }
            </div>
        </section>
    );
}

export default CircleAdvantages; // 3.30 - 142.75 = 139,45