import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import checkAuth from "../../helpers/checkAuth";
import api from "../../helpers/fetch";
import logOut from "../../helpers/logout";

import Button from "../../components/Button";

import ApplicationStep1 from "./step1";
import ApplicationStep2 from "./step2";
import ApplicationStep3 from "./step3";

import ReactLoading from "react-loading";

import css from "./styles.module.scss";

const {
    application,
    application__error,
    application__title,
    application__header,
    application__tab,
    application__tab_disabled,
    application__tab_active,
    application__tabStep,
    application__tabText,
    application__moileTitle,
    application__form,
    application__footer,
    application__footerButton,
} = css;

const Application = () => {
    
    const navigate = useNavigate();
    let {search} = useLocation();

    const params = {};

    if ( search[0] === "?" ) {
        search = search.substring(1);
        search = search.split("&");

        search.forEach((param, i) => {
            const paramsSplit = param.split("=");
            params[paramsSplit[0]] = paramsSplit[1];
        })
    }

    const [ready, setReady] = useState(false);

    const [errors, setErrors] = useState({});
    const [serverErrors, setServerErrors] = useState({});
    const [hasSend, setSended] = useState(false);

    let tmpApplication = localStorage.getItem("application");
    try {
        tmpApplication = tmpApplication ? JSON.parse(tmpApplication) : tmpApplication;
    }
    catch {}

    const [step1, setStep1] = useState( tmpApplication?.step1 ? tmpApplication.step1 : {
        name: "",
        surname: "",
        midname: "",
        email: ""
    });

    const [step2, setStep2] = useState( tmpApplication?.step2 ? tmpApplication.step2 : {
        pasport: "",
        bday: null,
        bplace: "",
        pasportOrgCode: "",
        pasportDateGet: "",
        pasportOrg: "",
        inn: "",
        photo1: null,
        photo2: null,
        photo3: null,
        photo4: null,
        registration: "",
        isOnReg: 1,
        living: "",
        familyState: 0,
        familyCount: 0,
        childrenCount: 0,
        liveWithParents: 1
    });

    const [step3, setStep3] = useState( tmpApplication?.step3 ? tmpApplication.step3 : {
        jobType: 0,
        jobPlace: "",
        jobPosition: "",
        jobAddress: "",
        jobPhone: "",
        nextIncome: 0,
        income: 0,
        loans: 1,
        amount: params["amount"] ? params["amount"] : 0,
        duration: params["duration"] ? params["duration"] : 0,
    });

    const [currentStep, setStep] = useState(0);
    const [valid, setValid] = useState(0);

    const [calculatorSettings, setCalculatorSettings] = useState({
        "min_amount": 2000,
        "max_amount": 30000,
        "min_interest": 0,
        "max_interest": 1,
        "min_duration": 7,
        "max_duration": 30
    });

    const steps = [{
        title: "контактные данные",
        block: <ApplicationStep1
            key={0}
            data={step1}
            onChange={setStep1}
            errors={errors}
            hasSend={hasSend}
        />
    }, {
        title: "паспортные данные",
        block: <ApplicationStep2
            key={1}
            data={step2}
            onChange={setStep2}
            errors={errors}
            hasSend={hasSend}
        />
    }, {
        title: "данные о доходе",
        block: <ApplicationStep3
            key={2}
            data={step3}
            onChange={setStep3}
            errors={errors}
            hasSend={hasSend}

            settings={calculatorSettings}
        />
    }];

    useEffect(()=>{

        const tmpErrors = {};

        if (
            step1.name.length >= 2 &&
            step1.surname.length >= 2 &&
            step1.email.match(/^[a-zA-Z0-9\-._]{2,}@[[a-zA-Z0-9\-._]{2,}\.[a-zA-Z]{2,}$/)
        ) {
            setValid(1);
            
            if (
                step2.bday >= (new Date()).getTime() ||
                step2.bday <= (new Date("1900-01-01")).getTime() ||
                step2.bday === null
            ) {
                tmpErrors["bday"] = "Пожалуйста, заполните это поле.";
            }
            else tmpErrors["bday"] = false;
            
            if ( !/^[0-9]{10}$/.test(step2.pasport))
                tmpErrors["pasport"] = "Пожалуйста, заполните это поле.";
            else tmpErrors["pasport"] = false;
            
            if ( !/^[а-яА-ЯёЁ\s-.,0-9]{3,}$/.test(step2.bplace) )
                tmpErrors["bplace"] = "Пожалуйста, заполните это поле.";
            else tmpErrors["bplace"] = false;
            
            if ( !/^[0-9]{6}$/.test(step2.pasportOrgCode) )
                tmpErrors["pasportOrgCode"] = "Пожалуйста, заполните это поле.";
            else tmpErrors["pasportOrgCode"] = false;
            
            if (
                step2.pasportDateGet <= (new Date(step2.bday)).getTime() ||
                step2.pasportDateGet >= (new Date()).getTime() ||
                step2.pasportDateGet === null
            )
                tmpErrors["pasportDateGet"] = "Пожалуйста, заполните это поле.";
            else tmpErrors["pasportDateGet"] = false;
            
            if ( !/^[а-яА-ЯёЁ\s-.,0-9"]{3,}$/.test(step2.pasportOrg) )
                tmpErrors["pasportOrg"] = "Пожалуйста, заполните это поле.";
            else tmpErrors["pasportOrg"] = false;
            
            if (
                !/^[0-9]{10,12}$/.test(step2.inn) &&
                step2.inn !== ""
            )
                tmpErrors["inn"] = "Пожалуйста, заполните это поле.";
            else tmpErrors["inn"] = false;
            
            if ( step2.photo1 === null )
                tmpErrors["photo1"] = "Пожалуйста, загрузите фото.";
            else tmpErrors["photo1"] = false;
            
            if ( step2.photo2 === null )
                tmpErrors["photo2"] = "Пожалуйста, загрузите фото.";
            else tmpErrors["photo2"] = false;
            
            if ( step2.photo3 === null )
                tmpErrors["photo3"] = "Пожалуйста, загрузите фото.";
            else tmpErrors["photo3"] = false;
            
            if ( step2.photo4 === null )
                tmpErrors["photo4"] = "Пожалуйста, загрузите фото.";
            else tmpErrors["photo4"] = false;
            
            if ( !/^[а-яА-ЯёЁ\s-.,0-9]{3,}$/.test(step2.registration) )
                tmpErrors["registration"] = "Пожалуйста, заполните это поле.";
            else tmpErrors["registration"] = false;
            
            if (
                step2.isOnReg !== 0 &&
                !/^[а-яА-ЯёЁ\s-.,0-9]{3,}$/.test(step2.living)
            )
                tmpErrors["living"] = "Пожалуйста, заполните это поле.";
            else tmpErrors["living"] = false;

            if (
                step2.bday < (new Date()).getTime() &&
                step2.bday > (new Date("1900-01-01")).getTime() &&
                step2.bday !== null &&
    
                /^[0-9]{10}$/.test(step2.pasport) &&
                /^[а-яА-ЯёЁ\s-.,0-9]{3,}$/.test(step2.bplace) &&
                /^[0-9]{6}$/.test(step2.pasportOrgCode) &&
    
                step2.pasportDateGet > (new Date(step2.bday)).getTime() &&
                step2.pasportDateGet < (new Date()).getTime() &&
                step2.pasportDateGet !== null &&
    
                /^[а-яА-ЯёЁ\s-.,0-9"]{3,}$/.test(step2.pasportOrg) &&
                (
                    /^[0-9]{10,12}$/.test(step2.inn) ||
                    step2.inn === ""
                ) &&
                typeof step2.photo1?.name === "string" &&
                typeof step2.photo2?.name === "string" &&
                typeof step2.photo3?.name === "string" &&
                typeof step2.photo4?.name === "string" &&
                /^[а-яА-ЯёЁ\s-.,0-9]{3,}$/.test(step2.registration) &&
                (
                    step2.isOnReg === 0 ||
                    /^[а-яА-ЯёЁ\s-.,0-9]{3,}$/.test(step2.living)
                ) &&
                step2.familyState >= 0 &&
                step2.familyState < 4 &&
                step2.familyCount >= 0 &&
                step2.familyCount < 6 &&
                step2.childrenCount >= 0 &&
                step2.childrenCount < 7 &&
                step2.liveWithParents >= 0 &&
                step2.liveWithParents < 2
            ) {
                setValid(2);
                
                if (
                    step3.jobType < 0 ||
                    step3.jobType >= 3
                )
                    tmpErrors["jobType"] = "Пожалуйста, заполните это поле.";
                else tmpErrors["jobType"] = false;
                
                if (step3.jobType === 0) {
                    if (
                        step3.jobPlace.length <= 3
                    )
                        tmpErrors["jobPlace"] = "Пожалуйста, заполните это поле.";
                    else tmpErrors["jobPlace"] = false;
                        
                    if (
                        step3.jobPosition.length <= 2
                    )
                        tmpErrors["jobPosition"] = "Пожалуйста, заполните это поле.";
                    else tmpErrors["jobPosition"] = false;
                        
                    if (
                        !/^[а-яА-ЯёЁ\s-.,0-9]{3,}$/.test(step3.jobAddress)
                    )
                        tmpErrors["jobAddress"] = "Пожалуйста, заполните это поле.";
                    else tmpErrors["jobAddress"] = false;
                        
                    if (
                        !/^[0-9]{10}$/.test(step3.jobPhone)
                    )
                        tmpErrors["jobPhone"] = "Пожалуйста, заполните это поле.";
                    else tmpErrors["jobPhone"] = false;
                        
                    if (
                        step3.nextIncome < 0 &&
                        step3.nextIncome >= 31
                    )
                        tmpErrors["nextIncome"] = "Пожалуйста, заполните это поле.";
                    else tmpErrors["nextIncome"] = false;
                        
                }

                if (
                    step3.income < 0 &&
                    step3.income >= 5
                )
                    tmpErrors["income"] = "Пожалуйста, заполните это поле.";
                else tmpErrors["income"] = false;
                    
                if (
                    step3.loans < 0 &&
                    step3.loans >= 6
                )
                    tmpErrors["loans"] = "Пожалуйста, заполните это поле.";
                else tmpErrors["loans"] = false;
                    
                if (
                    step3.amount < calculatorSettings.min_amount &&
                    step3.amount > calculatorSettings.max_amount
                )
                    tmpErrors["amount"] = "Пожалуйста, заполните это поле.";
                else tmpErrors["amount"] = false;
                    
                if (
                    step3.duration < calculatorSettings.min_duration &&
                    step3.duration > calculatorSettings.max_duration
                )
                    tmpErrors["duration"] = "Пожалуйста, заполните это поле.";
                else tmpErrors["duration"] = false;

                if (
                    step3.jobType >= 0 &&
                    step3.jobType < 3 &&
                    (
                        step3.jobPlace.length > 3 || step3.jobType !== 0
                    ) &&
                    (
                        step3.jobPosition.length > 2 || step3.jobType !== 0
                    ) &&
                    (
                        /^[а-яА-ЯёЁ\s-.,0-9]{3,}$/.test(step3.jobAddress) || step3.jobType !== 0
                    ) &&
                    (
                        /^[0-9]{10}$/.test(step3.jobPhone) || step3.jobType !== 0
                    ) &&
                    (
                        step3.nextIncome >= 0 || step3.jobType !== 0
                    ) &&
                    (
                        step3.nextIncome < 31 || step3.jobType !== 0
                    ) &&
                    step3.income >= 0 &&
                    step3.income < 5 &&
                    step3.loans >= 0 &&
                    step3.loans < 6 &&
                    step3.amount >= 2000 &&
                    step3.amount <= 30000 &&
                    step3.duration >= 7 &&
                    step3.duration <= 30 &&
                    (
                        step3.rule || (
                            step3.ruleAsp &&
                            step3.ruleKomf &&
                            step3.rulePersDate &&
                            step3.ruleUstupka
                        )
                    )
                ) {
                    setValid(3);
                }
            }
        }
        else {
            setValid(0);
    
            if (step1.name.length < 2)
                tmpErrors["name"] = "Пожалуйста, заполните это поле.";
            else tmpErrors["name"] = false;
            
            if (step1.name.length < 2)
                tmpErrors["surname"] = "Пожалуйста, заполните это поле.";
            else tmpErrors["surname"] = false;
    
            if (!/^[a-zA-Z0-9\-._]{2,}@[[a-zA-Z0-9\-._]{2,}\.[a-zA-Z]{2,}$/.test(step1.email))
                tmpErrors["email"] = "Пожалуйста, проверьте корректность электронной почты.";
            else tmpErrors["email"] = false;
        }

        localStorage.setItem("application", JSON.stringify({
            step1,
            step2,
            step3
        }));

        setErrors({...tmpErrors, ...serverErrors});

    }, [step1, step2, step3]);

    const sendApplication = async () => {

        const auth = checkAuth();

        if (auth === false) {
            logOut();
        }
        else {
            const body = new FormData();
    
            Object.keys(step1).forEach(key => {
                body.append(key, step1[key]);
            });
            
            Object.keys(step2).forEach(key => {
                body.append(key, step2[key]);
            });
    
            Object.keys(step3).forEach(key => {
                body.append(key, step3[key]);
            });

            const resp = await new api("application",  auth, {
                method: "POST",
                body
            }).send();
            
            if (resp.status === "success") {
                localStorage.setItem("resultPage", "true");
                navigate("/application/done");
            }
            else if (resp.status === "error") {
                setStep(0);
                setErrors(resp.errors);
                setServerErrors(resp.errors);
                setSended(true);
            }
        }
    }

    useEffect(() => {
        if (currentStep === 3) {
            sendApplication();
        }

        window.scrollTo(0, 0);
    }, [currentStep]);

    const getSettings = async () => {
        if (ready === false) {
            const auth = checkAuth();

            if (auth === false) {
                logOut();
            }
            else {
                const resp = await new api("application", auth).send();
        
                if (resp.status === "success") {
                    if (resp.redirect) {
                        if (resp.title && resp.text) {
                            localStorage.setItem("resultPage", JSON.stringify({
                                title: resp.title,
                                text:  resp.text
                            }));
                        }
                        navigate(resp.redirect);
                    }
                    else {
                        setReady(true);
                        setCalculatorSettings(resp.data);
                    }
                }
            }
        }
    }

    useEffect(() => {
        getSettings();
    });

    return (
        ready ? (
            currentStep < 3 ? (
                <div className={application}>
                    <h1 className={application__title}>Оформление нового займа</h1>
                    <div className={application__header}>
                        {
                            steps.map(({title}, i) => (
                                <div
                                    key={i}
                                    className={
                                        application__tab + 
                                        (i <= currentStep ? " " + application__tab_active : "") +
                                        (i > valid ? " " + application__tab_disabled : "")
                                    }
                                    onClick={
                                        valid >= i ? () => setStep(i) : null
                                    }
                                >
                                    <span className={application__tabStep}>Шаг {i + 1}</span>&nbsp;<span className={application__tabText}> - {title}</span>
                                </div>
                            ))
                        }
                    </div>
                    <h2 className={application__moileTitle}>{steps[currentStep].title}</h2>

                    {
                        Object.keys(serverErrors).length > 0 ? (
                            <div className={application__error}>
                                Пожалуйста, проверьте анкету. Одно или несколько полей заполнены некорректно.
                            </div>
                        ) : ""
                    }
        
                    <form className={application__form} method="POST" action="">
                        {
                            steps.map(({block}, i) => currentStep === i ? block : "")
                        }
                    </form>
                    <div className={application__footer}>
                        {
                            currentStep > 0 ? (
                                <Button
                                    onClick={() => setStep(currentStep - 1)}
                                    className={application__footerButton}
                                    variant="gray"
                                >
                                    Назад
                                </Button>
                            ) : ""
                        }
                        <Button
                            onClick={() => setStep(currentStep + 1)}
                            className={application__footerButton}
                            disabled={valid <= currentStep}
                        >
                            {
                                currentStep < 2 ? "Далее" : "Отправить заявку"
                            }
                        </Button>
                    </div>
                </div>
            ) : (
                <div style={{minHeight: "70vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
                   <ReactLoading type="spin" color="#2D9614" />
                </div>
            )
        ) : (
            <div style={{minHeight: "70vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
               <ReactLoading type="spin" color="#2D9614" />
            </div>
        )
    );
}

export default Application;