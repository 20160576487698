import MainIntro from "./main_intro";
import MainLoans from "./main_loans";
import MainAdvantages from "./main_advantages";
import MainInstruction from "./main_instruction";
import MainFaq from "./main_faq";
import ContentButton from "./content_button";
import DocumentsList from "./documents_list";
import CircleAdvantages from "./CircleAdvantages";
import AboutAdvantages from "./AboutAdvantages";
import ServicesTwoColumns from "./services_two_columns";
import AboutTwoColumns from "./AboutTwoColumns";
import ServicesCard from "./services_card";
import InfoHtml from "./info_html";
import ArticleHeader from "./TextPage/article_header";
import ArticleList from "./TextPage/article_list";
import ArticleSubHeader from "./TextPage/article_sub_header";
import ArticleText from "./TextPage/article_text";
import YaRek from "./YaRek";

const library = {
   main_intro: MainIntro,
   main_loans: MainLoans,
   main_advantages: MainAdvantages,
   main_instruction: MainInstruction,
   main_faq: MainFaq,
   button: ContentButton,
   circle_advantages: CircleAdvantages,
   about_two_columns: AboutTwoColumns,
   about_advantages: AboutAdvantages,
   documents_list: DocumentsList,
   services_two_columns: ServicesTwoColumns,
   services_card: ServicesCard,
   info_html: InfoHtml,
   article_header: ArticleHeader,
   article_list: ArticleList,
   article_sub_header: ArticleSubHeader,
   article_text: ArticleText,

   yandex_reklama: YaRek,
};

export default library;
