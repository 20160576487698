import { useState } from "react";
import IconArrowLeft from "../../manage/Components/Icons/IconArrowLeft";
import IconArrowRight from "../../manage/Components/Icons/IconArrowRight";
import IconDelete from "../../manage/Components/Icons/IconDelete";
import Button from "../Button";
import IconArrowFaq from "../Icons/ArrowFaq";
import PageEditorFloatingTools, { createFloatingTool } from "../PageEditorFloatingTools";
import css from "./styles.module.scss";

const {
    faq,
    faq__title,
    faq__block,
    faq__item,
    faq__itemHeader,
    faq__question,
    faq__button,
    faq__buttonIcon_selected,
    faq__buttonIcon,
    faq__answer,
    faq__answer_shown
} = css;

const MainFaq = ({
    data,

    editable = false,
    blockIndex = -1,
    onChange = () => {},
    onChangeGlobal = () => {}
}) => {

    const {
        title,
        questions
    } = data;

    const [selectedQuestion, selectQuestion] = useState(0);

    const defaultHeader = title + "";
    const defaultQuestions = [];

    questions.forEach (question => {
        defaultQuestions.push({
            question: question.question + "",
            answer: question.answer + ""
        });
    });

    const addQuestion = () => {
        const tmpData = JSON.parse(JSON.stringify(data));
        
        const questionTemplate = {
            question: "Вопрос",
            answer: "Ответ"
        };

        tmpData.questions.push(questionTemplate);
        defaultQuestions.push(questionTemplate);

        onChangeGlobal(blockIndex, tmpData);
    }

    const moveQuestion = (index, position) => {
        const newPosition = index + position;

        if (newPosition >= 0 && newPosition < questions.length) {
            const tmpData = JSON.parse(JSON.stringify(data));
    
            tmpData.questions.splice(index, 1);
            tmpData.questions.splice(newPosition, 0, questions[index]);

            defaultQuestions.splice(index, 1);
            defaultQuestions.splice(newPosition, 0, JSON.parse(JSON.stringify(questions[index])));
            
            onChangeGlobal(blockIndex, tmpData);
        }
    }

    const deleteQuestion = (index) => {
        if (index >= 0 && index < questions.length) {
            const tmpData = JSON.parse(JSON.stringify(data));
    
            tmpData.questions.splice(index, 1);

            defaultQuestions.splice(index, 1);
            
            onChangeGlobal(blockIndex, tmpData);
        }
    }

    return (
        <section className={faq}>
            <h2
                className={faq__title}
                contentEditable={editable} suppressContentEditableWarning={true}
                value={title}
                onChange={e => onChange(blockIndex, "title", e.target.textContent)}
                onBlur={e => onChange(blockIndex, "title", e.target.textContent)}
            >
                {defaultHeader}
            </h2>
            <div className={faq__block}>
                {
                    questions.map((question, i) => (
                        <div className={faq__item} key={i}>
                            <div
                                className={faq__itemHeader}
                                onClick={()=>selectQuestion(i === selectedQuestion ? -1 : i)}
                            >
                                <h3
                                    className={faq__question}

                                    contentEditable={editable} suppressContentEditableWarning={true}
                                    value={question.question}
    
                                    onChange={e => onChange(blockIndex, "questions." + i + ".question", e.target.textContent)}
                                    onBlur={e => onChange(blockIndex, "questions." + i + ".question", e.target.textContent)}
                                >
                                    {defaultQuestions[i].question}
                                </h3>
                                {
                                    !editable ? (
                                        <div className={faq__button}>
                                            <IconArrowFaq
                                                className={faq__buttonIcon + (
                                                    i === selectedQuestion || editable ? " " + faq__buttonIcon_selected : ""
                                                )}
                                            />
                                        </div>
                                    ) : ""
                                }

                                {
                                    editable ? (
                                        <PageEditorFloatingTools
                                            tools={[
                                                i > 0 ? createFloatingTool("Переместить назад", <IconArrowLeft />, () => moveQuestion(i, -1)) : {},
                                                i < questions.length - 1 ? createFloatingTool("Переместить вперёд", <IconArrowRight />, () => moveQuestion(i, 1)) : {},
                                                createFloatingTool("Удалить вопрос", <IconDelete />, () => deleteQuestion(i))
                                            ]}
                                        />
                                    ) : ""
                                }
                            </div>
                            <div
                                className={faq__answer + (i === selectedQuestion || editable ? " " + faq__answer_shown : "")}

                                contentEditable={editable} suppressContentEditableWarning={true}
                                value={question.answer}

                                onChange={e => onChange(blockIndex, "questions." + i + ".answer", e.target.textContent)}
                                onBlur={e => onChange(blockIndex, "questions." + i + ".answer", e.target.textContent)}
                            >
                                {defaultQuestions[i].answer}
                            </div>
                        </div>
                    ))
                }

                {
                    editable ? (
                        <Button onClick={addQuestion}>
                            Добавить вопрос
                        </Button>
                    ) : ""
                }
            </div>
        </section>
    );
}

export default MainFaq;