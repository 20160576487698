function IconArrowSlider(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="20" viewBox="0 0 36 20" fill="none" {...props}>
         <path
            d="M34.5 10L1 10M34.5 10L25.5 19M34.5 10L25.5 0.999999"
            stroke="#808080"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   );
}

export default IconArrowSlider;
