import { useEffect } from "react";
import { useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import api from "../../helpers/fetch";
import BackMenu from "../BackMenu";
import BlockWrapper from "../BlockWrapper";
import Button from "../Button";
import Input from "../Input";
import PACardWrapper from "../PersonalArea/CardWrapper";

import scss from "./index.module.scss";

const {
   PaymentUnauthorized__container,
   PaymentUnauthorized__wrapper,
   PaymentUnauthorized__title,
   PaymentUnauthorized__text,
   PaymentUnauthorized__secondaryText,
   PaymentUnauthorized__form,
   PaymentUnauthorized__input,
   PaymentUnauthorized__button,
   wrapper,
   cards,
   cards__current,
   cards__title,
   cards__grid,
   cards__grid_white,
   cards__grid_actions,
   cards__pay,
   cards__title_black,
   container,
} = scss;

function PaymentUnauthorized() {

   const navigate = useNavigate();
   const param = useParams();

   const [number, setNumber] = useState("");
   const [numberError, setNumberError] = useState(false);
   const [date, setDate] = useState("");
   const [dateError, setDateError] = useState(false);

   const [wait, setWait] = useState(false);
   const [response, setResponse] = useState();

   const [errorMessage, setErrorMessage] = useState(false);

   const getCompact = async (e) => {
      e.preventDefault();
      setWait(true);
      setDateError(false);
      setNumberError(false);
      setErrorMessage(false);
      const request = new api(`payments?number=${number}&bday=${date}`, false);
      const res = await request.send();
      
      setWait(false);

      if (res.status === "success") {
         setResponse(res);
      }
      else {
         if (res.message === "Can't get payment information") {
            setErrorMessage("Не удалось получить платёжную информацию.");
         }
         else if (res.message === "Loan not found") {
            setNumberError("Заём с таким номером не найден.");
         }
         else if (res.message === "Bday is invalid" || res.message === "Bday is empty") {
            setDateError("Дата рождения не указана или указана не корректно.")
         }
         else if (res.message === "Number is invalid" || res.message === "Number is empty") {
            setNumberError("Номер не указан или указан не корректно.");
         }
         else {
            navigate("/404");
         }
      }
   };

   const resetError = () => {
      if (number) {
         setNumberError(false);
      }
      if (date) {
         setDateError(false);
      }
   }

   const onChangeNumber = (number) => {
      setNumber(number);
      resetError();
   };

   const onChangeDate = (date) => {
      setDate(date);
      resetError();
   };

   return param["token"] ? (
      <section className={container}>
         <BackMenu>
            <Outlet />
         </BackMenu>
      </section>
   ) : (
      <section className={PaymentUnauthorized__container}>
         {!response ? (
            <BlockWrapper className={PaymentUnauthorized__wrapper}>
               <h2 className={PaymentUnauthorized__title}>Поиск займа для оплаты</h2>
               <p className={PaymentUnauthorized__text}>{errorMessage ? errorMessage : "По номеру договора"}</p>
               <form className={PaymentUnauthorized__form} onSubmit={getCompact} action="">
                  <Input
                     className={PaymentUnauthorized__input}
                     type="text"
                     label={"Номер договора"}
                     placeholder="Номер договора"
                     name="number"
                     regex={/[0-9]/}
                     value={number}
                     onChange={(number) => onChangeNumber(number)}
                     error={numberError}
                  />
                  <div className={wrapper}>
                     <Input
                        className={PaymentUnauthorized__input}
                        type="date"
                        placeholder="01.01.2000"
                        label={"Дата рождения"}
                        mask="XX.XX.XXXX"
                        name="date"
                        regex={/[0-9]/}
                        value={date}
                        onChange={(date) => onChangeDate(date)}
                        error={dateError}
                     />
                     <Button
                        isWaiting={wait}
                        className={PaymentUnauthorized__button}
                        type={"sabmit"}
                        disabled={!number || !date || numberError || dateError}
                     >
                        Найти
                     </Button>
                  </div>
               </form>
            </BlockWrapper>
         ) : (
            <section className={cards}>
               <PACardWrapper className={cards__pay}>
                  <h3 className={cards__title + " " + cards__title_black}>Минимальный платёж</h3>
                  <div className={cards__grid}>
                     <span>
                        <strong>
                           {parseFloat(response?.data?.minimal > 0 ? response?.data?.minimal / 100 : 0).toLocaleString(
                              "ru-RU"
                           )}{" "}
                           ₽
                        </strong>{" "}
                        c процентами
                     </span>
                  </div>
                  <Button
                     href={"/oplatit/" + response?.data?.token}
                     variant={"gray"}
                     disabled={response?.data?.minimal <= 0}
                  >
                     {response?.data?.minimal > 0 ? "Внести платёж" : "Платёж оплачен"}
                  </Button>
               </PACardWrapper>
               <PACardWrapper className={cards__current}>
                  <h3 className={cards__title}>Погасить задолженность</h3>
                  <div className={cards__grid + " " + cards__grid_white}>
                     <span>
                        <strong>{parseFloat(response?.data?.total / 100).toLocaleString("ru-RU")} ₽</strong> c
                        процентами
                     </span>
                  </div>
                  <div className={cards__grid + " " + cards__grid_white + " " + cards__grid_actions}>
                     <Button variant={"white"} href={"/oplatit/" + response?.data?.token + "/total"}>
                        {"Погасить заём"}
                     </Button>
                  </div>
               </PACardWrapper>
            </section>
         )}
         <p className={PaymentUnauthorized__secondaryText}>
            Указывая номер договора и дату рождения, вы соглашаетесь на обработку{" "}
            <Link to="/politika-konfidencialnosti">персональных данных</Link>.
         </p>
      </section>
   );
}

export default PaymentUnauthorized;
