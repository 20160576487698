import scss from "../index.module.scss";

const { section, subtitle } = scss;

function ArticleSubHeader({ data }) {
   return (
      <section className={section}>
         <span className={subtitle}>{data.text}</span>
      </section>
   );
}

export default ArticleSubHeader;
