function IconMir(props) {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="100"
         height="28"
         fill="none"
         viewBox="0 0 100 28"
         {...props}
      >
         <path
            fill="#fff"
            fillRule="evenodd"
            d="M9.25.73c.9 0 3.55-.25 4.67 3.55.76 2.56 1.96 6.74 3.62 12.56h.68c1.77-6.13 3-10.32 3.66-12.56C23 .44 25.84.73 26.98.73h8.78V28h-8.94V11.93h-.6L21.24 28h-6.72L9.54 11.92h-.6V28H0V.73h9.25zm39.34 0V16.8h.71l6.06-13.2C56.54.95 59.04.73 59.04.73h8.65V28h-9.12V11.92h-.72l-5.94 13.22a4.93 4.93 0 01-3.8 2.86h-8.64V.73h9.12zm50.63 12.96c-1.27 3.6-5.27 6.19-9.7 6.19h-9.56V28H71.3V13.69h27.93zM89.95.73H70.83C71.3 6.8 76.52 12 81.93 12h17.9C100.85 6.95 97.3.73 89.94.73z"
            clipRule="evenodd"
         ></path>
      </svg>
   );
}

export default IconMir;
