function IconOops(props) {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="256"
         height="256"
         fill="none"
         viewBox="0 0 256 256"
         {...props}
      >
         <path
            stroke="#D2C2D4"
            strokeWidth="11.2"
            d="M66.12 88.22h34.08a8 8 0 006.47-3.3l22.24-30.68"
         ></path>
         <path
            stroke="url(#a)"
            strokeWidth="11.2"
            d="M66.12 88.22h34.08a8 8 0 006.47-3.3l22.24-30.68"
         ></path>
         <path
            stroke="url(#b)"
            strokeWidth="11.2"
            d="M66.12 88.22h34.08a8 8 0 006.47-3.3l22.24-30.68"
         ></path>
         <path
            stroke="#D2C2D4"
            strokeWidth="11.2"
            d="M190.02 88.22h-34.08a8 8 0 01-6.48-3.3l-22.24-30.68"
         ></path>
         <path
            stroke="url(#c)"
            strokeWidth="11.2"
            d="M190.02 88.22h-34.08a8 8 0 01-6.48-3.3l-22.24-30.68"
         ></path>
         <path stroke="url(#d)" strokeWidth="8" d="M64.26 97.58v69.98"></path>
         <path stroke="url(#e)" strokeWidth="8" d="M107.74 163.77l-41.62-66.2"></path>
         <path stroke="url(#f)" strokeWidth="8" d="M107.74 163.77l-41.62-66.2"></path>
         <path stroke="url(#g)" strokeWidth="8" d="M191.65 97.58v69.98"></path>
         <path stroke="url(#h)" strokeWidth="8" d="M148.62 163.77l41.64-66.2"></path>
         <g filter="url(#i)">
            <path stroke="#F8DDE7" strokeWidth="2" d="M148.62 163.77l41.64-66.2"></path>
         </g>
         <path stroke="url(#j)" strokeWidth="8" d="M235.14 163.77l-41.64-66.2"></path>
         <path stroke="url(#k)" strokeWidth="8" d="M235.14 163.77l-41.64-66.2"></path>
         <path
            fill="url(#l)"
            d="M191.88 101.05a13.02 13.02 0 100-26.03 13.02 13.02 0 000 26.03z"
         ></path>
         <path
            fill="url(#m)"
            d="M191.88 101.05a13.02 13.02 0 100-26.03 13.02 13.02 0 000 26.03z"
         ></path>
         <path
            fill="url(#n)"
            d="M191.88 101.05a13.02 13.02 0 100-26.03 13.02 13.02 0 000 26.03z"
         ></path>
         <path
            fill="url(#o)"
            d="M148.76 159.98c-3.31 0-5.8 3.08-4.68 6.2a50.73 50.73 0 0095.6 0c1.11-3.12-1.37-6.2-4.68-6.2h-86.24z"
         ></path>
         <path
            fill="url(#p)"
            d="M148.76 159.98c-3.31 0-5.8 3.08-4.68 6.2a50.73 50.73 0 0095.6 0c1.11-3.12-1.37-6.2-4.68-6.2h-86.24z"
         ></path>
         <path
            fill="url(#q)"
            d="M148.76 159.98c-3.31 0-5.8 3.08-4.68 6.2a50.73 50.73 0 0095.6 0c1.11-3.12-1.37-6.2-4.68-6.2h-86.24z"
         ></path>
         <path
            fill="url(#r)"
            d="M148.76 159.98c-3.31 0-5.8 3.08-4.68 6.2a50.73 50.73 0 0095.6 0c1.11-3.12-1.37-6.2-4.68-6.2h-86.24z"
         ></path>
         <path
            fill="url(#s)"
            d="M148.76 159.98c-3.31 0-5.8 3.08-4.68 6.2a50.73 50.73 0 0095.6 0c1.11-3.12-1.37-6.2-4.68-6.2h-86.24z"
         ></path>
         <path
            fill="url(#t)"
            d="M148.76 159.98c-3.31 0-5.8 3.08-4.68 6.2a50.73 50.73 0 0095.6 0c1.11-3.12-1.37-6.2-4.68-6.2h-86.24z"
         ></path>
         <path stroke="url(#u)" strokeWidth="8" d="M21.24 163.77l41.63-66.2"></path>
         <g filter="url(#v)">
            <path stroke="#F8DDE7" strokeWidth="2" d="M22.5 163.77l41.62-66.2"></path>
         </g>
         <path
            fill="url(#w)"
            d="M21 159.98c-3.31 0-5.78 3.08-4.68 6.2a50.73 50.73 0 0095.6 0c1.11-3.12-1.36-6.2-4.68-6.2H21.01z"
         ></path>
         <path
            fill="url(#x)"
            d="M21 159.98c-3.31 0-5.78 3.08-4.68 6.2a50.73 50.73 0 0095.6 0c1.11-3.12-1.36-6.2-4.68-6.2H21.01z"
         ></path>
         <path
            fill="url(#y)"
            d="M21 159.98c-3.31 0-5.78 3.08-4.68 6.2a50.73 50.73 0 0095.6 0c1.11-3.12-1.36-6.2-4.68-6.2H21.01z"
         ></path>
         <path
            fill="url(#z)"
            d="M21 159.98c-3.31 0-5.78 3.08-4.68 6.2a50.73 50.73 0 0095.6 0c1.11-3.12-1.36-6.2-4.68-6.2H21.01z"
         ></path>
         <path
            fill="url(#A)"
            d="M21 159.98c-3.31 0-5.78 3.08-4.68 6.2a50.73 50.73 0 0095.6 0c1.11-3.12-1.36-6.2-4.68-6.2H21.01z"
         ></path>
         <path
            fill="url(#B)"
            d="M21 159.98c-3.31 0-5.78 3.08-4.68 6.2a50.73 50.73 0 0095.6 0c1.11-3.12-1.36-6.2-4.68-6.2H21.01z"
         ></path>
         <path
            fill="url(#C)"
            d="M64.12 101.05a13.02 13.02 0 100-26.03 13.02 13.02 0 000 26.03z"
         ></path>
         <path
            fill="url(#D)"
            d="M64.12 101.05a13.02 13.02 0 100-26.03 13.02 13.02 0 000 26.03z"
         ></path>
         <path
            fill="url(#E)"
            d="M64.12 101.05a13.02 13.02 0 100-26.03 13.02 13.02 0 000 26.03z"
         ></path>
         <g filter="url(#F)">
            <path
               stroke="url(#G)"
               strokeLinecap="round"
               strokeWidth="3.2"
               d="M131.65 55.62l22.13 30.5"
            ></path>
         </g>
         <path stroke="url(#H)" strokeWidth="12" d="M128 35v178.28"></path>
         <path stroke="url(#I)" strokeWidth="12" d="M128 35v178.28"></path>
         <path stroke="url(#J)" strokeWidth="12" d="M128 35v178.28"></path>
         <path stroke="url(#K)" strokeWidth="12" d="M128 35v178.28"></path>
         <path stroke="url(#L)" strokeWidth="12" d="M128 35v178.28"></path>
         <path
            fill="url(#M)"
            d="M185.52 239.7c5.12 0 7.74-5.9 3.88-9.28a92.63 92.63 0 00-61.4-23.17 92.64 92.64 0 00-61.4 23.16c-3.85 3.39-1.25 9.28 3.88 9.28h115.04v.01z"
         ></path>
         <path
            fill="url(#N)"
            d="M185.52 239.7c5.12 0 7.74-5.9 3.88-9.28a92.63 92.63 0 00-61.4-23.17 92.64 92.64 0 00-61.4 23.16c-3.85 3.39-1.25 9.28 3.88 9.28h115.04v.01z"
         ></path>
         <path
            fill="url(#O)"
            d="M185.52 239.7c5.12 0 7.74-5.9 3.88-9.28a92.63 92.63 0 00-61.4-23.17 92.64 92.64 0 00-61.4 23.16c-3.85 3.39-1.25 9.28 3.88 9.28h115.04v.01z"
         ></path>
         <path
            fill="url(#P)"
            d="M185.52 239.7c5.12 0 7.74-5.9 3.88-9.28a92.63 92.63 0 00-61.4-23.17 92.64 92.64 0 00-61.4 23.16c-3.85 3.39-1.25 9.28 3.88 9.28h115.04v.01z"
         ></path>
         <path
            fill="url(#Q)"
            d="M185.52 239.7c5.12 0 7.74-5.9 3.88-9.28a92.63 92.63 0 00-61.4-23.17 92.64 92.64 0 00-61.4 23.16c-3.85 3.39-1.25 9.28 3.88 9.28h115.04v.01z"
         ></path>
         <g filter="url(#R)">
            <path stroke="url(#S)" strokeWidth="3.2" d="M131.14 39.25v137"></path>
         </g>
         <path
            fill="url(#T)"
            d="M128 42.34a13.02 13.02 0 100-26.03 13.02 13.02 0 000 26.03z"
         ></path>
         <path
            fill="url(#U)"
            d="M128 42.34a13.02 13.02 0 100-26.03 13.02 13.02 0 000 26.03z"
         ></path>
         <path
            fill="url(#V)"
            d="M128 42.34a13.02 13.02 0 100-26.03 13.02 13.02 0 000 26.03z"
         ></path>
         <defs>
            <radialGradient
               id="a"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(11.3 0 0 19.8 73.98 88.22)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.29" stopColor="#BDAAB9"></stop>
               <stop offset="1" stopColor="#BDAAB9" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="b"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(41 -33 25.6 31.7 83.9 95.5)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.73" stopColor="#99839C" stopOpacity="0"></stop>
               <stop offset="0.99" stopColor="#99839C"></stop>
            </radialGradient>
            <radialGradient
               id="c"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-11.3 0 0 -19.8 182.14 88.22)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.29" stopColor="#BDAAB9"></stop>
               <stop offset="1" stopColor="#BDAAB9" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="l"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-7.3 19.5 -20.3 -7.6 199.2 81.51)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.32" stopColor="#FFC539"></stop>
               <stop offset="1" stopColor="#F6973E"></stop>
            </radialGradient>
            <radialGradient
               id="m"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-10 10.2 -11 -10.8 204.9 80.9)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFF361"></stop>
               <stop offset="1" stopColor="#FFF361" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="n"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 -11 9.5 0 191.88 102.67)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E98856"></stop>
               <stop offset="1" stopColor="#E98856" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="o"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 38.4 -71.5 0 191.88 161.5)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.48" stopColor="#FFB85E"></stop>
               <stop offset="1" stopColor="#FF9D5B"></stop>
            </radialGradient>
            <radialGradient
               id="q"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-26.3 35 -23.2 -17.4 229.4 155.5)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFC635"></stop>
               <stop offset="1" stopColor="#FFC635" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="r"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 36 -69.2 0 191.88 164)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.59" stopColor="#FD9860" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#F18E9D"></stop>
            </radialGradient>
            <radialGradient
               id="s"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(34.2 45.4 -63.4 47.8 181.99 157.65)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.83" stopColor="#FFB651" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#E29471"></stop>
            </radialGradient>
            <radialGradient
               id="t"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 9 -66.6 0 223.9 164)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFF263"></stop>
               <stop offset="1" stopColor="#FFD049" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="w"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 38.4 -71.5 0 64.12 161.5)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.48" stopColor="#FFB85E"></stop>
               <stop offset="1" stopColor="#FF9D5B"></stop>
            </radialGradient>
            <radialGradient
               id="y"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-26.3 35 -23.2 -17.4 101.65 155.5)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFC635"></stop>
               <stop offset="1" stopColor="#FFC635" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="z"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 9 -66.6 0 96.14 164)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFF263"></stop>
               <stop offset="1" stopColor="#FFD049" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="A"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 36 -69.2 0 64.12 164)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.59" stopColor="#FD9860" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#F18E9D"></stop>
            </radialGradient>
            <radialGradient
               id="B"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(34.2 45.4 -63.4 47.8 53.5 157.3)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.83" stopColor="#FFB651" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#E29471"></stop>
            </radialGradient>
            <radialGradient
               id="C"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-7.5 19.5 -20.3 -7.8 71.41 82.02)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.32" stopColor="#FFC539"></stop>
               <stop offset="1" stopColor="#F6973E"></stop>
            </radialGradient>
            <radialGradient
               id="D"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-10 10.2 -11 -10.8 77.13 80.89)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFF361"></stop>
               <stop offset="1" stopColor="#FFF361" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="E"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 -11 9.5 0 64.12 102.67)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E98856"></stop>
               <stop offset="1" stopColor="#E98856" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient id="I" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse">
               <stop stopColor="#B59CAD"></stop>
               <stop offset="1" stopColor="#B59CAD" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient id="L" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse">
               <stop offset="0.92" stopColor="#92767E" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#92767E"></stop>
            </radialGradient>
            <radialGradient
               id="N"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-59.4 0 0 -21.5 187.4 238)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFFF64"></stop>
               <stop offset="1" stopColor="#FFFF64" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="P"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-13.5 -43 124.3 -39 122.9 247.75)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.57" stopColor="#EB9E40" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#EB9E40"></stop>
            </radialGradient>
            <radialGradient
               id="Q"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 -30 102.9 0 128 235)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.69" stopColor="#DDB761" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#D29F5F"></stop>
            </radialGradient>
            <radialGradient
               id="T"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-7.5 19.5 -20.3 -7.8 135.7 23.34)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.32" stopColor="#FFC539"></stop>
               <stop offset="1" stopColor="#F6973E"></stop>
            </radialGradient>
            <radialGradient
               id="U"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-10 10.2 -11 -10.8 141.02 22.19)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFF361"></stop>
               <stop offset="1" stopColor="#FFF361" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="V"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 -11 9.5 0 128 43.97)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E98856"></stop>
               <stop offset="1" stopColor="#E98856" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="d"
               x1="-nan"
               x2="-nan"
               y1="-nan"
               y2="-nan"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.19" stopColor="#BBA1B3"></stop>
               <stop offset="1" stopColor="#D7B7C8"></stop>
            </linearGradient>
            <linearGradient
               id="e"
               x1="84.02"
               x2="89.9"
               y1="130.67"
               y2="126.5"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.17" stopColor="#D0B6C3"></stop>
               <stop offset="1" stopColor="#E6D2E4"></stop>
            </linearGradient>
            <linearGradient
               id="f"
               x1="82.65"
               x2="84.24"
               y1="131.16"
               y2="130.13"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C2A6B1"></stop>
               <stop offset="1" stopColor="#C2A6B1" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="g"
               x1="-nan"
               x2="-nan"
               y1="-nan"
               y2="-nan"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.19" stopColor="#BBA1B3"></stop>
               <stop offset="1" stopColor="#D7B7C8"></stop>
            </linearGradient>
            <linearGradient
               id="h"
               x1="163.78"
               x2="171.54"
               y1="130.67"
               y2="136.25"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C1AFC0"></stop>
               <stop offset="1" stopColor="#F6D6DE"></stop>
            </linearGradient>
            <linearGradient
               id="j"
               x1="211.41"
               x2="217.28"
               y1="130.67"
               y2="126.5"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.17" stopColor="#D0B6C3"></stop>
               <stop offset="1" stopColor="#E6D2E4"></stop>
            </linearGradient>
            <linearGradient
               id="k"
               x1="210.03"
               x2="211.62"
               y1="131.16"
               y2="130.13"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C2A6B1"></stop>
               <stop offset="1" stopColor="#C2A6B1" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="p"
               x1="191.88"
               x2="191.88"
               y1="159.98"
               y2="169.25"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFCF4D"></stop>
               <stop offset="1" stopColor="#FFCF4D" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="u"
               x1="36.4"
               x2="44.14"
               y1="130.67"
               y2="136.25"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C1AFC0"></stop>
               <stop offset="1" stopColor="#F6D6DE"></stop>
            </linearGradient>
            <linearGradient
               id="x"
               x1="64.12"
               x2="64.12"
               y1="159.98"
               y2="169.25"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFCF4D"></stop>
               <stop offset="1" stopColor="#FFCF4D" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="G"
               x1="130.9"
               x2="155.37"
               y1="62.06"
               y2="95.85"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FDEBFF"></stop>
               <stop offset="1" stopColor="#FDEBFF" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="H"
               x1="-nan"
               x2="-nan"
               y1="-nan"
               y2="-nan"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C3AEC2"></stop>
               <stop offset="1" stopColor="#E7D3E6"></stop>
            </linearGradient>
            <linearGradient
               id="J"
               x1="-nan"
               x2="-nan"
               y1="-nan"
               y2="-nan"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#BC9EA3"></stop>
               <stop offset="1" stopColor="#BC9EA3" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="K"
               x1="-nan"
               x2="-nan"
               y1="-nan"
               y2="-nan"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#A38B8F"></stop>
               <stop offset="1" stopColor="#CBB6CA" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="M"
               x1="128"
               x2="128"
               y1="207.26"
               y2="237.75"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.25" stopColor="#F9C63C"></stop>
               <stop offset="0.75" stopColor="#FFCE37"></stop>
            </linearGradient>
            <linearGradient
               id="O"
               x1="141.4"
               x2="141.4"
               y1="239.7"
               y2="233.75"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FA8694"></stop>
               <stop offset="1" stopColor="#FFA166" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="S"
               x1="-nan"
               x2="-nan"
               y1="-nan"
               y2="-nan"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#EDD9EE"></stop>
               <stop offset="1" stopColor="#EDD9EE" stopOpacity="0"></stop>
            </linearGradient>
            <filter
               id="i"
               width="49.73"
               height="73.66"
               x="144.58"
               y="93.84"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
               ></feBlend>
               <feGaussianBlur
                  result="effect1_foregroundBlur_2346_6280"
                  stdDeviation="1.6"
               ></feGaussianBlur>
            </filter>
            <filter
               id="v"
               width="49.72"
               height="73.66"
               x="18.45"
               y="93.84"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
               ></feBlend>
               <feGaussianBlur
                  result="effect1_foregroundBlur_2346_6280"
                  stdDeviation="1.6"
               ></feGaussianBlur>
            </filter>
            <filter
               id="F"
               width="31.73"
               height="40.1"
               x="126.85"
               y="50.82"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
               ></feBlend>
               <feGaussianBlur
                  result="effect1_foregroundBlur_2346_6280"
                  stdDeviation="1.6"
               ></feGaussianBlur>
            </filter>
            <filter
               id="R"
               width="9.6"
               height="143.4"
               x="126.34"
               y="36.05"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
               ></feBlend>
               <feGaussianBlur
                  result="effect1_foregroundBlur_2346_6280"
                  stdDeviation="1.6"
               ></feGaussianBlur>
            </filter>
         </defs>
      </svg>
   );
}

export default IconOops;
