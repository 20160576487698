import { wait } from "@testing-library/user-event/dist/utils";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyData from "../../../components/EmptyData";
import Table from "../../../components/Table";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import FulscreenLoader from "../../Components/FulscreenLoader";
import Head from "../../Components/Head";
import IconButton from "../../Components/IconButton";
import IconEdit from "../../Components/Icons/IconEdit";
import IconFilter from "../../Components/Icons/IconFilter";

function Pages() {
   const navigate = useNavigate();

   const [wait, setWait] = useState(false);

   const [sort, setSort] = useState([]);

   const [story, setStory] = useState(["title", "asc"]);

   const [search, setSearch] = useState("");

   const [pagination, setPagination] = useState({});

   const [paginationPage, setPaginationPage] = useState(1);

   const toTable = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value: el.title,
            },
            {
               type: "link",
               value: window.location.origin + "/" + el.pathname,
            },
            {
               type: "component",
               value: (
                  <IconButton onClick={() => navigate("/manage/pages/" + el.id_page)}>
                     <IconEdit />
                  </IconButton>
               ),
            },
         ]);
      });
      return tmpArray;
   };

   const getData = async () => {
      const auth = checkAuth();
      let queryParams = getSortQuery("title");
      const response = await new api(
         `manage/pages?${search ? "search=" + search : ""}` +
            queryParams +
            "&page=" +
            (paginationPage !== 0 && paginationPage ? paginationPage : 1),
         auth
      ).send();
      if (response.status === "success") {
         setStory(toTable(response.data.data));
         setPagination(response.data.pagination);
         setWait(true);
      }
   };

   const getSearch = (q) => {
      setSearch(q);
   };

   const getSortQuery = (nameField) => {
      if (sort.length === 0) {
         setSort([nameField, "asc"]);
         return "";
      } else {
         return "&sort_param=" + sort[0] + "&sort_direction=" + sort[1];
      }
   };

   useEffect(() => {
      getData();
   }, []);

   useEffect(() => {
      getData();
      onPaginate(1);
   }, [sort, search]);

   useEffect(() => {
      getData();
   }, [paginationPage]);

   const onPaginate = (number) => {
      setPaginationPage(number);
   };

   return wait ? (
      <>
         <Head onLeavePresEnter={getSearch} buttonIcon={<IconFilter />} searchButton />
         <Table
            rows={story}
            colums={[
               {
                  paramName: "title",
                  name: "Наименование страницы",
               },
               {
                  paramName: "pathname",
                  name: "Ссылка",
               },
               {
                  paramName: "action",
                  name: "Действия",
               },
            ]}
            onSort={(param, direction) => setSort([param, direction])}
            sortParam={sort[0]}
            sortDirection={sort[1]}
            baseColumnsWidth={[null, 390, 160]}
            blockOnEmpty={
               <EmptyData
                  title="Нет данных"
                  text="По вашему запросу ничего не найдено."
               />
            }
            pagination={pagination}
            onPaginate={onPaginate}
         />
      </>
   ) : (
      <FulscreenLoader />
   );
}

export default Pages;
