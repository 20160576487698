import { Outlet } from "react-router-dom";
import BackMenu from "../../components/BackMenu";
import scss from "./index.module.scss";

const { payment } = scss;

function Payment() {
   return (
      <>
         <section className={payment}>
            <BackMenu>
               <Outlet />
            </BackMenu>
         </section>
      </>
   );
}

export default Payment;
