import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import EmptyData from "../../../components/EmptyData";
import Table from "../../../components/Table";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import getFIO from "../../../helpers/getFIO";
import FulscreenLoader from "../../Components/FulscreenLoader";
import Head from "../../Components/Head";

import scss from "./index.module.scss";

const { link } = scss;

function Customers() {
   const [wait, setWait] = useState(false);
   const [row, setRow] = useState([]);
   const [colums] = useState([
      {
         paramName: "surname",
         name: "ФИО",
      },
      {
         paramName: "number",
         name: "Текущий займ",
      },
      {
         paramName: "bday",
         name: "Дата рождения",
      },
      {
         paramName: "reg_date",
         name: "Дата регистрации",
      },
      {
         paramName: "action",
         name: "Действия",
      },
   ]);
   const [sort, setSort] = useState(["surname", "asc"]);
   const [search, setSearch] = useState("");
   const [pagination, setPagination] = useState({});
   const [paginationPage, setPaginationPage] = useState(1);

   const toTable = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value:
                  el.name || el.surname
                     ? getFIO(el.name, el.surname, el.midname, "ser-name-mid")
                     : "Новый клиент",
            },
            {
               type: el.total_amount ? "money" : "text",
               value: el.total_amount ? el.total_amount : "Нет",
            },
            {
               type: el.bday ? "date" : "text",
               value: el.bday ? el.bday : "Не указано",
            },
            {
               type: "date",
               value: el.reg_date,
            },
            {
               type: "component",
               value: (
                  <Link className={link} to={"/manage/customers/" + el.id_user}>
                     Подробнее
                  </Link>
               ),
            },
         ]);
      });
      return tmpArray;
   };

   const getData = async () => {
      const auth = checkAuth();
      let queryParams = getSortQuery("surname");
      const response = await new api(
         `manage/clients?${search.length > 0 ? "search=" + search : ""}` +
            queryParams +
            "&page=" +
            (paginationPage !== 0 && paginationPage ? paginationPage : 1),
         auth
      ).send();
      if (response.status === "success") {
         setRow(toTable(response.data.data));
         setPagination(response.data.pagination);
         setWait(true);
      }
   };

   const getSearch = (q) => {
      setSearch(q);
   };

   const getSortQuery = (nameField) => {
      if (sort.length === 0) {
         setSort([nameField, "asc"]);
         return "";
      } else {
         return "&sort_param=" + sort[0] + "&sort_direction=" + sort[1];
      }
   };

   useEffect(() => {
      getData();
   }, []);

   useEffect(() => {
      getData();
      onPaginate(1);
   }, [sort, search]);

   useEffect(() => {
      getData();
   }, [paginationPage]);

   const onPaginate = (number) => {
      setPaginationPage(number);
   };

   return wait ? (
      <>
         <Head onLeavePresEnter={getSearch} searchButton />
         <Table
            rows={row}
            colums={colums}
            onSort={(param, direction) => setSort([param, direction])}
            sortParam={sort[0]}
            sortDirection={sort[1]}
            baseColumnsWidth={[null, 256, 256, 256, 160]}
            blockOnEmpty={
               <EmptyData
                  title="Нет данных"
                  text="Данные отсутвуют. Вероятно, активных займов еще нет."
               />
            }
            pagination={pagination}
            onPaginate={onPaginate}
         />
      </>
   ) : (
      <FulscreenLoader />
   );
}

export default Customers;
