import React from "react";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Button from "../../components/Button";
import PACardWrapper from "../../components/PersonalArea/CardWrapper";

import checkAuth from "../../helpers/checkAuth";
import api from "../../helpers/fetch";
import logOut from "../../helpers/logout";

import ReactLoading from "react-loading";

import scss from "./index.module.scss";
import EmptyData from "../../components/EmptyData";
import CurrentCard from "./CurrentCard";
import NotifyCard from "./NotifyCard";
import ServiceList from "../../components/PersonalArea/ServiceList";
import LoanHistoryList from "../../components/PersonalArea/LoanHistoryList";
import Picture from "../../components/Picture";
import IconCopy from "../../components/Icons/Copy";
import IconMir from "../../components/Icons/IconMir";
import Document from "../../components/Icons/Document";
import IconPersonFeedback from "../../manage/Components/Icons/IconPersonFeedback";
import PopUp from "../../manage/Components/PopUp";
import WhatsApp from "../../components/Icons/WhatsApp";
import Phone from "../../components/Icons/Phone";
import Email from "../../components/Icons/Email";
import formatPhone from "../../helpers/formatPhone";

const {
   cards,
   cards__current,
   cards__title,
   cards__text,
   services,
   services__header,
   services__title,
   services__link,
   story,
   story__title,
   story_fill,
   grid,
   offer,
   offer__container,
   offer__header,
   offer__title,
   offer__text,
   offer__img,
   offer__button,
   card,
   props,
   props__title,
   props__container,
   props__number,
   props__copy,
   card__data,
   card__container,
   card__text,
   card__text_subs,
   card__button,
   card__icon,
   text,
   actions,
   actions__icon,
   list,
   list__item,
   list__icon,
   list__link,
   list__button,
   yaRek,
} = scss;

function Profile() {
   const [loan, setLoan] = useState({
      status: 0,
      waiting_time: new Date().getTime() + 24 * 60 * 60 * 1000,
      denied_to: new Date().getTime() + 15 * 24 * 60 * 60 * 1000,
      current: {
         total: 8000.0,
         rate: 1,
         balance: 6400.0,
      },
      info: {
         total: 800.0,
         date: new Date().getTime() + 24 * 60 * 60 * 1000,
      },
      services: [],
      story: [],
   });
   const [ready, setReady] = useState(false);
   const [isActivePopUp, setIsActivePopUp] = useState(false);

   const onStart = async () => {
      const auth = checkAuth();
      if (auth === false) {
         alert();
         logOut();
         return;
      }
      const response = await new api("profile", auth).send();
      if (response.status === "success") {
         const { data } = response;
         setLoan(data);
         setReady(true);
      } else {
         logOut();
      }
   };

   const onOpenPopUp = () => {
      document.body.style.overflowY = "hidden";
      setIsActivePopUp(!isActivePopUp);
   };

   const onClosePopUp = () => {
      document.body.style.overflowY = null;
      setIsActivePopUp(!isActivePopUp);
   };

   useEffect(() => {
      onStart();
   }, []);

   useEffect(() => {
      if (ready) {
         window.yaContextCb.push(()=>{
            const delay = setTimeout(() => {
               clearTimeout(delay);

               // eslint-disable-next-line
               Ya.Context.AdvManager.render({
                  "blockId": "R-A-2643553-1",
                  "renderTo": "yandex_rtb_R-A-2643553-1"
               });
         }, 100);
      });
      }
   }, [ready]);

   return ready ? (
      <>
         <section className={cards}>
            <PACardWrapper className={cards__current}>
               {
                  loan.status !== 2 ? (
                     <>
                        <h3 className={cards__title}>Текущий заём</h3>
                        {CurrentCard(loan.status, loan)}
                     </>
                  ) : (
                     <>
                        <h3 className={cards__title}>Заём одобрен</h3>
                        <span className={cards__text}>
                           Вам одобрен заём! Осталось лишь подписать договор и получить деньги на карту.
                        </span>
                        <Button
                           href={"/sign"}
                           variant="white"
                        >Подписать договор</Button>
                     </>
                  )
               }
            </PACardWrapper>
            {NotifyCard(loan.status, loan)}
         </section>
         <div className={yaRek} id="yandex_rtb_R-A-2643553-1" />
         {loan.virtual_card.status === "created" ? (
            <section className={offer}>
               <article className={offer__container}>
                  <div className={offer__header}>
                     <h3 className={offer__title}>Ваша виртуальная карта</h3>
                     <p className={offer__text}>
                        Мы сделали виртуальную карту
                        <br />
                        специально для вас!
                     </p>
                  </div>
                  <Button type="button" href={"/virtual-card"} className={offer__button}>
                     Подробнее о карте
                  </Button>
                  <Picture
                     className={offer__img}
                     ftype="png"
                     fileName="offerGirlFull"
                     secondImges={["offerGirlTablet", "lemon"]}
                     alt={"Новая услуга. Виртуальная карта с кредитным лимитом."}
                  />
               </article>
            </section>
         ) : null}
         <section className={grid}>
            {loan.virtual_card.status === "active" ? (
               <div className={card}>
                  <article className={props}>
                     <h3 className={props__title}>
                        {loan.virtual_card.card_limit.toLocaleString("ru-RU")} ₽
                     </h3>
                     <div className={props__container}>
                        <span className={props__number}>
                           {
                              loan.virtual_card.number
                                 .toString()
                                 .split(/(\d{4})/)
                                 .filter((item) => item !== "")
                                 .join(" ")
                           }
                        </span>
                        <IconCopy
                           className={props__copy}
                           onClick={() => {
                              navigator.clipboard.writeText(loan.virtual_card.number);
                           }}
                        />
                     </div>
                     <div className={card__data}>
                        <div className={card__container}>
                           <span className={card__text}>Действует до:</span>
                           <span className={card__text + " " + card__text_subs}>
                              {(loan.virtual_card.month < 10
                                 ? "0" + loan.virtual_card.month
                                 : loan.virtual_card.month) +
                                 "/" +
                                 loan.virtual_card.year}
                           </span>
                        </div>
                        <div className={card__container}>
                           <span className={card__text}>CVC:</span>
                           <span className={card__text + " " + card__text_subs}>
                              {loan.virtual_card.cvc}
                           </span>
                        </div>
                        <IconMir className={card__icon} />
                     </div>
                  </article>
                  <div className={actions}>
                     <a
                        className={card__button}
                        href={loan.virtual_card.document}
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        <Document className={actions__icon} />
                        <span className={text}>Договор</span>
                     </a>
                     <button className={card__button} type="button" onClick={onOpenPopUp}>
                        <IconPersonFeedback className={actions__icon} />
                        <span className={text}>Поддержка</span>
                     </button>
                  </div>
               </div>
            ) : null}
            <div className={services}>
               <div className={services__header}>
                  <h2 className={services__title}>Все услуги</h2>
                  <Link className={services__link} to={"/dopolnitelnie-uslugi"}>
                     Смотреть все
                  </Link>
               </div>
               <ServiceList data={loan.services} />
            </div>
            <div
               className={
                  loan.virtual_card.status === "active" ? story + " " + story_fill : story
               }
            >
               <h2 className={story__title}>Иcтория займов</h2>
               {loan.story.length === 0 ? (
                  <EmptyData
                     title="Вы ещё не оформили ни одного займа."
                     text="После оформления займа, тут будет отображаться список всех Ваших займов, включая и те, по которым<br />Вы получили отказ."
                     buttonText="Оформить заём"
                     buttonLink="/application"
                     showButton={loan.status === 0}
                  />
               ) : (
                  <LoanHistoryList data={loan.story} />
               )}
            </div>
         </section>
         {isActivePopUp ? (
            <PopUp title={"Служба поддержки"} onCloase={onClosePopUp}>
               <ul className={list}>
                  {
                     loan.virtual_card.support.whatsapp ? (
                        <li className={list__item}>
                           <a
                              className={list__link}
                              href={"https://wa.me/+7" + loan.virtual_card.support.whatsapp}
                              target="_blank"
                              rel="noopener noreferrer"
                           >
                              <WhatsApp className={list__icon} />
                              Открыть чат в WhatsApp
                           </a>
                        </li>
                     ) : ""
                  }
                  <li className={list__item}>
                     <a
                        className={list__link}
                        href={"tel:+7" + loan.virtual_card.support.phone}
                     >
                        <Phone className={list__icon} />
                        {formatPhone(
                           parseInt(loan.virtual_card.support.phone)
                        )}
                     </a>
                  </li>
                  <li className={list__item}>
                     <a
                        className={list__link}
                        href={"mailto:" + loan.virtual_card.support.mail}
                     >
                        <Email className={list__icon} />
                        {loan.virtual_card.support.mail}
                     </a>
                  </li>
                  <li className={list__item}>
                     <Button
                        variant={"gray"}
                        className={list__button}
                        type="button"
                        onClick={onClosePopUp}
                     >
                        Закрыть
                     </Button>
                  </li>
               </ul>
            </PopUp>
         ) : null}
      </>
   ) : (
      <div
         style={{
            minHeight: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
         }}
      >
         <ReactLoading type="spin" color="#2D9614" />
      </div>
   );
}

export default Profile;
