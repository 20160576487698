import React from "react";

export default function IconHands(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" fill="none" viewBox="0 0 130 130" {...props}>
         <g filter="url(#filter0_i_917_4170)">
            <path
               fill="url(#paint0_linear_917_4170)"
               d="M27.682 26.284c1.836-.906 6.662-2.803 14.54-2.08 13.126 1.902 23.188 3.42 26.658 3.982l-8.438 5.086a30.84 30.84 0 015.05 6.662l1.564-1.795 44.106 38.573a9.049 9.049 0 012.279 10.552A9.055 9.055 0 01104 92.499a9.786 9.786 0 01-6.392 13.957 9.789 9.789 0 01-5.783-.348 9.788 9.788 0 01-9.984 9.48 9.788 9.788 0 01-4.836-1.391 9.961 9.961 0 01-16.327 5.61l-35.93-31.322a9.984 9.984 0 01-3.412-8.15 30.736 30.736 0 016.342-54.05h.004z"
            ></path>
            <path
               fill="url(#paint1_radial_917_4170)"
               d="M27.682 26.284c1.836-.906 6.662-2.803 14.54-2.08 13.126 1.902 23.188 3.42 26.658 3.982l-8.438 5.086a30.84 30.84 0 015.05 6.662l1.564-1.795 44.106 38.573a9.049 9.049 0 012.279 10.552A9.055 9.055 0 01104 92.499a9.786 9.786 0 01-6.392 13.957 9.789 9.789 0 01-5.783-.348 9.788 9.788 0 01-9.984 9.48 9.788 9.788 0 01-4.836-1.391 9.961 9.961 0 01-16.327 5.61l-35.93-31.322a9.984 9.984 0 01-3.412-8.15 30.736 30.736 0 016.342-54.05h.004z"
            ></path>
         </g>
         <path
            fill="url(#paint2_radial_917_4170)"
            d="M27.682 26.284c1.836-.906 6.662-2.803 14.54-2.08 13.126 1.902 23.188 3.42 26.658 3.982l-8.438 5.086a30.84 30.84 0 015.05 6.662l1.564-1.795 44.106 38.573a9.049 9.049 0 012.279 10.552A9.055 9.055 0 01104 92.499a9.786 9.786 0 01-6.392 13.957 9.789 9.789 0 01-5.783-.348 9.788 9.788 0 01-9.984 9.48 9.788 9.788 0 01-4.836-1.391 9.961 9.961 0 01-16.327 5.61l-35.93-31.322a9.984 9.984 0 01-3.412-8.15 30.736 30.736 0 016.342-54.05h.004z"
         ></path>
         <path
            fill="url(#paint3_radial_917_4170)"
            d="M27.682 26.284c1.836-.906 6.662-2.803 14.54-2.08 13.126 1.902 23.188 3.42 26.658 3.982l-8.438 5.086a30.84 30.84 0 015.05 6.662l1.564-1.795 44.106 38.573a9.049 9.049 0 012.279 10.552A9.055 9.055 0 01104 92.499a9.786 9.786 0 01-6.392 13.957 9.789 9.789 0 01-5.783-.348 9.788 9.788 0 01-9.984 9.48 9.788 9.788 0 01-4.836-1.391 9.961 9.961 0 01-16.327 5.61l-35.93-31.322a9.984 9.984 0 01-3.412-8.15 30.736 30.736 0 016.342-54.05h.004z"
         ></path>
         <path
            fill="url(#paint4_radial_917_4170)"
            d="M27.682 26.284c1.836-.906 6.662-2.803 14.54-2.08 13.126 1.902 23.188 3.42 26.658 3.982l-8.438 5.086a30.84 30.84 0 015.05 6.662l1.564-1.795 44.106 38.573a9.049 9.049 0 012.279 10.552A9.055 9.055 0 01104 92.499a9.786 9.786 0 01-6.392 13.957 9.789 9.789 0 01-5.783-.348 9.788 9.788 0 01-9.984 9.48 9.788 9.788 0 01-4.836-1.391 9.961 9.961 0 01-16.327 5.61l-35.93-31.322a9.984 9.984 0 01-3.412-8.15 30.736 30.736 0 016.342-54.05h.004z"
         ></path>
         <path
            fill="url(#paint5_radial_917_4170)"
            d="M27.682 26.284c1.836-.906 6.662-2.803 14.54-2.08 13.126 1.902 23.188 3.42 26.658 3.982l-8.438 5.086a30.84 30.84 0 015.05 6.662l1.564-1.795 44.106 38.573a9.049 9.049 0 012.279 10.552A9.055 9.055 0 01104 92.499a9.786 9.786 0 01-6.392 13.957 9.789 9.789 0 01-5.783-.348 9.788 9.788 0 01-9.984 9.48 9.788 9.788 0 01-4.836-1.391 9.961 9.961 0 01-16.327 5.61l-35.93-31.322a9.984 9.984 0 01-3.412-8.15 30.736 30.736 0 016.342-54.05h.004z"
         ></path>
         <path
            fill="url(#paint6_radial_917_4170)"
            d="M27.682 26.284c1.836-.906 6.662-2.803 14.54-2.08 13.126 1.902 23.188 3.42 26.658 3.982l-8.438 5.086a30.84 30.84 0 015.05 6.662l1.564-1.795 44.106 38.573a9.049 9.049 0 012.279 10.552A9.055 9.055 0 01104 92.499a9.786 9.786 0 01-6.392 13.957 9.789 9.789 0 01-5.783-.348 9.788 9.788 0 01-9.984 9.48 9.788 9.788 0 01-4.836-1.391 9.961 9.961 0 01-16.327 5.61l-35.93-31.322a9.984 9.984 0 01-3.412-8.15 30.736 30.736 0 016.342-54.05h.004z"
         ></path>
         <g filter="url(#filter1_f_917_4170)">
            <path
               fill="#FFB21A"
               fillRule="evenodd"
               d="M19.02 74.149a8.056 8.056 0 00-.454 10.802 8.45 8.45 0 007.995 2.957 7.877 7.877 0 001.523 9.02c2.243 2.233 5.594 3.042 8.434 2.132-.488 2.421.662 5.171 2.628 7.142 1.942 1.938 4.99 2.429 7.41 1.657-.304 2.202.354 4.591 1.812 6.342 2.722 3.274 8.016 4.091 11.375 1.284l5.456-5.635c2.779-2.726 2.56-7.971 0-10.904-1.734-1.995-4.2-2.876-6.54-2.539 1.58-2.758.954-6.451-1.016-8.706-1.99-2.275-4.704-3.156-7.11-2.64a8.27 8.27 0 00-1.77-9.02c-2.548-2.498-6.452-2.867-9.466-1.218a8.134 8.134 0 00-13.772-7.284l-6.5 6.61h-.004z"
               clipRule="evenodd"
            ></path>
         </g>
         <g filter="url(#filter2_f_917_4170)">
            <path
               stroke="url(#paint7_linear_917_4170)"
               strokeLinecap="round"
               strokeWidth="6.094"
               d="M61.112 105.02l9.8 8.758"
            ></path>
         </g>
         <g filter="url(#filter3_f_917_4170)">
            <path
               fill="#D77A15"
               d="M53.658 32.975a80.768 80.768 0 00-7.126 2.356l.008.02a7.304 7.304 0 00-4.225 6.582c0 4.046 3.36 7.32 7.5 7.32 1.007 0 1.97-.194 2.847-.548.711-.22 1.463-.459 2.247-.707h.012l2.096-.674c14.211-4.575 7.15-6.183-3.36-14.349z"
            ></path>
         </g>
         <g filter="url(#filter4_f_917_4170)">
            <path
               stroke="url(#paint8_linear_917_4170)"
               strokeLinecap="round"
               strokeWidth="6.094"
               d="M73.612 50.456l34.784 30.977"
            ></path>
         </g>
         <g filter="url(#filter5_f_917_4170)">
            <path
               stroke="url(#paint9_linear_917_4170)"
               strokeLinecap="round"
               strokeWidth="6.094"
               d="M89.984 87.6l9.803 8.758"
            ></path>
         </g>
         <g filter="url(#filter6_f_917_4170)">
            <path
               stroke="url(#paint10_linear_917_4170)"
               strokeLinecap="round"
               strokeWidth="6.094"
               d="M76.77 98.036l9.798 8.763"
            ></path>
         </g>
         <path
            fill="url(#paint11_linear_917_4170)"
            d="M13.841 86.365a8.93 8.93 0 01.504-11.977l7.21-7.324A9.018 9.018 0 1134.3 79.828l-7.317 7.58c-3.73 3.657-9.803 2.97-13.142-1.043z"
         ></path>
         <path
            fill="url(#paint12_linear_917_4170)"
            d="M13.841 86.365a8.93 8.93 0 01.504-11.977l7.21-7.324A9.018 9.018 0 1134.3 79.828l-7.317 7.58c-3.73 3.657-9.803 2.97-13.142-1.043z"
         ></path>
         <path
            fill="url(#paint13_radial_917_4170)"
            d="M13.841 86.365a8.93 8.93 0 01.504-11.977l7.21-7.324A9.018 9.018 0 1134.3 79.828l-7.317 7.58c-3.73 3.657-9.803 2.97-13.142-1.043z"
         ></path>
         <path
            fill="url(#paint14_radial_917_4170)"
            d="M13.841 86.365a8.93 8.93 0 01.504-11.977l7.21-7.324A9.018 9.018 0 1134.3 79.828l-7.317 7.58c-3.73 3.657-9.803 2.97-13.142-1.043z"
         ></path>
         <path
            fill="url(#paint15_linear_917_4170)"
            d="M24.395 99.653a8.735 8.735 0 01-.073-12.297l10.433-10.644c3.396-3.465 9.104-3.62 12.57-.223 3.473 3.412 3.582 9.258.15 12.715L36.968 100.04c-3.404 3.425-9.157 3.014-12.578-.394l.005.008z"
         ></path>
         <path
            fill="url(#paint16_linear_917_4170)"
            d="M24.395 99.653a8.735 8.735 0 01-.073-12.297l10.433-10.644c3.396-3.465 9.104-3.62 12.57-.223 3.473 3.412 3.582 9.258.15 12.715L36.968 100.04c-3.404 3.425-9.157 3.014-12.578-.394l.005.008z"
         ></path>
         <path
            fill="url(#paint17_radial_917_4170)"
            d="M24.395 99.653a8.735 8.735 0 01-.073-12.297l10.433-10.644c3.396-3.465 9.104-3.62 12.57-.223 3.473 3.412 3.582 9.258.15 12.715L36.968 100.04c-3.404 3.425-9.157 3.014-12.578-.394l.005.008z"
         ></path>
         <path
            fill="url(#paint18_radial_917_4170)"
            d="M24.395 99.653a8.735 8.735 0 01-.073-12.297l10.433-10.644c3.396-3.465 9.104-3.62 12.57-.223 3.473 3.412 3.582 9.258.15 12.715L36.968 100.04c-3.404 3.425-9.157 3.014-12.578-.394l.005.008z"
         ></path>
         <path
            fill="url(#paint19_linear_917_4170)"
            d="M46.881 118.8c-2.652-3.19-2.908-8.28 0-11.237l6.98-6.216c3.331-3.384 8.572-3.047 11.688.536 2.835 3.25 3.079 9.068 0 12.09l-6.045 6.257c-3.73 3.107-9.6 2.201-12.623-1.43z"
         ></path>
         <path
            fill="url(#paint20_linear_917_4170)"
            d="M46.881 118.8c-2.652-3.19-2.908-8.28 0-11.237l6.98-6.216c3.331-3.384 8.572-3.047 11.688.536 2.835 3.25 3.079 9.068 0 12.09l-6.045 6.257c-3.73 3.107-9.6 2.201-12.623-1.43z"
         ></path>
         <path
            fill="url(#paint21_radial_917_4170)"
            d="M46.881 118.8c-2.652-3.19-2.908-8.28 0-11.237l6.98-6.216c3.331-3.384 8.572-3.047 11.688.536 2.835 3.25 3.079 9.068 0 12.09l-6.045 6.257c-3.73 3.107-9.6 2.201-12.623-1.43z"
         ></path>
         <path
            fill="url(#paint22_radial_917_4170)"
            d="M46.881 118.8c-2.652-3.19-2.908-8.28 0-11.237l6.98-6.216c3.331-3.384 8.572-3.047 11.688.536 2.835 3.25 3.079 9.068 0 12.09l-6.045 6.257c-3.73 3.107-9.6 2.201-12.623-1.43z"
         ></path>
         <path
            fill="url(#paint23_linear_917_4170)"
            d="M36.66 109.931c-3.075-3.075-4.347-7.873-1.275-10.944l10.387-10.579c3.076-3.071 8.117-2.746 11.4 1.008 2.56 2.929 3.071 8.056 0 11.131l-9.385 9.384c-2.486 2.86-8.056 3.072-11.13 0h.003z"
         ></path>
         <path
            fill="url(#paint24_linear_917_4170)"
            d="M36.66 109.931c-3.075-3.075-4.347-7.873-1.275-10.944l10.387-10.579c3.076-3.071 8.117-2.746 11.4 1.008 2.56 2.929 3.071 8.056 0 11.131l-9.385 9.384c-2.486 2.86-8.056 3.072-11.13 0h.003z"
         ></path>
         <path
            fill="url(#paint25_radial_917_4170)"
            d="M36.66 109.931c-3.075-3.075-4.347-7.873-1.275-10.944l10.387-10.579c3.076-3.071 8.117-2.746 11.4 1.008 2.56 2.929 3.071 8.056 0 11.131l-9.385 9.384c-2.486 2.86-8.056 3.072-11.13 0h.003z"
         ></path>
         <path
            fill="url(#paint26_radial_917_4170)"
            d="M36.66 109.931c-3.075-3.075-4.347-7.873-1.275-10.944l10.387-10.579c3.076-3.071 8.117-2.746 11.4 1.008 2.56 2.929 3.071 8.056 0 11.131l-9.385 9.384c-2.486 2.86-8.056 3.072-11.13 0h.003z"
         ></path>
         <g filter="url(#filter7_i_917_4170)">
            <path
               fill="#FBB439"
               d="M49.266 32.646l.012.024a8.511 8.511 0 006.947 15.535l2.551-.824.013-.004c3.875-1.256 9.583-3.234 12.695-3.852.967-.195 1.918.163 2.66.813l36.941 32.342c6.687-5.875 11.152-13.874 11.152-23.149 0-16.185-12.813-29.302-29.53-29.302-11.465.568-29.669 2.961-43.44 8.417z"
            ></path>
            <path
               fill="url(#paint27_radial_917_4170)"
               d="M49.266 32.646l.012.024a8.511 8.511 0 006.947 15.535l2.551-.824.013-.004c3.875-1.256 9.583-3.234 12.695-3.852.967-.195 1.918.163 2.66.813l36.941 32.342c6.687-5.875 11.152-13.874 11.152-23.149 0-16.185-12.813-29.302-29.53-29.302-11.465.568-29.669 2.961-43.44 8.417z"
            ></path>
            <path
               fill="url(#paint28_radial_917_4170)"
               d="M49.266 32.646l.012.024a8.511 8.511 0 006.947 15.535l2.551-.824.013-.004c3.875-1.256 9.583-3.234 12.695-3.852.967-.195 1.918.163 2.66.813l36.941 32.342c6.687-5.875 11.152-13.874 11.152-23.149 0-16.185-12.813-29.302-29.53-29.302-11.465.568-29.669 2.961-43.44 8.417z"
            ></path>
            <path
               fill="url(#paint29_radial_917_4170)"
               d="M49.266 32.646l.012.024a8.511 8.511 0 006.947 15.535l2.551-.824.013-.004c3.875-1.256 9.583-3.234 12.695-3.852.967-.195 1.918.163 2.66.813l36.941 32.342c6.687-5.875 11.152-13.874 11.152-23.149 0-16.185-12.813-29.302-29.53-29.302-11.465.568-29.669 2.961-43.44 8.417z"
            ></path>
         </g>
         <path
            fill="url(#paint30_radial_917_4170)"
            d="M49.266 32.646l.012.024a8.511 8.511 0 006.947 15.535l2.551-.824.013-.004c3.875-1.256 9.583-3.234 12.695-3.852.967-.195 1.918.163 2.66.813l36.941 32.342c6.687-5.875 11.152-13.874 11.152-23.149 0-16.185-12.813-29.302-29.53-29.302-11.465.568-29.669 2.961-43.44 8.417z"
         ></path>
         <g filter="url(#filter8_f_917_4170)">
            <path
               fill="#FFE746"
               d="M76.115 38.626c-5.301 1.3-11.757 3.275-16.473 4.827a5.183 5.183 0 01-6.606-3.441c-.707-2.45.536-5.046 2.945-5.89 6.053-2.113 13.43-4.027 18.712-5.103 2.531-.516 4.895 1.21 5.35 3.753.476 2.661-1.304 5.212-3.928 5.85v.004z"
            ></path>
         </g>
         <defs>
            <filter
               id="filter0_i_917_4170"
               width="112.377"
               height="104.312"
               x="7.967"
               y="17.95"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="6.094" dy="-6.094"></feOffset>
               <feGaussianBlur stdDeviation="6.094"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.945098 0 0 0 0 0.615686 0 0 0 0 0.156863 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_917_4170"></feBlend>
            </filter>
            <filter
               id="filter1_f_917_4170"
               width="56.591"
               height="58.169"
               x="13.658"
               y="62.068"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4170" stdDeviation="1.523"></feGaussianBlur>
            </filter>
            <filter
               id="filter2_f_917_4170"
               width="28.08"
               height="27.04"
               x="51.972"
               y="95.879"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4170" stdDeviation="3.047"></feGaussianBlur>
            </filter>
            <filter
               id="filter3_f_917_4170"
               width="29.673"
               height="23.266"
               x="38.821"
               y="29.481"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4170" stdDeviation="1.747"></feGaussianBlur>
            </filter>
            <filter
               id="filter4_f_917_4170"
               width="53.065"
               height="49.258"
               x="64.472"
               y="41.315"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4170" stdDeviation="3.047"></feGaussianBlur>
            </filter>
            <filter
               id="filter5_f_917_4170"
               width="28.084"
               height="27.04"
               x="80.844"
               y="78.459"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4170" stdDeviation="3.047"></feGaussianBlur>
            </filter>
            <filter
               id="filter6_f_917_4170"
               width="28.08"
               height="27.044"
               x="67.629"
               y="88.895"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4170" stdDeviation="3.047"></feGaussianBlur>
            </filter>
            <filter
               id="filter7_i_917_4170"
               width="77.82"
               height="53.06"
               x="44.417"
               y="24.229"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="0.609"></feOffset>
               <feGaussianBlur stdDeviation="2.031"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.917647 0 0 0 0 0.615686 0 0 0 0 0.137255 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_917_4170"></feBlend>
            </filter>
            <filter
               id="filter8_f_917_4170"
               width="43.532"
               height="31.041"
               x="44.714"
               y="20.802"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4170" stdDeviation="4.063"></feGaussianBlur>
            </filter>
            <linearGradient
               id="paint0_linear_917_4170"
               x1="91.764"
               x2="64.598"
               y1="57.005"
               y2="96.866"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFD741"></stop>
               <stop offset="1" stopColor="#FFBE37"></stop>
            </linearGradient>
            <radialGradient
               id="paint1_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(122.645 28.917 43.447) scale(19.2985 26.8349)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFDC38"></stop>
               <stop offset="1" stopColor="#FFDC38" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint2_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-136.375 73.264 27.607) scale(11.2244 8.95711)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFCB41"></stop>
               <stop offset="1" stopColor="#FFCB41" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint3_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-138.778 72.507 25.988) scale(19.0726 5.21022)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F48E5A"></stop>
               <stop offset="1" stopColor="#F48E5A" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint4_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-17.77339 -18.28124 4.99408 -4.85534 99.381 109.817)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F48E5A"></stop>
               <stop offset="1" stopColor="#F48E5A" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint5_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-133.968 65.72 41.32) scale(19.9321 6.32013)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F48E5A"></stop>
               <stop offset="1" stopColor="#F48E5A" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint6_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-17.26574 -14.8535 4.35384 -5.06092 67.77 125.304)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F48E5A"></stop>
               <stop offset="1" stopColor="#F48E5A" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint7_linear_917_4170"
               x1="70.911"
               x2="56.164"
               y1="113.778"
               y2="99.308"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFCD43"></stop>
               <stop offset="1" stopColor="#FFCD43" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint8_linear_917_4170"
               x1="108.396"
               x2="71.577"
               y1="81.433"
               y2="49.44"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFE647"></stop>
               <stop offset="1" stopColor="#FFE647" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint9_linear_917_4170"
               x1="99.787"
               x2="85.036"
               y1="96.358"
               y2="81.888"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFD941"></stop>
               <stop offset="1" stopColor="#FFD941" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint10_linear_917_4170"
               x1="86.568"
               x2="71.817"
               y1="106.799"
               y2="92.324"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFD63F"></stop>
               <stop offset="1" stopColor="#FFD63F" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint11_linear_917_4170"
               x1="31.208"
               x2="13.561"
               y1="69.7"
               y2="86.71"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFBC41"></stop>
               <stop offset="1" stopColor="#E69429"></stop>
            </linearGradient>
            <linearGradient
               id="paint12_linear_917_4170"
               x1="29.62"
               x2="25.687"
               y1="86.015"
               y2="81.823"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E08407"></stop>
               <stop offset="1" stopColor="#E08407" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint13_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-88.877 57.973 35.41) scale(12.9517 11.712)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#DA725B"></stop>
               <stop offset="1" stopColor="#DA725B" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint14_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-7.55364 7.23634 -6.99844 -7.3053 34.569 66.779)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFE43E"></stop>
               <stop offset="1" stopColor="#FFE43E" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint15_linear_917_4170"
               x1="43.522"
               x2="23.819"
               y1="79.97"
               y2="98.966"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFBC41"></stop>
               <stop offset="1" stopColor="#E69429"></stop>
            </linearGradient>
            <linearGradient
               id="paint16_linear_917_4170"
               x1="41.751"
               x2="37.355"
               y1="98.191"
               y2="93.511"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E08407"></stop>
               <stop offset="1" stopColor="#E08407" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint17_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(2.03125 -16.77184 19.81997 2.40041 30.7 104.991)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E8766E"></stop>
               <stop offset="1" stopColor="#E8766E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint18_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(136.026 7.231 48.25) scale(9.85156 9.5277)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFE43E"></stop>
               <stop offset="1" stopColor="#FFE43E" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint19_linear_917_4170"
               x1="62.494"
               x2="46.443"
               y1="103.837"
               y2="119.328"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFBC41"></stop>
               <stop offset="1" stopColor="#E69429"></stop>
            </linearGradient>
            <linearGradient
               id="paint20_linear_917_4170"
               x1="48.982"
               x2="54.69"
               y1="106.137"
               y2="109.825"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.156" stopColor="#E08407"></stop>
               <stop offset="1" stopColor="#E08407" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint21_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(1.52344 -14.75104 17.45814 1.80301 51.903 125.304)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E8766E"></stop>
               <stop offset="1" stopColor="#E8766E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint22_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(136.071 11.623 64.095) scale(8.03029 7.76585)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFE43E"></stop>
               <stop offset="1" stopColor="#FFE43E" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint23_linear_917_4170"
               x1="53.394"
               x2="35.466"
               y1="91.739"
               y2="109.05"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFBC41"></stop>
               <stop offset="1" stopColor="#E69429"></stop>
            </linearGradient>
            <linearGradient
               id="paint24_linear_917_4170"
               x1="52.918"
               x2="49.177"
               y1="109.31"
               y2="105.304"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E08407"></stop>
               <stop offset="1" stopColor="#E08407" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint25_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(1.8511 -15.25641 18.06174 2.19147 41.711 114.499)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E8766E"></stop>
               <stop offset="1" stopColor="#E8766E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint26_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(136.08 9.669 56.16) scale(8.97004 8.67457)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFE43E"></stop>
               <stop offset="1" stopColor="#FFE43E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint27_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(40.99677 -27.33121 42.9559 64.43378 77.91 69.444)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.361" stopColor="#FFE949" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#FFE949"></stop>
            </radialGradient>
            <radialGradient
               id="paint28_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-21.403 137.628 -95.893) scale(17.5538 28.1876)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#EE9226"></stop>
               <stop offset="1" stopColor="#EE9226" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint29_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(27.33136 -21.77931 20.78413 26.08248 75.985 70.3)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F7A91E"></stop>
               <stop offset="1" stopColor="#F7A91E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint30_radial_917_4170"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-35.932 138.299 -95.2) scale(46.9389 73.4402)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.661" stopColor="#FFEA49" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#FFEA49"></stop>
            </radialGradient>
         </defs>
      </svg>
   );
}
