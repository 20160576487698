import React from "react";

export default function IconScan(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="none" viewBox="0 0 64 64" {...props}>
         <path
            fill="url(#a)"
            d="M40 8h16v13.33h-5.33v-8H40V8zM24 8v5.33H13.33v8H8V8h16zm16 48v-5.33h10.67v-8H56V56H40zm-16 0H8V42.67h5.33v8H24V56zM8 29.33h48v5.34H8v-5.34z"
         ></path>
         <defs>
            <linearGradient id="a" x1="8" x2="56" y1="56" y2="8" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614"></stop>
               <stop offset="1" stopColor="#BAE860"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}
