import React from "react";

export default function IconCharacterNumber(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="none" viewBox="0 0 64 64" {...props}>
         <path
            fill="url(#a)"
            d="M55 18H43v4h12v8h-8v4h8v8H43v4h12a4 4 0 004-4V22a4 4 0 00-4-4zM39 46H23V34a4 4 0 014-4h8v-8H23v-4h12a4 4 0 014 4v8a4 4 0 01-4 4h-8v8h12v4zM4 45v-2h6V21H4v-2h8v24h6v2H4z"
         ></path>
         <path fill="url(#b)" d="M12 20v24-24zm2-2H4v4h6v20H4v4h16v-4h-6V18z"></path>
         <defs>
            <linearGradient id="a" x1="4" x2="26.64" y1="46" y2="1.53" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614"></stop>
               <stop offset="1" stopColor="#BAE860"></stop>
            </linearGradient>
            <linearGradient id="b" x1="4" x2="28.12" y1="46" y2="32.22" gradientUnits="userSpaceOnUse">
               <stop stopColor="#2D9614"></stop>
               <stop offset="1" stopColor="#BAE860"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}
