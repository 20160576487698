import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BlockWrapper from "../../components/BlockWrapper";
import Button from "../../components/Button";
import Input from "../../components/Input";
import PincodeInput from "../../components/PincodeInput";
import css from "./styles.module.scss";

const {
   signIn,
   signIn__wrapper,
   signIn__title,
   signIn__text,
   signIn__form,
   signIn__form_pin,
   signIn__input,
   signIn__button,
   signIn__link,
   signIn__secondaryText
} = css;


function SignIn() {
   const navigate = useNavigate();

   const setAuth = ({ token, expire_in }) => {
      localStorage.setItem("token", token);
      localStorage.setItem("expireIn", expire_in);
      localStorage.setItem("role", 0);

      navigate(0);
   };

   const [step, setStep] = useState(0);

   const [phone, setPhone] = useState("");
   const [phoneError, setPhoneError] = useState();

   useEffect(() => setPhoneError(), [phone]);

   const sendPhoneNumber = async (e) => {
      e.preventDefault();

      if (phone.length === 10) {
         const body = new FormData();
         body.append("phone", phone);

         const resp = await fetch(global.api + "auth", {
            method: "POST",
            body,
         });

         const json = await resp.json();

         if (json.status === "success") {
            if (!json.password) {
               setStep(3);
            } else if (!json.email) {
               setStep(4);
            }
         } else {
            if (json.message === "Need a password to log in") {
               setStep(1);
            } else {
               setPhoneError("Проверьте указанный телефон");
            }
         }
      }
   };

   const [password, setPassword] = useState("");
   const [passwordError, setPasswordError] = useState(false);

   const sendPassword = async (e) => {
      e.preventDefault();

      if (password.length === 5) {
         const body = new FormData();

         body.append("phone", phone);
         body.append("password", password);

         const resp = await fetch(global.api + "auth", {
            method: "POST",
            body,
         });

         const json = await resp.json();

         if (json.status === "success") {
            setAuth(json.data);
         } else if (json.message === "User blocked") {
            localStorage.setItem("resultPage", JSON.stringify({
               title: "Личный кабинет заблокирован",
               text: json.data?.reason ? "Ваш личный кабинет был заблокирован<br />по следующей причине:<br />"+json.data.reason+"<br /><br />Для снятия блокировки обратитесь по номеру <b style=\"white-space: nowrap;\">+7 (812) 408-25-25</b>." : "Для снятия блокировки обратитесь по номеру <b style=\"white-space: nowrap;\">+7 (812) 408-25-25</b>.",
               buttonText: "Вернуться на главную",
               buttonLink: "/",
            }));
            navigate("/message");
         } else {
            setPasswordError(true);

            const timer = setTimeout(() => {
               setPasswordError(false);
               setPassword("");
               clearTimeout(timer);
            }, 1000);
         }
      }
   };

   const sendResetRequest = async () => {
      const body = new FormData();
      body.append("phone", phone);

      const resp = await fetch(global.api + "reset_password", {
         method: "POST",
         body,
      });

      const json = await resp.json();

      if (json.status === "success") {
         setStep(2);
      }
   };

   const goToEmail = (e) => {
      e.preventDefault();

      if (password.length === 5) {
         setStep(4);
      }
   };

   const [email, setEmail] = useState("");
   const [emailError, setEmailError] = useState();

   const checkEmail = (em) => em.match(/^(\w|-|\.|_){2,}@(\w|-|\.|_){2,}\.\w{2,}$/);

   const sendPasswordEmail = async (e) => {
      e.preventDefault();

      if (password.length === 5 && checkEmail(email)) {
         const body = new FormData();

         body.append("phone", phone);
         body.append("password", password);
         body.append("email", email);

         const resp = await fetch(global.api + "auth", {
            method: "POST",
            body,
         });

         const json = await resp.json();

         if (json.status === "success") {
            setAuth(json.data);
            setEmailError();
         } else {
            if (
               json.message === "Need a email to log in" ||
               json.message === "The email parameter is invalid"
            ) {
               setEmailError("Адрес эл. почты указан некорректно.");
            }
            else if (json.message === "User with this email already exist") {
               setEmailError("Кто-то уже использует этот адрес эл. почты.");
            }
         }
      }
   };

   return (
      <div className={signIn}>
         {step === 0 || (step !== 1 && step !== 2 && step !== 3 && step !== 4) ? (
            <>
               <BlockWrapper className={signIn__wrapper}>
                  <h2 className={signIn__title}>Вход в личный кабинет</h2>
                  <p className={signIn__text}>Введите номер телефона для входа</p>
                  <form className={signIn__form} onSubmit={sendPhoneNumber} action="">
                     <Input
                        className={signIn__input}
                        placeholder="Номер телефона"
                        mask={"+7 (XXX) XXX-XX-XX"}
                        name="phone"
                        regex={/[0-9]/}
                        value={phone}
                        onChange={setPhone}
                        error={phoneError}
                     />
                     <Button className={signIn__button} onClick={sendPhoneNumber} disabled={phone.length !== 10}>
                        Далее
                     </Button>
                  </form>
               </BlockWrapper>

               <p className={signIn__secondaryText}>
                  Указывая номер телефона, вы соглашаетесь на обработку <Link to="/">персональных данных</Link>.
               </p>
               <p className={signIn__secondaryText}>
                  Обращаем ваше внимание, что указанный вами номер телефона является верификационным, и на него будут
                  отправляться подтверждающие СМС. Также он является логином в личном кабинет.
               </p>
            </>
         ) : (
            ""
         )}
         {step === 1 ? (
            <BlockWrapper className={signIn__wrapper}>
               <h2 className={signIn__title}>Вход в личный кабинет</h2>
               <p className={signIn__text}>Введите пин-код, установленный при регистрации</p>
               <form className={signIn__form + " " + signIn__form_pin} onSubmit={sendPassword} action="">
                  <PincodeInput
                     length={5}
                     type="password"
                     regexp={/[0-9]/}
                     value={password}
                     onChange={setPassword}
                     invalid={passwordError}
                  />
                  <Button className={signIn__button} onClick={sendPassword} disabled={password.length !== 5}>
                     Войти
                  </Button>
               </form>
               <p className={signIn__link} onClick={sendResetRequest}>
                  Забыли пин-код?
               </p>
            </BlockWrapper>
         ) : (
            ""
         )}
         {step === 2 ? (
            <BlockWrapper className={signIn__wrapper}>
               <h2 className={signIn__title}>Восстановление пин-кода</h2>
               <p className={signIn__text}>
                  Мы отправили письмо со ссылкой для смены пин-кода на адрес электронной почты, который Вы указали при
                  регистрации.
               </p>
               <Button href="/">Вернуться на главную</Button>
            </BlockWrapper>
         ) : (
            ""
         )}
         {step === 3 ? (
            <BlockWrapper className={signIn__wrapper}>
               <h2 className={signIn__title}>Установите пин-код</h2>
               <p className={signIn__text}>Этот пин-код будет использоваться для входа в Ваш личный кабинет</p>
               <form className={signIn__form + " " + signIn__form_pin} onSubmit={goToEmail} action="">
                  <PincodeInput length={5} type="password" regexp={/[0-9]/} value={password} onChange={setPassword} />
                  <Button className={signIn__button} onClick={goToEmail} disabled={password.length !== 5}>
                     Далее
                  </Button>
               </form>
            </BlockWrapper>
         ) : (
            ""
         )}
         {step === 4 ? (
            <BlockWrapper className={signIn__wrapper}>
               <h2 className={signIn__title}>Установите эл. почту</h2>
               <p className={signIn__text}>
                  Эл. почта будет использоваться для восстановления пароля и получения уведомлений
               </p>
               <form className={signIn__form} onSubmit={sendPasswordEmail} action="">
                  <Input
                     className={signIn__input}
                     placeholder="Адрес эл. почты"
                     name="email"
                     type="email"
                     value={email}
                     onChange={setEmail}
                     error={emailError}
                  />
                  <Button className={signIn__button} onClick={sendPasswordEmail} disabled={!checkEmail(email)}>
                     Готово
                  </Button>
               </form>
            </BlockWrapper>
         ) : (
            ""
         )}
      </div>
   );
}

export default SignIn;