import library from "../../components/library";

const PageBuilder = (props = {
    content: [],
    className: "",
    editable: false,
    onChange: () => {},
    onChangeGlobal: () => {},
    onClick: () => {}
}) => {
    return (
        <div className={props.className}>
            {
                props.content.map((block, i) => {
                    const Block = library[block.type] ? library[block.type] : () => "";
                    return <Block
                                key = {i}
                                data = {block.data}
                                blockIndex = {i}
                                editable = {props.editable}
                                onChange = {props.onChange}
                                onChangeGlobal = {props.onChangeGlobal}
                                onClick = {props.onClick}
                            />;
                })
            }
        </div>
    );
}

export default PageBuilder;