import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import formatPhone from "../../../helpers/formatPhone";
import logOut from "../../../helpers/logout";

import css from "./styles.module.scss";

const {
    clientHeaderBlock,
    clientHeaderBlock_white,
    clientHeaderBlock_gray,
    clientHeaderBlock__infoBlock,
    clientHeaderBlock__phone,
    clientHeaderBlock__logOut,
    clientHeaderBlock__avatar,
} = css;

const ClientHeaderBlock = ({className, children, variant, onLogOut, onClick}) => {

    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const token = checkAuth();

    const role = localStorage.getItem("role")

    let tmpClassName = clientHeaderBlock;
    tmpClassName += variant === "white" ? " " + clientHeaderBlock_white : "";
    tmpClassName += variant === "gray" ? " " + clientHeaderBlock_gray : "";
    tmpClassName += className ? " " + className : "";

    const update = async () => {
        if (token) {
            const response = await (
                new api("user", token)
            ).send();
            
            if (response.status === "success") {
                setPhone(formatPhone(response.data.phone_number));
                setEmail(response.data.email);
            }
            else {
                logOut();
            }
        }
    }

    useEffect(() => {
        update();
    }, [])

    return token ? (
        <div className={tmpClassName}>
            <div className={clientHeaderBlock__infoBlock}>
                <Link
                    to={role === "0" ? "/profile" : "/manage"}
                    className={clientHeaderBlock__phone}
                    onClick={onClick}
                >{role === "0" ? phone : email}</Link>
                <p
                    className={clientHeaderBlock__logOut}
                    onClick={() => logOut("/", onLogOut)}
                >Выйти</p>
            </div>
            <Link
                to={role === "0" ? "/profile" : "/manage"}
                onClick={onClick}
            >
                <img
                    src="/assets/base_avatar.jpg"
                    className={clientHeaderBlock__avatar}
                    alt=""
                    width="64"
                    height="64"
                />
            </Link>
        </div>
    ) : children;
}

export default ClientHeaderBlock;