import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import checkAuth from "../../helpers/checkAuth";
import api from "../../helpers/fetch";
import formatPhone from "../../helpers/formatPhone";
import logOut from "../../helpers/logout";
import Button from "../Button";
import Input from "../Input";

import scss from "./index.module.scss";

const {
   telephone,
   telephone__form,
   telephone__description,
   telephone__button,
   telephone__input,
   telephone__subtext,
} = scss;

function ConfirmTelephone({
   number,
   onConfirm = () => {},
   disableInput = false,
   inputError = false,
   VirtualCardGet,
   VirtualCardText,
}) {
   const navigate = useNavigate();

   const [pin, setPin] = useState("");
   const [wait, setWait] = useState(false);
   let [time, setTime] = useState(90);
   const [state, setState] = useState({
      telephone: number,
   });

   const sendSMS = async (e) => {
      e.preventDefault();
      const auth = checkAuth();
      if (!auth) logOut();
      const resp = await new api(
         VirtualCardGet ? VirtualCardGet : "sign/send_code",
         auth
      ).send();
      if (resp.status === "success") {
         startTimer();
      } else {
         if (resp.message === "Code already has sended") {
            const time_diff = 90 - parseInt((new Date().getTime() - resp.date) / 1000);
            startTimer(time_diff);
         } else {
            navigate("/profile");
         }
      }
   };

   useEffect(() => {
      if (pin.length === 6) {
         onConfirm(pin);
      }
   }, [pin]);

   const startTimer = (timeout = 90) => {
      setWait(true);
      setTime(timeout);
      const interval = setInterval(() => {
         if (timeout > 0) {
            setTime(--timeout);
         } else {
            setWait(false);
            setTime(90);
            clearInterval(interval);
         }
      }, 1000);
   };

   const secondsToMinutes = (seconds = 0) => {
      const minutes = Math.floor(seconds / 60);
      seconds = seconds - minutes * 60;
      return (
         minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0")
      );
   };

   return (
      <div className={telephone}>
         <form className={telephone__form} action="" method="post" onSubmit={sendSMS}>
            <span className={telephone__description}>
               SMS c кодом будет отправлено на&nbsp;номер{" "}
               <span style={{ whiteSpace: "nowrap" }}>
                  {formatPhone(state.telephone)}
               </span>
            </span>
            <Button className={telephone__button} type={"submit"} disabled={wait}>
               {wait ? secondsToMinutes(time) : "Получить код"}
            </Button>
            <Input
               className={telephone__input}
               regex={/^[0-9]$/}
               mask={"XXXXXX"}
               placeholder={"Введите код из СМС"}
               value={pin}
               disabled={disableInput}
               error={inputError}
               onChange={setPin}
               type={"text"}
            />
         </form>
         {VirtualCardText ? (
            <span className={telephone__subtext}>{VirtualCardText}</span>
         ) : (
            <span className={telephone__subtext}>
               На Ваш номер будет отправлено SMS-сообщение с кодом. Введите в поле, чтобы
               подписать согласие с Заявление на займ,
               <br />
               График платежей по договору займа, табличной формы индивидуальных условий
               микрозайма №{number} и Договор займа №{number}.
            </span>
         )}
      </div>
   );
}

export default ConfirmTelephone;
