import IconCopy from "../../Icons/Copy";
import IconChip from "../../Icons/IconChip";
import IconMir from "../../Icons/IconMir";
import IconLightLogo from "../../Icons/LogoLight";
import scss from "./index.module.scss";

const {
   card,
   card__section,
   card__logo,
   card__chip,
   information,
   number,
   number__card,
   number__copy,
   holder,
   holder__name,
   holder__mir,
} = scss;

function CrediCard({ numberCard }) {
   const numberString = numberCard.toString().replaceAll(" ", "");
   const tmp = "XXXX XXXX XXXX XXXX XXXX XXXX";
   let formatedNumber = "";
   let i = 0;

   tmp.split("").forEach(char => {
      if (numberString[i]) {
         if (char === "X") {
             formatedNumber += numberString[i];
             i++;
         }
         else {
             formatedNumber += char;
         }
      }
   });

   return (
      <article className={card}>
         <div className={card__section}>
            <IconLightLogo className={card__logo} />
         </div>
         <div className={card__section}>
            <IconChip className={card__chip} />
         </div>
         <div className={card__section}>
            <div className={information}>
               <div className={number}>
                  <span className={number__card}>
                     {formatedNumber}
                  </span>
                  <IconCopy
                     className={number__copy}
                     onClick={() =>
                        navigator.clipboard.writeText(numberCard)
                     }
                  />
               </div>
               <div className={holder}>
                  <span className={holder__name}>Card Holder</span>
                  <IconMir className={holder__mir} />
               </div>
            </div>
         </div>
      </article>
   );
}

export default CrediCard;
