import React from "react";

export default function IconLock(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" fill="none" viewBox="0 0 130 130" {...props}>
         <g filter="url(#filter0_i_917_4300)">
            <path stroke="#C2C2C6" strokeWidth="11.172" d="M35.657 57.797v-22.6a20.377 20.377 0 0140.755 0v22.6"></path>
         </g>
         <path
            stroke="url(#paint0_radial_917_4300)"
            strokeWidth="11.172"
            d="M35.657 57.797v-22.6a20.377 20.377 0 0140.755 0v22.6"
         ></path>
         <g strokeWidth="4.063" filter="url(#filter1_f_917_4300)">
            <path
               stroke="url(#paint1_radial_917_4300)"
               d="M37.245 57.797v-23.4c0-11.651 9.323-21.1 20.82-21.1s20.82 9.449 20.82 21.1v23.4"
            ></path>
            <path
               stroke="url(#paint2_radial_917_4300)"
               d="M37.245 57.797v-23.4c0-11.651 9.323-21.1 20.82-21.1s20.82 9.449 20.82 21.1v23.4"
            ></path>
         </g>
         <g filter="url(#filter2_iiii_917_4300)">
            <path
               fill="url(#paint3_linear_917_4300)"
               d="M88.534 45.544H23.786c-6.731 0-12.188 5.457-12.188 12.188v52.179c0 6.731 5.457 12.187 12.188 12.187h64.748c6.731 0 12.188-5.456 12.188-12.187v-52.18c0-6.73-5.457-12.187-12.188-12.187z"
            ></path>
         </g>
         <path
            fill="url(#paint4_radial_917_4300)"
            d="M88.534 45.544H23.786c-6.731 0-12.188 5.457-12.188 12.188v52.179c0 6.731 5.457 12.187 12.188 12.187h64.748c6.731 0 12.188-5.456 12.188-12.187v-52.18c0-6.73-5.457-12.187-12.188-12.187z"
         ></path>
         <path
            fill="url(#paint5_linear_917_4300)"
            d="M88.534 45.544H23.786c-6.731 0-12.188 5.457-12.188 12.188v52.179c0 6.731 5.457 12.187 12.188 12.187h64.748c6.731 0 12.188-5.456 12.188-12.187v-52.18c0-6.73-5.457-12.187-12.188-12.187z"
         ></path>
         <path
            fill="url(#paint6_radial_917_4300)"
            d="M88.534 45.544H23.786c-6.731 0-12.188 5.457-12.188 12.188v52.179c0 6.731 5.457 12.187 12.188 12.187h64.748c6.731 0 12.188-5.456 12.188-12.187v-52.18c0-6.73-5.457-12.187-12.188-12.187z"
         ></path>
         <g filter="url(#filter3_f_917_4300)">
            <path
               fill="url(#paint7_linear_917_4300)"
               d="M79.178 46.41a18.826 18.826 0 0011.257 20.698V78.65a3.25 3.25 0 00.918 2.258l1.699 1.76a2.909 2.909 0 01.158 3.871l-.813.987a2.747 2.747 0 00-.02 3.466l.792.987a3.022 3.022 0 01-.3 4.107l-1.39 1.288a3.25 3.25 0 00-1.04 2.384v6.22a7.3 7.3 0 0010.128 6.732c.102-.63.155-1.276.155-1.938V58.597c0-6.731-2.641-10.1-9.368-10.1L79.177 46.41z"
            ></path>
            <path
               fill="url(#paint8_radial_917_4300)"
               d="M79.178 46.41a18.826 18.826 0 0011.257 20.698V78.65a3.25 3.25 0 00.918 2.258l1.699 1.76a2.909 2.909 0 01.158 3.871l-.813.987a2.747 2.747 0 00-.02 3.466l.792.987a3.022 3.022 0 01-.3 4.107l-1.39 1.288a3.25 3.25 0 00-1.04 2.384v6.22a7.3 7.3 0 0010.128 6.732c.102-.63.155-1.276.155-1.938V58.597c0-6.731-2.641-10.1-9.368-10.1L79.177 46.41z"
            ></path>
         </g>
         <g strokeWidth="7.109" filter="url(#filter4_f_917_4300)">
            <path
               stroke="url(#paint9_linear_917_4300)"
               d="M61.843 83.476a10.591 10.591 0 10-11.363.07v13.381a5.683 5.683 0 005.682 5.682 5.683 5.683 0 005.681-5.682v-13.45z"
            ></path>
            <path
               stroke="url(#paint10_linear_917_4300)"
               d="M61.843 83.476a10.591 10.591 0 10-11.363.07v13.381a5.683 5.683 0 005.682 5.682 5.683 5.683 0 005.681-5.682v-13.45z"
            ></path>
         </g>
         <path
            fill="url(#paint11_radial_917_4300)"
            d="M61.843 83.476a10.591 10.591 0 10-11.363.07v13.381a5.683 5.683 0 005.682 5.682 5.683 5.683 0 005.681-5.682v-13.45z"
         ></path>
         <g filter="url(#filter5_iii_917_4300)">
            <path
               fill="url(#paint12_linear_917_4300)"
               fillRule="evenodd"
               d="M109.448 66.219a18.825 18.825 0 10-14.597.024v11.541c0 .845.327 1.656.914 2.263l1.698 1.755a2.912 2.912 0 01.155 3.876l-.813.983a2.75 2.75 0 00-.016 3.47l.792.987a3.022 3.022 0 01-.3 4.103l-1.39 1.292a3.25 3.25 0 00-1.04 2.38v6.224a7.296 7.296 0 004.465 6.9 7.294 7.294 0 005.667 0 7.306 7.306 0 004.465-6.9V66.214v.005zm-3.108-27.085a4.22 4.22 0 01-2.561 4.07 4.226 4.226 0 112.561-4.07z"
               clipRule="evenodd"
            ></path>
         </g>
         <path
            fill="url(#paint13_radial_917_4300)"
            fillRule="evenodd"
            d="M109.448 66.219a18.825 18.825 0 10-14.597.024v11.541c0 .845.327 1.656.914 2.263l1.698 1.755a2.912 2.912 0 01.155 3.876l-.813.983a2.75 2.75 0 00-.016 3.47l.792.987a3.022 3.022 0 01-.3 4.103l-1.39 1.292a3.25 3.25 0 00-1.04 2.38v6.224a7.296 7.296 0 004.465 6.9 7.294 7.294 0 005.667 0 7.306 7.306 0 004.465-6.9V66.214v.005zm-3.108-27.085a4.22 4.22 0 01-2.561 4.07 4.226 4.226 0 112.561-4.07z"
            clipRule="evenodd"
         ></path>
         <g filter="url(#filter6_f_917_4300)">
            <path
               stroke="url(#paint14_linear_917_4300)"
               strokeWidth="1.625"
               d="M102.866 65.747c9.181 0 16.624-7.57 16.624-16.908 0-9.338-7.443-16.908-16.624-16.908-9.18 0-16.623 7.57-16.623 16.908 0 9.338 7.442 16.908 16.623 16.908z"
            ></path>
         </g>
         <defs>
            <filter
               id="filter0_i_917_4300"
               width="55.989"
               height="48.563"
               x="30.071"
               y="9.234"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="4.063"></feOffset>
               <feGaussianBlur stdDeviation="2.031"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.560784 0 0 0 0 0.556863 0 0 0 0 0.576471 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_917_4300"></feBlend>
            </filter>
            <filter
               id="filter1_f_917_4300"
               width="51.797"
               height="52.626"
               x="32.167"
               y="8.218"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4300" stdDeviation="1.523"></feGaussianBlur>
            </filter>
            <filter
               id="filter2_iiii_917_4300"
               width="95.217"
               height="82.647"
               x="8.552"
               y="42.498"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="-3.047"></feOffset>
               <feGaussianBlur stdDeviation="1.523"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.898039 0 0 0 0 0.364706 0 0 0 0 0.423529 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_917_4300"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-4.063"></feOffset>
               <feGaussianBlur stdDeviation="1.523"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.772549 0 0 0 0 0.364706 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_917_4300" result="effect2_innerShadow_917_4300"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="4.063"></feOffset>
               <feGaussianBlur stdDeviation="1.523"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.843137 0 0 0 0 0.266667 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect2_innerShadow_917_4300" result="effect3_innerShadow_917_4300"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="3.047"></feOffset>
               <feGaussianBlur stdDeviation="1.523"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.478431 0 0 0 0 0.207843 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect3_innerShadow_917_4300" result="effect4_innerShadow_917_4300"></feBlend>
            </filter>
            <filter
               id="filter3_f_917_4300"
               width="29.966"
               height="74.995"
               x="74.818"
               y="42.347"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4300" stdDeviation="2.031"></feGaussianBlur>
            </filter>
            <filter
               id="filter4_f_917_4300"
               width="32.354"
               height="49.798"
               x="39.931"
               y="58.396"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4300" stdDeviation="1.016"></feGaussianBlur>
            </filter>
            <filter
               id="filter5_iii_917_4300"
               width="40.089"
               height="83.96"
               x="82.279"
               y="29.645"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="1.422" dy="-0.406"></feOffset>
               <feGaussianBlur stdDeviation="1.219"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.686275 0 0 0 0 0.529412 0 0 0 0 0.784314 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_917_4300"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-1.016" dy="1.016"></feOffset>
               <feGaussianBlur stdDeviation="1.016"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.898039 0 0 0 0 0.886275 0 0 0 0 0.929412 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_917_4300" result="effect2_innerShadow_917_4300"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="0.609" dy="0.609"></feOffset>
               <feGaussianBlur stdDeviation="1.219"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.588235 0 0 0 0 0.568627 0 0 0 0 0.607843 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect2_innerShadow_917_4300" result="effect3_innerShadow_917_4300"></feBlend>
            </filter>
            <filter
               id="filter6_f_917_4300"
               width="36.904"
               height="37.473"
               x="84.415"
               y="30.103"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4300" stdDeviation="0.508"></feGaussianBlur>
            </filter>
            <radialGradient
               id="paint0_radial_917_4300"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 -19.6777 21.4849 0 56.034 31.452)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.531" stopColor="#AB83C0"></stop>
               <stop offset="1" stopColor="#CBBBDC" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint1_radial_917_4300"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(136.74 36.295 25.393) scale(21.3036 29.2634)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.216" stopColor="#EDECF3"></stop>
               <stop offset="1" stopColor="#EDECF3" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint2_radial_917_4300"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 -28.9453 20.9755 0 42.148 51.764)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#EDECF3"></stop>
               <stop offset="1" stopColor="#EDECF3" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint3_linear_917_4300"
               x1="32.419"
               x2="60.604"
               y1="57.984"
               y2="122.098"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFC74E"></stop>
               <stop offset="1" stopColor="#F7864E"></stop>
            </linearGradient>
            <radialGradient
               id="paint4_radial_917_4300"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-132.274 76.223 39.277) scale(13.2111 30.7774)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FF8650"></stop>
               <stop offset="1" stopColor="#FF8650" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint5_linear_917_4300"
               x1="56.16"
               x2="56.16"
               y1="44.273"
               y2="50.367"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F3D04E"></stop>
               <stop offset="1" stopColor="#F3D04E" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint6_radial_917_4300"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(126.254 38.186 47.968) scale(9.44586 21.3351)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFF760"></stop>
               <stop offset="1" stopColor="#FFF760" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint7_linear_917_4300"
               x1="89.802"
               x2="89.802"
               y1="46.41"
               y2="113.278"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#BE7F3B"></stop>
               <stop offset="1" stopColor="#BC553E"></stop>
            </linearGradient>
            <radialGradient
               id="paint8_radial_917_4300"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-12.4165 0 0 -17.4607 102.221 87.624)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.336" stopColor="#763C16"></stop>
               <stop offset="1" stopColor="#763C16" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint9_linear_917_4300"
               x1="68.855"
               x2="53.113"
               y1="88.709"
               y2="88.709"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.331" stopColor="#D98A40"></stop>
               <stop offset="1" stopColor="#D98A40" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint10_linear_917_4300"
               x1="43.465"
               x2="54.511"
               y1="84.898"
               y2="84.898"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFCD63"></stop>
               <stop offset="1" stopColor="#FFCD63" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint11_radial_917_4300"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(34.061 -101.737 112.425) scale(23.1205 31.798)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.217" stopColor="#564F80"></stop>
               <stop offset="1" stopColor="#473154"></stop>
            </radialGradient>
            <linearGradient
               id="paint12_linear_917_4300"
               x1="102.119"
               x2="102.119"
               y1="78.459"
               y2="112.417"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#CDC1DE"></stop>
               <stop offset="1" stopColor="#C3B0DB"></stop>
            </linearGradient>
            <radialGradient
               id="paint13_radial_917_4300"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 53.7205 -26.3775 0 102.119 58.695)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.935" stopColor="#C0A3E0" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#B778E9"></stop>
            </radialGradient>
            <linearGradient
               id="paint14_linear_917_4300"
               x1="120.29"
               x2="109.557"
               y1="35.912"
               y2="44.557"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.126" stopColor="#EBE8F3"></stop>
               <stop offset="1" stopColor="#EBE8F3" stopOpacity="0"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}
