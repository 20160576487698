import scss from "../index.module.scss";

const { section, list, list_item } = scss;

function ArticleList({ data }) {
   return (
      <section className={section}>
         <ul className={list}>
            {data.items.map((item, key) => (
               <li className={list_item} key={key} dangerouslySetInnerHTML={{ __html: item }}></li>
            ))}
         </ul>
      </section>
   );
}

export default ArticleList;
