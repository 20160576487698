import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../Button";
import scss from "./index.module.scss";

const {
   parent,
   parent__header,
   parent__title,
   parent__subtitle,
   parent__topButton,
   parent__bottomButton,
   parent__container,
} = scss;

function BackMenu({ children }) {
   const location = useLocation();
   const navigate = useNavigate();

   const getLocation = (pathname) => {
      const paths = pathname.split("/");

      if (paths[1] === "payment-method") {
         if (paths.length === 2) {
            return {
               title: "Выберите способ оплаты",
               subtitle: "",
            };
         } else if (paths.length === 3) {
            if (paths[2] === "qr-code") {
               return {
                  title: "Оплата по QR в приложении банка",
                  subtitle: "От 1-го до 3-х дней",
               };
            }
            if (paths[2] === "requisites") {
               return { title: "Оплата по реквизитам", subtitle: "От 1-го до 3-х дней" };
            }
            if (paths[2] === "bank-card") {
               return {
                  title: "Оплата по реквизитам банковской карты",
                  subtitle: "Мгновенное погашение",
               };
            }

            return {
               title: "Выберите способ оплаты",
               subtitle: "",
            };
         } else if (paths.length === 4) {
            if (paths[3] === "qr-code") {
               return {
                  title: "Оплата по QR в приложении банка",
                  subtitle: "Мгновенное погашение",
               };
            }
            if (paths[3] === "requisites") {
               return { title: "Оплата по реквизитам", subtitle: "От 1-го до 3-х дней" };
            }
            if (paths[3] === "bank-card") {
               return {
                  title: "Оплата по реквизитам банковской карты",
                  subtitle: "Мгновенное погашение",
               };
            }
         }
      } else if (paths[1] === "oplatit") {
         if (paths.length === 2) {
            return {
               title: "Выберите способ оплаты",
               subtitle: "",
            };
         } else if (paths.length === 3) {
            if (paths[2] === "qr-code") {
               return {
                  title: "Оплата по QR в приложении банка",
                  subtitle: "От 1-го до 3-х дней",
               };
            }
            if (paths[2] === "requisites") {
               return { title: "Оплата по реквизитам", subtitle: "От 1-го до 3-х дней" };
            }
            if (paths[2] === "bank-card") {
               return {
                  title: "Оплата по реквизитам банковской карты",
                  subtitle: "Мгновенное погашение",
               };
            }

            return {
               title: "Выберите способ оплаты",
               subtitle: "",
            };
         } else if (paths.length === 4) {
            if (paths[3] === "qr-code") {
               return {
                  title: "Оплата по QR в приложении банка",
                  subtitle: "От 1-го до 3-х дней",
               };
            }
            if (paths[3] === "requisites") {
               return {
                  title: "Оплата по реквизитам",
                  subtitle: "От 1-го до 3-х дней",
               };
            }
            if (paths[3] === "bank-card") {
               return {
                  title: "Оплата по реквизитам банковской карты",
                  subtitle: "Мгновенное погашение",
               };
            }

            return {
               title: "Выберите способ оплаты",
               subtitle: "",
            };
         } else if (paths.length === 5) {
            if (paths[4] === "qr-code") {
               return {
                  title: "Оплата по QR в приложении банка",
                  subtitle: "От 1-го до 3-х дней",
               };
            }
            if (paths[4] === "requisites") {
               return { title: "Оплата по реквизитам", subtitle: "От 1-го до 3-х дней" };
            }
            if (paths[4] === "back-card") {
               return {
                  title: "Оплата по реквизитам банковской карты",
                  subtitle: "Мгновенное погашение",
               };
            }

            return {
               title: "Выберите способ оплаты",
               subtitle: "",
            };
         }
      }
      navigate("/");
   };

   return (
      <div className={parent}>
         <div className={parent__header}>
            <h2 className={parent__title}>{getLocation(location.pathname).title}</h2>
            {getLocation(location.pathname).subtitle ? (
               <span className={parent__subtitle}>
                  {getLocation(location.pathname).subtitle}
               </span>
            ) : null}
         </div>
         <Button
            className={parent__topButton}
            variant={"gray"}
            onClick={() => navigate(-1)}
         >
            Назад
         </Button>
         <div className={parent__container}>{children}</div>
         <Button
            className={parent__bottomButton}
            variant={"gray"}
            onClick={() => navigate(-1)}
         >
            Назад
         </Button>
      </div>
   );
}

export default BackMenu;
