import { useEffect } from "react";
import scss from "./index.module.scss";

const { yaRek } = scss;

function YaRek({ data, editable = false, blockIndex = -1, onChange = () => {} }) {

   useEffect(() => {
      window.yaContextCb.push(()=>{
         const delay = setTimeout(() => {
            clearTimeout(delay);

            const bannerData = {};
            if (data.id) bannerData.blockId = data.id;
            if (data.render) bannerData.renderTo = data.render;
            if (data.type) bannerData.type = data.type;

            // eslint-disable-next-line
            Ya.Context.AdvManager.render(bannerData);
         }, 100);
      });
   }, []);

   return (
      <div className={yaRek} id={!editable ? data.render : null}>
         {editable ? (
            <>
          </p>
            </>
         ) : ''}
      </div>
   );
}

export default YaRek;