import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import router from "./router/routes.jsx";

import "./index.scss";
import ContentPages from "./pages/ContentPages/index.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
   global.api = "http://192.168.43.52/api/";
} else {
   global.api = "/api/";
}

const initState = window.initState ? window.initState : {
   menu_items: [
      {
         id_page: 0,
         title: "О нас",
         pathname: "o-nas"
      },
      {
         id_page: 1,
         title: "Документы",
         pathname: "dokumenti"
      },
      {
         id_page: 2,
         title: "Дополнительные услуги",
         pathname: "dopolnitelnie-uslugi"
      },
      {
         id_page: 3,
         title: "Политика конфиденциальности",
         pathname: "politika-konfidencialnosti"
      },
   ]
};

initState.menu_items.forEach(({pathname}) => {
   router[0].children.push({
      path: pathname,
      element: <ContentPages />,
   });
});

const browserRouter = createBrowserRouter(router);

window.yaContextCb.push(()=>{
   // eslint-disable-next-line
	Ya.Context.AdvManager.render({
		"blockId": "R-A-2643553-2",
		"type": "fullscreen",
		"platform": "touch"
	});
});

root.render(
   <React.StrictMode>
      <RouterProvider router={browserRouter} />
   </React.StrictMode>
);
