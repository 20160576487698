import EmptyData from "../../../components/EmptyData";
import Container from "../../Components/Container";

import css from "./styles.module.scss";

const {
   dashboard,
   dashboard__release
} = css;

const Dashboard = () => {
   return (
      <Container className={dashboard}>
         <div className={dashboard__release}>
            <EmptyData title="Уже скоро!" text="Тут появится актуальная статистика по всей системе." />
         </div>
      </Container>
   );
};

export default Dashboard;
