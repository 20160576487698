import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import AboutAdvantages from "../../components/AboutAdvantages";
import ConfirmTelephone from "../../components/ConfirmTelephone";
import DocumentList from "../../components/DocumentList";
import Picture from "../../components/Picture";
import checkAuth from "../../helpers/checkAuth";
import api from "../../helpers/fetch";
import logOut from "../../helpers/logout";
import ReactLoading from "react-loading";

import scss from "./index.module.scss";

const {
   about,
   approved,
   approved__confirm,
   approved__title,
   offer,
   content,
   content__title,
   content__text,
   content__button,
   content__button_green,
   content__container,
   content__actions,
   offer__picture,
} = scss;

function VirtualCard() {
   const navigate = useNavigate();
   const [codeSended, setCodeSended] = useState(false);
   const [codeError, setCodeError] = useState(false);
   const [ready, setReady] = useState(false);
   const [stateCard, setStateCard] = useState("");
   const data = {
      title: "Что такое виртуальная карта?",
      items: [
         "Оплачивайте покупки в интернет магазинах или оплачивайте подписки на онлайн сервисы",
         "Оплачивайте товары в магазинах с использованием Mir Pay или Кошелёк Pay",
         "Быстрое и простое оформление без похода в отделение банка",
         "Конфиденциальность и защита личной информации",
         "Рассмотрение заявки от 1 минуты",
         "Снижаем ставку постоянным клиентам",
      ],
   };

   const onConfirm = async (code = 0) => {
      if (/^[0-9]{6}$/.test(code)) {
         setCodeSended(true);
         const body = new FormData();
         body.append("code", code);
         const auth = checkAuth();
         if (!auth) logOut();
         const resp = await new api("virtual_card_promo/code", auth, {
            method: "POST",
            body,
         }).send();
         if (resp.status === "success") {
            setCodeSended(false);
            navigate("/profile");
         } else {
            setCodeSended(false);
            if (resp.message === "Code is empty" || resp.message === "Code is invalid") {
               setCodeError("Указанный код не совпадает с кодом, отправленным в СМС.");
            } else {
               navigate("/profile");
            }
         }
      }
   };

   const onStart = async () => {
      const auth = checkAuth();
      if (auth === false) {
         alert();
         logOut();
         return;
      }
      const response = await new api("virtual_card_promo", auth).send();
      if (response.status === "success") {
         const { data } = response;
         setStateCard(data);
         setReady(true);
         if (data.status !== "created") navigate("/404");
      } else {
         logOut();
      }
   };

   useEffect(() => {
      onStart();
   }, []);

   return ready ? (
      <>
         <section className={offer}>
            <div className={content}>
               <div className={content__container}>
                  <h1 className={content__title}>Виртуальная карта</h1>
                  <p className={content__text}>
                     Оформите бесплатную виртуальную карту
                     <br /> с кредитным лимитом до{" "}
                     {stateCard.card_limit.toLocaleString("ru-RU")} ₽ онлайн
                     <br /> за пару минут!
                  </p>
               </div>
               <div className={content__actions}>
                  <Link
                     className={content__button + " " + content__button_green}
                     to={"confirm"}
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration={500}
                  >
                     Оформить
                  </Link>
                  <Link
                     className={content__button}
                     to={"advantages"}
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration={500}
                  >
                     Подробнее
                  </Link>
               </div>
            </div>
            <Picture
               className={offer__picture}
               lazy
               fileName={"VirtualCardOffer"}
               alt={"Рекламное предложение об оформлении виртуальной карты."}
            />
         </section>
         <AboutAdvantages id={"advantages"} data={data} className={about} />
         <section id={"confirm"} className={approved}>
            <h2 className={approved__title}>Оформить виртуальную карту</h2>
            <DocumentList
               data={[
                  {
                     url: stateCard.document,
                     name: "Договор о использовании виртуальной карты",
                  },
               ]}
            />
            <div className={approved__confirm}>
               <ConfirmTelephone
                  number={stateCard.phone_number}
                  onConfirm={onConfirm}
                  disableInput={codeSended}
                  inputError={codeError}
                  VirtualCardGet={"virtual_card_promo/send_code"}
                  VirtualCardText={
                     "На Ваш номер будет отправлено SMS-сообщение с кодом. Введите полученный код в поле, чтобы подписать согласие с Договор о использовании виртуальной карты."
                  }
               />
            </div>
         </section>
      </>
   ) : (
      <div
         style={{
            minHeight: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
         }}
      >
         <ReactLoading type="spin" color="#2D9614" />
      </div>
   );
}

export default VirtualCard;
