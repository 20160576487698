import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import ContentHead from "../../../components/ContentHead";
import EmptyData from "../../../components/EmptyData";
import Input from "../../../components/Input";
import Table from "../../../components/Table";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import formatPhone from "../../../helpers/formatPhone";
import getFIO from "../../../helpers/getFIO";
import TextOverflow from "../../../helpers/text-overflow";
import Card from "../../Components/Card";
import Container from "../../Components/Container";
import FulscreenLoader from "../../Components/FulscreenLoader";
import IconCheckmark from "../../Components/Icons/IconCheckmark";
import IconDismiss from "../../Components/Icons/IconDismiss";
import InfoLoanHeader from "../../Components/InfoLoanHeader";
import PopUp from "../../Components/PopUp";
import scss from "./index.module.scss";

const {
   loan,
   loan__list,
   loan__container,
   confirm,
   confirm__bank,
   confirm__tel,
   confirm__button,
   table__action,
   table__button,
   table__button_green,
   table__button_red,
   table__button_disabled,
   wrapper__accept,
   attantion_small,
   buttons,
   field,
} = scss;

function Loan() {
   const getInfo = (status, bank, tel, table, onClick) => {
      switch (status) {
         case "confirmed": {
            return (
               <Container>
                  <div className={loan__container}>
                     <EmptyData
                        redButton
                        buttonText="Отозвать решение по займу"
                        onClick={() => setIsDenied(!isDenied)}
                        showButton
                        text={"Как только клиент подпишет договор, тут появятся информация для перевода через СБП."}
                        title={"Договор ожидает подписания"}
                     />
                  </div>
               </Container>
            );
         }
         case "unpayed": {
            return (
               <Container>
                  <ContentHead
                     title={"Договор подписан!"}
                     subtitle={"Осталось лишь подтвердить денежный перевод клиенту через СБП."}
                     bottomSubtitle
                  />
                  <span className={confirm}>
                     Клиент попросил перевести деньги в <span className={confirm__bank}>{bank}</span> на номер{" "}
                     <a className={confirm__tel} href={"tel:+7" + tel}>
                        {formatPhone(tel)}
                     </a>
                  </span>
                  <Button className={confirm__button} onClick={() => setIsNewTrans(!isNewTrans)}>
                     Подтвердить перевод
                  </Button>
               </Container>
            );
         }
         case "signed": {
            return table ? (
               <Container>
                  <ContentHead title={"Транзакции"} buttonClick={() => onClick()} />
                  <Table
                     rows={table}
                     colums={[
                        {
                           paramName: "number",
                           name: "Номер транзакции",
                        },
                        {
                           paramName: "date_created",
                           name: "Дата платежа",
                        },
                        {
                           paramName: "status",
                           name: "Статус",
                        },
                        {
                           paramName: "amount",
                           name: "Сумма",
                        },
                        {
                           paramName: "action",
                           name: "Действия",
                        },
                     ]}
                     onSort={(param, direction) => setSort([param, direction])}
                     sortParam={sort[0]}
                     sortDirection={sort[1]}
                     baseColumnsWidth={[null, 260, 260, 200, 200, 152]}
                     blockOnEmpty={
                        <EmptyData title="Нет данных" text="Вероятно, клиент еще не оплатил ни одного платежа." />
                     }
                     pagination={pagination}
                     onPaginate={onPaginate}
                  />
               </Container>
            ) : (
               <FulscreenLoader />
            );
         }
         default: {
            break;
         }
      }
   };

   const params = useParams();
   const navigate = useNavigate();

   const [amount, setAmount] = useState("");
   const [datePay, setDatePay] = useState("");
   const [amountError, setAmountError] = useState("");
   const [datePayError, setDatePayError] = useState("");
   const [wait, setWait] = useState(false);
   const [isAcive, setIsActive] = useState(false);
   const [isNewTrans, setIsNewTrans] = useState(false);
   const [isDenied, setIsDenied] = useState(false);
   const [error, setError] = useState(false);
   const [errorForm, setErrorForm] = useState(false);
   const [sort, setSort] = useState([]);
   const [errorDenied, setErrorDenied] = useState(false);
   const [isDeniedTransaction, setIsDeniedTransaction] = useState(false);
   const [errorDeniedTransaction, setErrorDeniedTransaction] = useState(false);
   const [isAcceptTransaction, setIsAcceptTransaction] = useState(false);
   const [errorAcceptTransaction, setErrorAcceptTransaction] = useState(false);
   const [currentTransaction, setCurrentTransaction] = useState("");
   const [pagination, setPagination] = useState({});
   const [paginationPage, setPaginationPage] = useState(1);

   const [state, setState] = useState({
      status: "",
      userId: 0,
      number: 0,
      name: "",
      surname: "",
      midname: "",
      total_amount: 0,
      rate: 0,
      bank: "",
      phone_number: 0,
   });

   const [table, setTable] = useState([]);

   const [documents, setDocuments] = useState([]);

   const onDenied = async (e) => {
      e.preventDefault();
      setWait(true);
      const auth = checkAuth();
      const response = await new api(`manage/loans/${state?.id_loan}/decline`, auth, { method: "PUT" }).send();
      if (response.status === "success") {
         setWait(false);
         onHeandlerOpenerThree();
         navigate("/manage/loans");
      } else {
         setErrorDenied(true);
         setWait(false);
      }
      setWait(false);
   };

   const sendForm = async (e) => {
      e.preventDefault();
      setAmountError("");
      setDatePayError("");
      setWait(true);
      if (amount && parseInt(amount) > 0) {
         if (datePay) {
            const auth = checkAuth();
            const body = new FormData();
            body.append("id_loan", state.id_loan);
            body.append("amount", Math.floor(parseInt(amount) * 100));
            body.append("date", new Date(datePay)?.getTime());
            const response = await new api(`manage/transactions`, auth, { method: "POST", body }).send();
            if (response.status === "success") {
               onHeandlerOpener();
               setAmount("");
               setDatePay("");
               getData();
               getTrans();
            } else {
               setErrorForm(!errorForm);
            }
         } else {
            setDatePayError("Поле должно быть заполнено");
         }
      } else {
         setAmountError("Поле должно быть заполнено");
      }
      setWait(false);
      setAmount("");
      setDatePay("");
   };

   const getData = async () => {
      const auth = checkAuth();
      const response = await new api(`manage/loans/${params.id}`, auth).send();
      if (response.status === "success") {
         setState(response.data.loan);
         setDocuments(response.data.documents);
      } else {
         navigate("/manage/loans");
      }
   };

   const getTrans = async () => {
      const auth = checkAuth();
      let queryParams = getSortQuery("status");
      const response = await new api(
         `manage/transactions?id_loan=${params.id}${queryParams}` +
            "&page=" +
            (paginationPage !== 0 && paginationPage ? paginationPage : 1),
         auth
      ).send();
      if (response.status === "success") {
         setTable(toTable(response?.data.data));
         setPagination(response?.data.pagination);
      }
   };

   const getSortQuery = (nameField) => {
      if (sort.length === 0) {
         setSort([nameField, "asc"]);
      } else {
         return "&sort_param=" + sort[0] + "&sort_direction=" + sort[1];
      }
      return "";
   };

   useEffect(() => {
      getData();
   }, []);

   useEffect(() => {
      getTrans();
   }, [sort, paginationPage]);

   const onPaginate = (number) => {
      setPaginationPage(number);
   };

   const toTable = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value: el.id_loan_transaction,
            },
            {
               type: "date",
               value: el.date_created,
            },
            {
               type: el.status === "confirmed" ? "success" : "text",
               value: statusesTransaction[el.status],
            },
            {
               type: "money",
               value: el.amount,
            },
            {
               type: "component",
               value: (
                  <span className={table__action}>
                     {el.status === "processing" ? (
                        <>
                           <button
                              className={table__button + " " + table__button_green}
                              onClick={() => setCurrentAccept(el.id_loan_transaction)}
                           >
                              <IconCheckmark />
                           </button>
                           <button
                              className={table__button + " " + table__button_red}
                              onClick={() => setCurrentDenied(el.id_loan_transaction)}
                           >
                              <IconDismiss />
                           </button>
                        </>
                     ) : (
                        <>
                           <button className={table__button + " " + table__button_disabled} disabled>
                              <IconCheckmark />
                           </button>
                           <button className={table__button + " " + table__button_disabled} disabled>
                              <IconDismiss />
                           </button>
                        </>
                     )}
                  </span>
               ),
            },
         ]);
      });
      return tmpArray;
   };

   const statusesTransaction = {
      confirmed: "Одобрена",
      processing: "Обрабатывается",
      denied: "Отклонёна",
   };

   const onConfirm = async (e) => {
      e.preventDefault();
      const auth = checkAuth();
      const response = await new api(`manage/loans/${params.id}/payout`, auth, { method: "PUT" }).send();
      if (response.status === "success") {
         onHeandlerOpenerSecond();
         getData();
      } else {
         setError(!error);
      }
   };

   const setCurrentAccept = (id) => {
      setIsAcceptTransaction(true);
      setCurrentTransaction(id);
   };

   const setCurrentDenied = (id) => {
      setIsDeniedTransaction(true);
      setCurrentTransaction(id);
   };

   const onDeniedTransaction = async (e) => {
      e.preventDefault();
      const auth = checkAuth();
      setWait(true);
      const body = JSON.stringify({ status: "denied" });
      const response = await new api(`manage/transactions/${currentTransaction}/update`, auth, {
         method: "PUT",
         body,
      }).send();
      if (response.status === "success") {
         onHeandlerOpenerFore();
         getTrans();
      }
      setWait(false);
      setCurrentTransaction("");
   };

   const onAcceptTransaction = async (e) => {
      e.preventDefault();
      const auth = checkAuth();
      setWait(true);
      const body = JSON.stringify({ status: "confirmed" });
      const response = await new api(`manage/transactions/${currentTransaction}/update`, auth, {
         method: "PUT",
         body,
      }).send();
      if (response.status === "success") {
         onHeandlerOpenerFive();
         getTrans();
      }
      setWait(false);
      setCurrentTransaction("");
   };

   const onHeandlerOpener = () => {
      document.body.style.overflowY = null;
      setIsActive(!isAcive);
   };
   const onHeandlerOpenerSecond = () => {
      document.body.style.overflowY = null;
      setIsNewTrans(!isNewTrans);
   };
   const onHeandlerOpenerThree = () => {
      document.body.style.overflowY = null;
      setIsDenied(!isDenied);
   };
   const onHeandlerOpenerFore = () => {
      document.body.style.overflowY = null;
      setIsDeniedTransaction(!isDeniedTransaction);
   };
   const onHeandlerOpenerFive = () => {
      document.body.style.overflowY = null;
      setIsAcceptTransaction(!isAcceptTransaction);
   };

   return (
      <>
         <div className={loan}>
            <InfoLoanHeader
               params={state.number}
               rate={state.rate}
               amount={state.total_amount / 100}
               initials={getFIO(state.name, TextOverflow(state.surname, 11), state.midname, "ser-initials")}
               id={state.id_client}
            />
            <Container>
               <h2>Документы</h2>
               <div className={loan__list}>
                  {documents.map(({ name, url }, key) => (
                     <Card type={"document"} title={TextOverflow(name, 55)} link={url} key={key.toString()} />
                  ))}
               </div>
            </Container>
            {getInfo(state.status, state.bank, state.phone_number, table, () => setIsActive(!isAcive))}
         </div>
         {isAcive ? (
            <PopUp title={"Новая транзакция"} onCloase={() => onHeandlerOpener()}>
               <form action="" onSubmit={sendForm} className={wrapper__accept}>
                  {!errorForm ? (
                     <>
                        <Input
                           label={"Сумма платежа"}
                           placeholder={"10000"}
                           className={field}
                           type={"number"}
                           value={amount}
                           onChange={setAmount}
                           error={amountError}
                        />
                        <Input
                           label={"Дата платежа"}
                           placeholder={"01.01.2001"}
                           className={field}
                           type={"date"}
                           value={datePay}
                           onChange={setDatePay}
                           error={datePayError}
                        />
                        <p className={attantion_small}>
                           Внимательно проверьте данные, которые вы ввели в поля выше. Если вы допустите ошибку,
                           изменить данные будет не возможно.
                        </p>
                        <p className={attantion_small}>
                           Подтверждая данную форму, вы соглашаетесь с материальной ответственностью.
                        </p>
                     </>
                  ) : (
                     <>
                        <p className={attantion_small}>Что-то пошло не по плану!</p>
                        <p className={attantion_small}>Пробуйте немного позже или обратитесь в службу поддержки.</p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onHeandlerOpener()}>
                        Отмена
                     </Button>
                     {!errorForm ? (
                        <Button isWaiting={wait} type={"submit"}>
                           Зарегистрировать
                        </Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>Обновить страницу</Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isNewTrans ? (
            <PopUp title={"Требуется подтверждение"} onCloase={() => onHeandlerOpenerSecond()}>
               <form action="" onSubmit={onConfirm} className={wrapper__accept}>
                  {error ? (
                     <>
                        <p className={attantion_small}>Что-то пошло не по плану!</p>
                        <p className={attantion_small}>Пробуйте немного позже или обратитесь в службу поддержки.</p>
                     </>
                  ) : (
                     <>
                        <p className={attantion_small}>
                           Вы уверены что хотите подтвердить перевод денежных средств клиенту?
                        </p>
                        <p className={attantion_small}>
                           Операцию будет нельзя отменить или изменить. Будьте внимательны!
                        </p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onHeandlerOpenerSecond()}>
                        Отмена
                     </Button>
                     {!error ? (
                        <Button type={"submit"}>Подтвердить</Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>Обновить страницу</Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isDenied ? (
            <PopUp title={"Требуется подтверждение"} onCloase={() => onHeandlerOpenerThree()}>
               <form action="" onSubmit={onDenied} className={wrapper__accept}>
                  {!errorDenied ? (
                     <p className={attantion_small}>
                        Если вы отзовете решение, то обратить действие будет невозможно! В случае, если вы это сделали
                        случано, свяжитесь с клиентом и попросите еще раз оформить заявку.
                     </p>
                  ) : (
                     <>
                        <p className={attantion_small}>Что-то пошло не по плану!</p>
                        <p className={attantion_small}>Пробуйте позже или обратитесь в службу поддержки.</p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onHeandlerOpenerThree()}>
                        Отмена
                     </Button>
                     {!errorDenied ? (
                        <Button isWaiting={wait} type={"submit"}>
                           Отозвать решение
                        </Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>Обновить страницу</Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isDeniedTransaction ? (
            <PopUp title={"Требуется подтверждение"} onCloase={() => onHeandlerOpenerFore()}>
               <form action="" onSubmit={onDeniedTransaction} className={wrapper__accept}>
                  {!errorDeniedTransaction ? (
                     <p className={attantion_small}>
                        Отклонив транзакцию, вы не сможете более ее подтвердить. Внимательно проврьте данные, преред
                        отклонением транзакции.
                     </p>
                  ) : (
                     <>
                        <p className={attantion_small}>Что-то пошло не по плану!</p>
                        <p className={attantion_small}>Пробуйте позже или обратитесь в службу поддержки.</p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onHeandlerOpenerFore()}>
                        Отмена
                     </Button>
                     {!errorDeniedTransaction ? (
                        <Button isWaiting={wait} type={"submit"}>
                           Отозвать решение
                        </Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>Обновить страницу</Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isAcceptTransaction ? (
            <PopUp title={"Требуется подтверждение"} onCloase={() => onHeandlerOpenerFive()}>
               <form action="" onSubmit={onAcceptTransaction} className={wrapper__accept}>
                  {!errorAcceptTransaction ? (
                     <p className={attantion_small}>
                        Одобрив транзакцию, вы не сможете более ее изменять. Внимательно проврьте данные, преред
                        подтверждением транзакции.
                     </p>
                  ) : (
                     <>
                        <p className={attantion_small}>Что-то пошло не по плану!</p>
                        <p className={attantion_small}>Пробуйте позже или обратитесь в службу поддержки.</p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onHeandlerOpenerFive()}>
                        Отмена
                     </Button>
                     {!errorDenied ? (
                        <Button isWaiting={wait} type={"submit"}>
                           Одобрить транзакцию
                        </Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>Обновить страницу</Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
      </>
   );
}

export default Loan;
