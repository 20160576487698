import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import PageBuilder from "../../../components/pageBuilder";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import logOut from "../../../helpers/logout";
import PopUp from "../../Components/PopUp";
import classes from "./styles.module.scss";

const {
   pageEditor,
   pageEditor__view,
   pageEditor__builder,
   pageEditor__tools,

   pageEditor__loading,
   pageEditor__loading_fade,
   pageEditor__loadingBlocks,
   pageEditor__loadingBlock,
   pageEditor__loadingTools,

   pageEditor__prop,
   pageEditor__propName,

   modalText,
   modalButtons
} = classes;

function Page() {

   const params = useParams();
   const navigate = useNavigate();

   const [loading, setLoading] = useState(true);
   const [loadingFade, setLoadingFade] = useState(false);

   const [saveModalState, setSaveModalState] = useState(false);

   const [jsonContent, setJsonContent] = useState([]);

   const [pageName, setPageName] = useState("");
   const [pageTitle, setPageTitle] = useState("");
   const [pageDescription, setPageDescription] = useState([""]);
   const [pageKeywords, setPageKeywords] = useState([""]);
   const [pagePath, setPagePath] = useState("");

   // Добавить в следующих обновлениях (или убрать если не вернёмся к этому через год)
   const [pageBanner, setPageBanner] = useState("");

   const [selectedComponentType, setSelectedComponentType] = useState(null);

   const getPage = async () => {
      const pageId = parseInt(params.id);

      if (isNaN(pageId)) {
         navigate("/manage/pages");
         return false;
      }

      const auth = checkAuth();
      if (!auth) logOut();

      const role = parseInt(localStorage.getItem("role"));

      if (role !== 2 && role !== 3) logOut();
      
      const response = await new api(`manage/pages/${pageId}`, auth).send();

      if (response.status === "success") {
         setJsonContent(response.data[0].json_content);

         setPageName(response.data[0].title);
         setPageBanner(response.data[0].banner_image);
         setPagePath(response.data[0].pathname);

         if (response.data[0].meta) {
            const meta = response.data[0].meta;

            setPageTitle(meta.title ? meta.title : "");
            setPageDescription(meta.description ? [meta.description] : [""]);
            setPageKeywords(meta.keywords ? [meta.keywords] : [""]);
         }

         const timer1 = setTimeout(() => {

            setLoadingFade(true);

            const timer2 = setTimeout(() => {
               setLoading(false);
               clearTimeout(timer2);
            }, 400);
            
            clearTimeout(timer1);
         }, 2000);
      }
      else {
         navigate("/manage/pages");
      }
   }

   const onContentChage = (blockIndex, parametr, value) => {
      const contentTmp = JSON.parse(JSON.stringify(jsonContent));

      if (parametr.indexOf(".") > 0) {
         const parametrPath = parametr.split(".");

         parametrPath.forEach((path, i) => {
            parametrPath[i] = /^[0-9]{1,}$/.test(parametrPath[i]) ? parseInt(parametrPath[i]) : parametrPath[i];
         });


         const pathCount = parametrPath.length;
         
         const setValueRek = (node, pathIndex, newValue) => {

            if (pathIndex < pathCount) {
               if (pathIndex === pathCount - 1) {
                  node[parametrPath[pathIndex]] = newValue;
                  return node;
               }
               else {
                  node[parametrPath[pathIndex]] = setValueRek(node[parametrPath[pathIndex]], ++pathIndex, newValue);
                  return node;
               }
            }
            else {
               return node;
            }
         }

         contentTmp[blockIndex]["data"] = setValueRek(contentTmp[blockIndex]["data"], 0, value);

         console.log(contentTmp);
      }
      else {
         contentTmp[blockIndex]["data"][parametr] = value;
      }

      setJsonContent(contentTmp);
   }

   const [forceSaveState, setForceSaveState] = useState(false);
   const onChangeGlobal = (blockIndex, data, forceSave = false) => {
      setForceSaveState(forceSave);
      const contentTmp = JSON.parse(JSON.stringify(jsonContent));

      contentTmp[blockIndex].data = data;

      setJsonContent(contentTmp);
   }

   // Debug
   useEffect(() => {
      if (forceSaveState) sendSaveRquest();
      setForceSaveState(false);

      console.log(jsonContent);
   }, [jsonContent]);
   
   useEffect(() => {
      getPage();
   }, []);

   const saveChanges = () => {
      setSaveModalState(true);
   }

   const [saveState, setSaveState] = useState(true);
   
   const [resultModalState, setResultModalState] = useState(false);
   const [errorModalState, setErrorModalState] = useState(false);

   const sendSaveRquest = async () => {
      const pageId = parseInt(params.id);

      if (isNaN(pageId)) {
         navigate("/manage/pages/");
         return false;
      }

      const auth = checkAuth();
      if (!auth) logOut();

      const role = parseInt(localStorage.getItem("role"));

      if (role !== 2 && role !== 3) logOut();

      const body = {
         title: pageName,
         meta: {
            title: pageTitle.trim(),
            description: pageDescription.join(" ").trim(),
            keywords: pageKeywords.join(" ").trim()
         },
         banner_image: "",
         json_og: {},
         pathname: pagePath.trim(),
         json_content: jsonContent
      };

      setSaveState(false);
      
      const response = await new api(`manage/pages/${pageId}`, auth, {
         method: "PUT",
         body: JSON.stringify(body)
      }).send();

      setSaveState(true);
      setSaveModalState(false);

      if (response.status === "success") {
         setResultModalState(true);
      }
      else {
         setErrorModalState(true);
      }
   }

   const openPage = () => {
      setResultModalState(false);
      window.open('/' + pagePath, '_blank');
   }

   return (
      <div className={pageEditor}>
         {
            loading ? (
               <div className={pageEditor__loading + (loadingFade ? " " + pageEditor__loading_fade : "")}>
                  <div className={pageEditor__loadingBlocks}>
                     <div className={pageEditor__loadingBlock} style={{minHeight: (100 + Math.random() * 500) + "px"}} />
                     <div className={pageEditor__loadingBlock} style={{minHeight: (100 + Math.random() * 500) + "px"}} />
                     <div className={pageEditor__loadingBlock} style={{minHeight: (100 + Math.random() * 500) + "px"}} />
                  </div>
                  <div className={pageEditor__loadingTools}>
                     <div className={pageEditor__loadingBlock} style={{minHeight: (100 + Math.random() * 100) + "px"}} />
                     <div className={pageEditor__loadingBlock} style={{minHeight: (100 + Math.random() * 100) + "px"}} />
                     <div className={pageEditor__loadingBlock} style={{minHeight: (100 + Math.random() * 100) + "px"}} />
                     <div className={pageEditor__loadingBlock} style={{minHeight: (100 + Math.random() * 100) + "px"}} />
                     <div className={pageEditor__loadingBlock} style={{minHeight: (100 + Math.random() * 100) + "px"}} />
                     <div className={pageEditor__loadingBlock} style={{minHeight: (100 + Math.random() * 100) + "px"}} />
                  </div>
               </div>
            ) : ""
         }
         <div className={pageEditor__view}>
            <PageBuilder
               className={pageEditor__builder}
               content={jsonContent}
               editable={true}
               onChange={onContentChage}
               onChangeGlobal={onChangeGlobal}
            />
         </div>
         <div className={pageEditor__tools}>
            
            {
               selectedComponentType === null ? (
                  <>
                     <div className={pageEditor__prop}>
                        <p className={pageEditor__propName}>*Название страницы в&nbsp;меню сайта</p>
                        <Input
                           placeholder={"Новая страница"}
                           value={pageName}
                           onChange={setPageName}
                        />
                     </div>
                     
                     <div className={pageEditor__prop}>
                        <p className={pageEditor__propName}>*Название страницы (в&nbsp;поиске, вкладке и&nbsp;т.д.)</p>
                        <Input
                           placeholder={"Новая страница"}
                           value={pageTitle}
                           onChange={setPageTitle}
                        />
                     </div>
                     
                     {
                        parseInt(params.id) > 1 ? (
                           <div className={pageEditor__prop}>
                              <p className={pageEditor__propName}>*Путь до&nbsp;страницы</p>
                              <Input
                                 placeholder={"novaya-stranica"}
                                 value={pagePath}
                                 onChange={setPagePath}
                              />
                           </div>
                        ) : ""
                     }
                     
                     <div className={pageEditor__prop}>
                        <p className={pageEditor__propName}>Описание страницы</p>
                        <Input
                           placeholder={"Описание новой страницы"}
                           type={"textarea"}
                           value={pageDescription}
                           onChange={setPageDescription}
                        />
                     </div>
                     
                     <div className={pageEditor__prop}>
                        <p className={pageEditor__propName}>Ключевые слова (через запятую)</p>
                        <Input
                           placeholder={"Слово 1, Слово 2, Слово 3"}
                           type={"textarea"}
                           value={pageKeywords}
                           onChange={setPageKeywords}
                        />
                     </div>
                     
                     {/* <div className={pageEditor__prop}>
                        <p className={pageEditor__propName}>Page banner image</p>
                     </div> */}
                     
                     <div className={pageEditor__prop}>
                        <Button onClick={saveChanges}>Сохранить изменения</Button>
                     </div>
                  </>
               ) : (
                  <>
                     <div className={pageEditor__prop}>
                        <p className={pageEditor__propName}>Редактор компонента</p>
                     </div>
                  </>
               )
            }
            
         </div>

         {
            saveModalState ? (
               <PopUp
                  title="Сохранение изменений"
                  onCloase={saveState ? () => setSaveModalState(false) : null}
               >
                  <p className={modalText}>
                     Вы действительно хотите сохранить внесённые изменения? После сохранения, вернуть страницу к&nbsp;предыдущему состоянию можно будет только вручную.
                  </p>

                  <div className={modalButtons}>
                     <Button
                        variant="gray"
                        onClick={() => setSaveModalState(false)}
                        isWaiting={!saveState ? true : null}
                     >Отменить</Button>
                     <Button
                        onClick={sendSaveRquest}
                        isWaiting={!saveState ? true : null}
                     >Сохранить</Button>
                  </div>
               </PopUp>
            ) : (
               resultModalState ? (
                  <PopUp
                     title="Сохранение изменений"
                     onCloase={() => setResultModalState(false)}
                  >
                     <p className={modalText}>
                        Данные страницы успешно обновлены.
                     </p>
   
                     <div className={modalButtons}>
                        <Button
                           variant="gray"
                           onClick={() => setResultModalState(false)}
                        >Хорошо</Button>
                        <Button
                           onClick={openPage}
                        >Просмотр</Button>
                     </div>
                  </PopUp>
               ) : (
                  errorModalState ? (
                     <PopUp
                        title="Сохранение изменений"
                        onCloase={() => setErrorModalState(false)}
                     >
                        <p className={modalText}>
                           Не удалось сохранить изменения на странице. Повторите попытку чуть позже.
                        </p>
      
                        <div className={modalButtons}>
                           <Button
                              variant="gray"
                              onClick={() => setErrorModalState(false)}
                           >Закрыть</Button>
                        </div>
                     </PopUp>
                  ) : ""
               )
            )
         }
      </div>
   );
}

export default Page;
