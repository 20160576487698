import { useEffect } from "react";
import { useState } from "react";
import Input from "../../components/Input";
import css from "./styles.module.scss";

const {
    application__stepBlock,
    application__formBlock
} = css;

const ApplicationStep1 = ({onChange = () => {}, data = {}, errors = {}, hasSend = false}) => {

    const [name, setName] = useState(data.name ? data.name : "");
    const [surname, setSurname] = useState(data.surname ? data.surname : "");
    const [midname, setMidname] = useState(data.midname ? data.midname : "");
    const [email, setEmail] = useState(data.email ? data.email : "");


    useEffect(() => {
        onChange({
            name,
            surname,
            midname,
            email
        });
    }, [name, surname, midname, email]);

    return (
        <>
            <div className={application__stepBlock}>
                <Input
                    label={"Имя"}
                    placeholder={"Иван"}
                    className={application__formBlock}
                    type={"text"}
                    value={name}
                    onChange={setName}
                    error={errors.name ? errors.name : null}
                    showErrorOnBlur={!hasSend}
                />
                <Input
                    label={"Фамилия"}
                    placeholder={"Иванов"}
                    className={application__formBlock}
                    type={"text"}
                    value={surname}
                    onChange={setSurname}
                    error={errors.surname ? errors.surname : null}
                    showErrorOnBlur={!hasSend}
                />
            </div>
            <div className={application__stepBlock}>
                <Input
                    label={"Отчество"}
                    placeholder={"Иванович"}
                    className={application__formBlock}
                    type={"text"}
                    value={midname}
                    onChange={setMidname}
                    error={errors.midname ? errors.midname : null}
                    showErrorOnBlur={!hasSend}
                />
                <Input
                    label={"Электронная почта"}
                    placeholder={"ivanov.ivan@mail.ru"}
                    className={application__formBlock}
                    type={"text"}
                    value={email}
                    onChange={setEmail}
                    error={errors.email ? errors.email : null}
                    showErrorOnBlur={!hasSend}
                />
            </div>
        </>
    );
}

export default ApplicationStep1;