function Email(props) {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         fill="none"
         viewBox="0 0 24 24"
         {...props}
      >
         <path
            fill="#02181D"
            d="M12 22a10.14 10.14 0 01-9.21-6.13A9.66 9.66 0 012 12c0-1.38.26-2.68.79-3.89A10.19 10.19 0 018.12 2.8 9.66 9.66 0 0112 2a9.6 9.6 0 013.89.79A10.17 10.17 0 0121.2 8.1c.53 1.21.79 2.5.79 3.89v1.45c0 .98-.34 1.82-1.01 2.51-.68.7-1.5 1.04-2.49 1.04a3.75 3.75 0 01-2.95-1.45A4.97 4.97 0 0112 17a4.82 4.82 0 01-3.54-1.46A4.81 4.81 0 017 12c0-1.38.49-2.56 1.46-3.54A4.82 4.82 0 0112 7c1.38 0 2.56.49 3.54 1.46A4.81 4.81 0 0117 12v1.45A1.47 1.47 0 0018.5 15a1.47 1.47 0 001.5-1.55V12a7.7 7.7 0 00-2.36-5.64A7.7 7.7 0 0012 4a7.7 7.7 0 00-5.64 2.36A7.7 7.7 0 004 12a7.7 7.7 0 002.36 5.64A7.7 7.7 0 0012 20h4c.28 0 .52.1.71.29.2.19.3.43.29.71 0 .28-.1.52-.29.71A.9.9 0 0116 22h-4zm0-7c.83 0 1.54-.3 2.13-.88.58-.58.87-1.29.87-2.12 0-.83-.3-1.54-.88-2.13A2.9 2.9 0 0012 9c-.83 0-1.54.3-2.13.88A2.9 2.9 0 009 12c0 .83.3 1.54.88 2.13.58.58 1.29.87 2.12.87z"
         ></path>
      </svg>
   );
}

export default Email;
