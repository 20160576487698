import checkAuth from "../../helpers/checkAuth";
import SignIn from "../../pages/SignIn"

const AuthWrapper = ({children}) => {

    const token = checkAuth();

    if (token) {
        if (localStorage.getItem("role") === "0") {
            return children;
        }
        else {
            window.location.href = "/";
        }
    }
    else return <SignIn />;
}

export default AuthWrapper;