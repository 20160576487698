import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import EmptyData from "../../../components/EmptyData";
import IconCopy from "../../../components/Icons/Copy";
import Input from "../../../components/Input";
import Table from "../../../components/Table";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import formatPhone from "../../../helpers/formatPhone";
import getFIO from "../../../helpers/getFIO";
import logOut from "../../../helpers/logout";
import Accordion from "../../Components/Accordion";
import AccordionItem from "../../Components/AccordionItem";
import Card from "../../Components/Card";
import IconArrowRight from "../../Components/Icons/IconArrowRight";
import LongNotice from "../../Components/longNotice";
import PopUp from "../../Components/PopUp";
import ReactLoading from "react-loading";

import scss from "./index.module.scss";

const {
   container,
   container__section_1,
   container__section_2,
   container__section_3,
   avatar,
   card__wrapper,
   card__title,
   card__link,
   card__link_tel,
   card__link_email,
   link__header,
   link__block,
   link__title,
   link__subtitle,
   passport,
   passport_row,
   passport__block,
   field,
   button,
   passport__block_col,
   form,
   form__text,
   form__buttons,
   form__fields,
   form__buttons_min,
   acord,
   textaria,
   notice,
   notice__text,
   notice__actions,
   notice__button,
   notice__button_red,
   about,
   about_item,
   about_text,
   about_text_nums,
   about_text_link,
   about_subtext,
} = scss;

function Customer() {
   const params = useParams();
   const navigate = useNavigate();
   const [isLoad, setIsLoad] = useState("");
   const [state, setState] = useState({});
   const [sort, setSort] = useState([]);
   const [row, setRow] = useState([]);
   const [password, setPassword] = useState([]);
   const [pagination, setPagination] = useState({});
   const [paginationPage, setPaginationPage] = useState(1);
   const [valueBlock, setValueBlock] = useState("");
   const [valueBlockError, setValueBlockError] = useState("");
   const [passwordError, setPasswordError] = useState("");

   const [isActive, setIsActive] = useState(false);
   const [isBlock, setIsBlock] = useState(false);
   const [isDelete, setIsDelite] = useState(false);

   const [isAddCard, setIsAddCard] = useState(false);
   const [isCancelCard, setIsCancelCard] = useState(false);
   const [cardNumber, setCardNumber] = useState("");
   const [cvc, setCVC] = useState("");
   const [cardMonth, setCardMonth] = useState("");
   const [cardYear, setCardYear] = useState("");
   const [cardLimit, setCardLimit] = useState("");
   const [ErrorCardNumber, setErrorCardNumber] = useState("");
   const [ErrorCvc, setErrorCVC] = useState("");
   const [ErrorCardMonth, setErrorCardMonth] = useState("");
   const [ErrorCardYear, setErrorCardYear] = useState("");
   const [ErrorCardLimit, setErrorCardLimit] = useState("");

   const [wait, setWait] = useState(false);

   const jobTypeArray = ["Работаю", "Не работаю", "Пенсионер"];

   const nextIncomeArray = [
      "1го числа",
      "2го числа",
      "3го числа",
      "4го числа",
      "5го числа",
      "6го числа",
      "7го числа",
      "8го числа",
      "9го числа",
      "10го числа",
      "11го числа",
      "12го числа",
      "13го числа",
      "14го числа",
      "15го числа",
      "16го числа",
      "17го числа",
      "18го числа",
      "19го числа",
      "20го числа",
      "21го числа",
      "22го числа",
      "23го числа",
      "24го числа",
      "25го числа",
      "26го числа",
      "27го числа",
      "28го числа",
      "29го числа",
      "30го числа",
      "31го числа",
   ];

   const incomeArray = [
      "До 10 000 ₽",
      "10 000 ₽ - 20 000 ₽",
      "20 000 ₽ - 35 000 ₽",
      "35 000 ₽ - 50 000 ₽",
      "Более 50 000 ₽",
   ];

   const status = {
      confirmed: "Одобрен",
      signed: "Подписан",
      completed: "Выплачен",
      closed: "Закрыт",
      denied: "Отклонён",
      unpayed: "Нужна выплата",
   };

   const toTable = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value: el.number,
            },
            {
               type: "money",
               value: el.total_amount,
            },
            {
               type: "text",
               value: status[el.status],
            },
            {
               type: "component",
               value:
                  el.status === "confirmed" ||
                  el.status === "signed" ||
                  el.status === "unpayed" ? (
                     <Link to={"/manage/loans/" + el.id_loan} className={button}>
                        Подробнее
                     </Link>
                  ) : (
                     <Link to={"/manage/archive/" + el.id_loan} className={button}>
                        Подробнее
                     </Link>
                  ),
            },
         ]);
      });
      return tmpArray;
   };

   const getData = async () => {
      const auth = checkAuth();
      if (!auth) logOut();
      const response = await new api(`manage/clients/${params.id}`, auth).send();
      if (response.status === "success") {
         setState(response.data.client);
         setIsLoad(true);
      } else navigate("/404");
   };

   const getTable = async () => {
      const auth = checkAuth();
      if (!auth) logOut();
      let queryParams = getSortQuery("status");
      const response = await new api(
         `manage/loans/?id_client=${params.id}&total&` +
            queryParams +
            "&page=" +
            (paginationPage !== 0 && paginationPage ? paginationPage : 1),
         auth
      ).send();
      if (response.status === "success") {
         setRow(toTable(response.data.data));
         setPagination(response.data.pagination);
      }
   };

   const getPasport = () => {
      if (state.pasport) {
         let pas = state.pasport.toString();
         return pas.slice(0, 4) + "-" + pas.slice(4, 10);
      } else return 0;
   };

   const getCode = () => {
      if (state.pasportOrgCode) {
         let pas = state.pasportOrgCode.toString();
         return pas.slice(0, 3) + "-" + pas.slice(3, 6);
      } else return 0;
   };

   const getSortQuery = (nameField) => {
      if (sort.length === 0) {
         setSort([nameField, "asc"]);
      } else {
         return "sort_param=" + sort[0] + "&sort_direction=" + sort[1];
      }
   };

   useEffect(() => {
      getData();
      getTable();
   }, []);

   useEffect(() => {
      getTable();
   }, [sort, paginationPage]);

   const onPaginate = (number) => {
      setPaginationPage(number);
   };

   const onOpenAddCard = () => {
      setIsAddCard(!isAddCard);
      document.body.style.overflowY = "hidden";
   };

   const onCloasAddCard = () => {
      setErrorCardNumber("");
      setErrorCardMonth("");
      setErrorCardYear("");
      setErrorCVC("");
      setErrorCardLimit("");
      setCardNumber("");
      setCVC("");
      setCardMonth("");
      setCardYear("");
      setCardLimit("");
      setIsAddCard(!isAddCard);
      document.body.style.overflowY = null;
   };

   const onOpenCancelCard = () => {
      setIsCancelCard(!isCancelCard);
      document.body.style.overflowY = "hidden";
   };

   const onCloasCancelCard = () => {
      setIsCancelCard(!isCancelCard);
      document.body.style.overflowY = null;
   };

   const onOpen = () => {
      setIsActive(!isActive);
      document.body.style.overflowY = "hidden";
   };

   const onCloas = () => {
      setIsActive(!isActive);
      document.body.style.overflowY = null;
      getData();
      getTable();
   };

   const onOpenBlock = () => {
      setIsBlock(!isBlock);
      document.body.style.overflowY = "hidden";
   };

   const onCloasBlock = () => {
      setIsBlock(!isBlock);
      document.body.style.overflowY = null;
      getData();
      getTable();
   };

   const onOpenDelete = () => {
      setIsDelite(!isDelete);
      document.body.style.overflowY = "hidden";
   };

   const onCloasDelete = () => {
      setIsDelite(!isDelete);
      document.body.style.overflowY = null;
   };

   const onSubmit = async (e) => {
      e.preventDefault();
      setWait(true);
      const auth = checkAuth();
      const response = await new api(
         `manage/clients/${state?.id_user ? state?.id_user : null}/reset_blocks`,
         auth,
         {
            method: "PUT",
         }
      ).send();
      if (response.status === "success") {
         onCloas();
         getData();
         setWait(false);
      } else {
         setWait(false);
      }
      setWait(false);
   };

   const onAddCard = async (e) => {
      e.preventDefault();
      setErrorCardNumber(
         /^[0-9]{16}$/.test(cardNumber) ? "" : "В поле содержится ошибка"
      );
      setErrorCardMonth(
         parseInt(cardMonth) <= 12 && parseInt(cardMonth) > 0 ? "" : "Ошибка"
      );
      setErrorCardYear(
         /^[0-9]{2}$/.test(cardYear) && parseInt(cardYear) > 0 ? "" : "Ошибка"
      );
      setErrorCVC(/^[0-9]{3}$/.test(cvc) ? "" : "Ошибка");
      setErrorCardLimit(parseInt(cardLimit) > 0 ? "" : "В поле содержится ошибка");
      setWait(true);
      if (
         ErrorCardNumber !== "В поле содержится ошибка" &&
         ErrorCardMonth !== "Ошибка" &&
         ErrorCardYear !== "Ошибка" &&
         ErrorCvc !== "Ошибка" &&
         ErrorCardLimit !== "В поле содержится ошибка"
      ) {
         if (cardNumber && cardMonth && cardYear && cvc && cardLimit) {
            const auth = checkAuth();
            const body = new FormData();
            body.append("number", cardNumber);
            body.append("month", parseInt(cardMonth));
            body.append("year", parseInt(cardYear));
            body.append("cvc", cvc);
            body.append("card_limit", cardLimit);
            const response = await new api(
               `manage/clients/${state?.id_user ? state?.id_user : null}/virtual_card`,
               auth,
               {
                  method: "POST",
                  body,
               }
            ).send();
            if (response.status === "success") {
               onCloasAddCard();
               setIsLoad(false);
               getData();
            } else if (response.message === "Month is invalid") {
               setErrorCardMonth("Ошибка");
            } else if (response.message === "Year is invalid") {
               setErrorCardYear("Ошибка");
            }
         }
      }
      setWait(false);
   };

   const onCancelCard = async (e) => {
      e.preventDefault();
      setWait(true);
      const auth = checkAuth();
      const response = await new api(
         `manage/clients/${state?.id_user ? state?.id_user : null}/virtual_card`,
         auth,
         {
            method: "delete",
         }
      ).send();
      if (response.status === "success") {
         onCloasCancelCard();
         setIsLoad(false);
         getData();
      }
      setWait(false);
   };

   const onSubmitBlock = async (e) => {
      e.preventDefault();
      setValueBlockError("");
      setWait(true);
      let regexp = /^[A-Za-zА-Яа-яЁё0-9\s\.\,\-\(\)]{3,}$/.test(valueBlock);
      if (regexp && valueBlock) {
         const auth = checkAuth();
         const body = JSON.stringify({
            reason: valueBlock,
         });
         const response = await new api(
            `manage/clients/${state?.id_user ? state?.id_user : null}/block`,
            auth,
            {
               method: "PUT",
               body,
            }
         ).send();
         if (response.status === "success") {
            setValueBlock("");
            onCloasBlock();
            getData();
            setWait(false);
         } else {
            setWait(false);
         }
      } else {
         setValueBlockError("В сообщении содержаться не корректные символы.");
      }
      setWait(false);
   };

   const onSubmitUnBlock = async (e) => {
      e.preventDefault();
      setWait(true);
      const auth = checkAuth();
      const response = await new api(
         `manage/clients/${state?.id_user ? state?.id_user : null}/unblock`,
         auth,
         {
            method: "PUT",
         }
      ).send();
      if (response.status === "success") {
         onCloasBlock();
         getData();
         setWait(false);
      } else {
         setWait(false);
      }

      setWait(false);
   };

   const onSubmitDelete = async (e) => {
      e.preventDefault();
      setPasswordError("");
      setWait(true);
      const auth = checkAuth();
      const body = JSON.stringify({
         password: password,
      });
      const response = await new api(
         `manage/clients/${state?.id_user ? state?.id_user : null}/remove`,
         auth,
         {
            method: "DELETE",
            body,
         }
      ).send();
      if (response.status === "success") {
         onCloasDelete();
         navigate(-1);
         setWait(false);
      } else {
         setWait(false);
         setPasswordError("Не верный пароль или неизвестная ошибка.");
      }
      setWait(false);
      setPassword("");
   };

   return isLoad ? (
      <div className={container}>
         <Card
            type={state?.phone_number ? "wrapper" : ""}
            className={container__section_1}
         >
            <div className={card__wrapper}>
               <img
                  src="/assets/base_avatar.jpg"
                  className={avatar}
                  alt="Аватар пользователя"
                  width="64"
                  height="64"
               />
               <h2 className={card__title}>
                  {state?.name || state?.surname
                     ? getFIO(state?.name, state?.surname, state?.midname, "ser-name-mid")
                     : "Новый клиент"}
               </h2>
               <span className={card__link + " " + card__link_tel}>
                  <a href={"tel:+" + state?.phone_number}>
                     {formatPhone(state?.phone_number)}
                  </a>
                  <IconCopy
                     onClick={() =>
                        navigator.clipboard.writeText(formatPhone(state?.phone_number))
                     }
                  />
               </span>
               <span className={card__link + " " + card__link_email}>
                  <a href={"mailto:" + state?.email}>
                     {state?.email ? state?.email : "Еще не предоставлено"}
                  </a>
                  <IconCopy
                     onClick={() =>
                        navigator.clipboard.writeText(
                           state?.email ? state?.email : "Еще не предоставлено"
                        )
                     }
                  />
               </span>
            </div>
         </Card>
         {state?.id_loan ? (
            <Card
               type={state?.number ? "wrapper-link" : ""}
               id={state?.id_loan}
               className={container__section_2}
            >
               <span className={link__header}>
                  Текущий займ №{state?.number} <IconArrowRight />
               </span>
               <div className={link__block}>
                  <span className={link__title}>Ставка в день</span>
                  <span className={link__subtitle}>
                     {state?.rate.toLocaleString("ru-RU")} %
                  </span>
               </div>
               <div className={link__block}>
                  <span className={link__title}>Сумма займа</span>
                  <span className={link__subtitle}>
                     {(state?.total_amount / 100).toLocaleString("ru-RU")} ₽
                  </span>
               </div>
            </Card>
         ) : state?.apply_block ? (
            <LongNotice buttonText={"Разблокировать"} onClick={() => onOpen()}>
               <h2 className={card__title}>Заявки заблокированы</h2>
               <br />
               Отказано в займе:{" "}
               <b>
                  {state?.denied_time
                     ? new Date(state?.denied_time).toLocaleString("ru-RU")
                     : new Date().toLocaleDateString("ru-RU")}
               </b>
               .
               <br />
               Дата авто-разблокировки:{" "}
               <b>
                  ~
                  {state?.denied_end
                     ? new Date(state?.denied_end).toLocaleString("ru-RU")
                     : new Date().toLocaleDateString("ru-RU")}
               </b>
               .
            </LongNotice>
         ) : (
            <Card type={"wrapper"} className={container__section_2}>
               <span className={link__header}>У клиента нет активного займа.</span>
               <div className={link__block}>
                  <span className={link__title}>Ставка в день</span>
                  <span className={link__subtitle}>0.00 %</span>
               </div>
               <div className={link__block}>
                  <span className={link__title}>Сумма займа</span>
                  <span className={link__subtitle}>0.00 ₽</span>
               </div>
            </Card>
         )}
         <Accordion className={container__section_3}>
            {state?.pasport ? (
               <>
                  <AccordionItem title={"Паспортные данные"}>
                     <div className={passport}>
                        <div className={passport__block}>
                           <span>Паспорт:</span>
                           <span
                              className={field}
                              onClick={() => navigator.clipboard.writeText(state.pasport)}
                           >
                              {getPasport()}
                           </span>
                        </div>
                        <div className={passport__block}>
                           <span>Дата рождения:</span>
                           <span
                              className={field}
                              onClick={() => navigator.clipboard.writeText(state.bday)}
                           >
                              {new Date(state.bday).toLocaleDateString("ru-RU")}
                           </span>
                        </div>
                        <div className={passport__block}>
                           <span>Место рождения:</span>
                           <span
                              className={field}
                              onClick={() => navigator.clipboard.writeText(state.bplace)}
                           >
                              {state.bplace}
                           </span>
                        </div>
                        <div className={passport__block}>
                           <span>Код подразделения:</span>
                           <span
                              className={field}
                              onClick={() =>
                                 navigator.clipboard.writeText(state.pasportOrgCode)
                              }
                           >
                              {getCode()}
                           </span>
                        </div>
                        <div className={passport__block}>
                           <span>Дата выдачи:</span>
                           <span
                              className={field}
                              onClick={() =>
                                 navigator.clipboard.writeText(state.pasportDateGet)
                              }
                           >
                              {new Date(state.pasportDateGet).toLocaleDateString("ru-RU")}
                           </span>
                        </div>
                        <div className={passport__block}>
                           <span>Кем выдан:</span>
                           <span
                              className={field}
                              onClick={() =>
                                 navigator.clipboard.writeText(state.pasportOrg)
                              }
                           >
                              {state.pasportOrg}
                           </span>
                        </div>
                        {state.inn ? (
                           <>
                              <div className={passport__block}>
                                 <span>ИНН:</span>
                                 <span
                                    className={field}
                                    onClick={() =>
                                       navigator.clipboard.writeText(state.inn)
                                    }
                                 >
                                    {state.inn}
                                 </span>
                              </div>
                           </>
                        ) : null}
                        <div className={passport__block}>
                           <span>Адрес регистрации:</span>
                           <span
                              className={field}
                              onClick={() =>
                                 navigator.clipboard.writeText(state.registration)
                              }
                           >
                              {state.registration}
                           </span>
                        </div>
                        {state.isOnReg === 1 ? (
                           <div className={passport__block}>
                              <span>Адрес проживания:</span>
                              <span
                                 className={field}
                                 onClick={() =>
                                    navigator.clipboard.writeText(state.living)
                                 }
                              >
                                 {state.living}
                              </span>
                           </div>
                        ) : null}
                        <hr />
                        <div className={passport + " " + passport_row}>
                           <div className={passport__block + " " + passport__block_col}>
                              <span>Фотография разворота:</span>
                              <a
                                 href={state.photo1}
                                 target={"_blank"}
                                 title={"Открыть в новой вкладке " + state.photo1}
                                 rel="noreferrer"
                                 className={field}
                              >
                                 <img
                                    src={
                                       state.photo1
                                          ? state.photo1
                                          : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                                    }
                                    alt="Фотография разворота паспорта"
                                    width={"256px"}
                                    height={"128px"}
                                 />
                              </a>
                           </div>
                           <div className={passport__block + " " + passport__block_col}>
                              <span>Фотография регистрации:</span>
                              <a
                                 href={state.photo2}
                                 target={"_blank"}
                                 title={"Открыть в новой вкладке " + state.photo2}
                                 rel="noreferrer"
                                 className={field}
                              >
                                 <img
                                    src={
                                       state.photo2
                                          ? state.photo2
                                          : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                                    }
                                    alt="Фотография регистрации"
                                    width={"256px"}
                                    height={"128px"}
                                 />
                              </a>
                           </div>
                           <div className={passport__block + " " + passport__block_col}>
                              <span>Фотография с паспортом:</span>
                              <a
                                 href={state.photo3}
                                 target={"_blank"}
                                 title={"Открыть в новой вкладке " + state.photo3}
                                 rel="noreferrer"
                                 className={field}
                              >
                                 <img
                                    src={
                                       state.photo3
                                          ? state.photo3
                                          : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                                    }
                                    alt="Фотография с паспортом"
                                    width={"256px"}
                                    height={"128px"}
                                 />
                              </a>
                           </div>
                           <div className={passport__block + " " + passport__block_col}>
                              <span>Фотография СНИЛСа:</span>
                              <a
                                 href={state.photo4}
                                 target={"_blank"}
                                 title={"Открыть в новой вкладке  " + state.photo4}
                                 rel="noreferrer"
                                 className={field}
                              >
                                 <img
                                    src={
                                       state.photo4
                                          ? state.photo4
                                          : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                                    }
                                    alt="Фотография СНИЛСа"
                                    width={"256px"}
                                    height={"128px"}
                                 />
                              </a>
                           </div>
                        </div>
                     </div>
                  </AccordionItem>
                  <AccordionItem title={"Данные о доходе"}>
                     {state?.jobType !== "" ? (
                        <div className={passport}>
                           <div className={passport__block}>
                              <span>Вид занятости:</span>
                              <span
                                 className={field}
                                 onClick={() =>
                                    navigator.clipboard.writeText(state?.jobType)
                                 }
                              >
                                 {state?.jobType !== ""
                                    ? jobTypeArray[state?.jobType]
                                    : "Не указано"}
                              </span>
                           </div>
                           {state?.jobType === 0 ? (
                              <>
                                 <div className={passport}>
                                    <div className={passport__block}>
                                       <span>Место работы:</span>
                                       <span
                                          className={field}
                                          onClick={() =>
                                             navigator.clipboard.writeText(
                                                state.jobPlace
                                                   ? state.jobPlace
                                                   : "Не указано"
                                             )
                                          }
                                          dangerouslySetInnerHTML={{
                                             __html: state.jobPlace
                                                ? state.jobPlace
                                                : "Не указано",
                                          }}
                                       ></span>
                                    </div>
                                 </div>
                                 <div className={passport}>
                                    <div className={passport__block}>
                                       <span>Должность:</span>
                                       <span
                                          className={field}
                                          onClick={() =>
                                             navigator.clipboard.writeText(
                                                state.jobPosition
                                             )
                                          }
                                       >
                                          {state.jobPosition
                                             ? state.jobPosition
                                             : "Не указано"}
                                       </span>
                                    </div>
                                 </div>
                                 <div className={passport}>
                                    <div className={passport__block}>
                                       <span>Адрес организации:</span>
                                       <span
                                          className={field}
                                          onClick={() =>
                                             navigator.clipboard.writeText(
                                                state.jobAddress
                                             )
                                          }
                                       >
                                          {state.jobAddress
                                             ? state.jobAddress
                                             : "Не указано"}
                                       </span>
                                    </div>
                                 </div>
                                 <div className={passport}>
                                    <div className={passport__block}>
                                       <span>Номер телефона:</span>
                                       <a
                                          href={"tel:+7" + state.jobPhone}
                                          className={field}
                                          onClick={() =>
                                             navigator.clipboard.writeText(
                                                formatPhone(state.jobPhone)
                                             )
                                          }
                                       >
                                          {state.jobPhone
                                             ? formatPhone(state.jobPhone)
                                             : "Не указано"}
                                       </a>
                                    </div>
                                 </div>
                                 <div className={passport}>
                                    <div className={passport__block}>
                                       <span>Ближайшая выплата:</span>
                                       <span
                                          className={field}
                                          onClick={() =>
                                             navigator.clipboard.writeText(
                                                state.nextIncome || state.nextIncome === 0
                                                   ? nextIncomeArray[state.nextIncome]
                                                   : "Не указано"
                                             )
                                          }
                                       >
                                          {state.nextIncome || state.nextIncome === 0
                                             ? nextIncomeArray[state.nextIncome]
                                             : "Не указано"}
                                       </span>
                                    </div>
                                 </div>
                              </>
                           ) : null}
                           <div className={passport}>
                              <div className={passport__block}>
                                 <span>Ежемесячный доход:</span>
                                 <span
                                    className={field}
                                    onClick={() =>
                                       navigator.clipboard.writeText(
                                          state.income || state.income === 0
                                             ? incomeArray[state.income]
                                             : "Не указано"
                                       )
                                    }
                                 >
                                    {state.income || state.income === 0
                                       ? incomeArray[state.income]
                                       : "Не указано"}
                                 </span>
                              </div>
                           </div>
                        </div>
                     ) : (
                        <div className={passport}>
                           <div className={passport__block}>
                              <span>Информация отсутвует</span>
                           </div>
                        </div>
                     )}
                  </AccordionItem>

                  <AccordionItem title={"Выпуск виртуальной карты"}>
                     <div className={acord}>
                        <div className={notice}>
                           <p className={notice__text}>
                              {
                                 state?.virtual_card.status === "none" ? "Виртуальная карта клиента" : (
                                    <b>
                                       Карта: {
                                          state?.virtual_card.number
                                             .toString()
                                             .split(/(\d{4})/)
                                             .filter((item) => item !== "")
                                             .join(" ")
                                       } | Действительна до {
                                          state?.virtual_card.month +
                                          " / " +
                                          state?.virtual_card.year
                                       }
                                    </b>
                                 )
                              }
                           </p>
                           <div className={notice__actions}>
                              {state?.virtual_card.status === "none" ? (
                                 <button
                                    className={notice__button}
                                    type="button"
                                    onClick={() => onOpenAddCard()}
                                 >
                                    Добавить
                                 </button>
                              ) : (
                                 <>
                                    <button
                                       className={
                                          notice__button + " " + notice__button_red
                                       }
                                       type="button"
                                       onClick={() => onOpenCancelCard()}
                                    >
                                       Отключить карту
                                    </button>
                                 </>
                              )}
                           </div>
                        </div>
                        {state?.virtual_card.status !== "none" ? (
                           <ul className={about}>
                              
                              <li className={about_item}>
                                 <span className={about_text + " " + about_text_nums}>
                                    CVC:{" " + state?.virtual_card.cvc + " "}
                                    |
                                    Лимит: {" " +
                                       state?.virtual_card.card_limit.toLocaleString(
                                          "ru-RU"
                                       ) +
                                       " ₽"}
                                 </span>
                              </li>
                              <li className={about_item + " " + about_text_nums}>
                                 <span className={about_text + " " + about_text_nums}>
                                    {
                                       state?.virtual_card.document ? (
                                          <a
                                             href={state?.virtual_card.document}
                                             target="_blank"
                                             className={about_text + " " + about_text_link}
                                             rel="noreferrer noopenner"
                                          >Открыть договор</a>
                                       ) : (
                                          <span className={about_text + " " + about_text_nums}>
                                             Договор не подписан
                                          </span>)
                                    }
                                 </span>
                              </li>
                              <li className={about_item + " " + about_text_nums}>
                              </li>
                           </ul>
                        ) : null}

                        <small className={about_subtext}>
                           Если у вас возникли проблемы с предоставлением доступа{" "}
                           <br /> или отключением виртуальной карты от системы,
                           обратитесь в{" "}
                           <Link to={"/manage/support"}>службу поддержки</Link>.
                        </small>
                     </div>
                  </AccordionItem>
               </>
            ) : null}
            {row.length > 0 ? (
               <AccordionItem title={"История займов"}>
                  <Table
                     rows={row}
                     colums={[
                        {
                           paramName: "number",
                           name: "Номер договора",
                        },
                        {
                           paramName: "totaol_amount",
                           name: "Сумма",
                        },
                        {
                           paramName: "status",
                           name: "Статус",
                        },
                        {
                           paramName: "action",
                           name: "Действия",
                        },
                     ]}
                     onSort={(param, direction) => setSort([param, direction])}
                     sortParam={sort[0]}
                     sortDirection={sort[1]}
                     baseColumnsWidth={[null, 256, 256, 160]}
                     blockOnEmpty={
                        <EmptyData
                           title="Нет данных"
                           text="Данные отсутвуют. Вероятно, что у клиента еще не было займов."
                        />
                     }
                     pagination={pagination}
                     onPaginate={onPaginate}
                  />
               </AccordionItem>
            ) : null}
            <AccordionItem title={"Действия учётной записью"}>
               <div className={acord}>
                  <h4>Блокировка личного кабинета клиента</h4>
                  <LongNotice
                     buttonText={state?.status ? "Заблокировать" : "Разблокировать"}
                     onClick={() => onOpenBlock()}
                     size={state?.status ? "long_no_icon_yellow" : "long_no_icon"}
                  >
                     {state?.status
                        ? "Заблокировать доступ в личный кабинет?"
                        : "Разблокировать доступ в личный кабинет? Заблокирован по причине: " +
                          state?.block_reason}
                  </LongNotice>
                  <h4>Удаление учётную запись клиента</h4>
                  <LongNotice
                     buttonText={"Удалить"}
                     onClick={() => onOpenDelete()}
                     size="long"
                  >
                     Удаление учётной записи клиента.
                  </LongNotice>
               </div>
            </AccordionItem>
         </Accordion>
         {isActive ? (
            <PopUp title={"Снять ограничения по заявкам?"} onCloase={() => onCloas()}>
               <form action="" onSubmit={onSubmit} className={form}>
                  <p className={form__text}>
                     Если вы снимете ограничение на оформление заявки: клиент сможет
                     оставить новую заявку. <br /> Вы уверены, что хотите снять
                     ограничение?
                  </p>
                  <div className={form__buttons}>
                     <Button variant={"gray"} onClick={() => onCloas()}>
                        Отмена
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        Разблокировать
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isBlock ? (
            <PopUp
               title={
                  state?.status
                     ? "Заблокировать личный кабинет?"
                     : "Разблокировать личный кабинет?"
               }
               onCloase={() => onCloasBlock()}
            >
               <form
                  action=""
                  onSubmit={state?.status ? onSubmitBlock : onSubmitUnBlock}
                  className={form}
               >
                  {state?.status ? (
                     <>
                        <small>
                           Укажите причину блокировки клиента. Это поможет в будущем
                           избежать ошибочной блокировки.
                        </small>
                        <Input
                           className={textaria}
                           label={"Причина блокировки"}
                           type="textarea"
                           value={valueBlock}
                           onChange={(e) => setValueBlock(e[0])}
                           error={valueBlockError}
                        />
                     </>
                  ) : (
                     <p className={form__text}>
                        Заблокирован по причине: <b>{state?.block_reason}</b>
                        <br />
                        <br />
                        Вы уверены, что хотите разблокировать личный кабинет клиента?
                     </p>
                  )}
                  <div className={form__buttons}>
                     <Button variant={"gray"} onClick={() => onCloasBlock()}>
                        Отмена
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        {state?.status ? "Заблокировать" : "Разблокировать"}
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isDelete ? (
            <PopUp title={"Удалить учётную запись?"} onCloase={() => onCloasDelete()}>
               <form action="" onSubmit={onSubmitDelete} className={form}>
                  <p className={form__text}>
                     Удаление учётной записи необратимо: паспортные данные, сканы
                     документов, информация о трудовой деятельности и история займов будут
                     безвозвратно удалены из системы. Так же будут удалён активный займ,
                     если он имеется. <br />
                     <br /> Подтвердите свои дествия, введя пароль учётной записи.
                  </p>
                  <Input
                     label={"Пароль"}
                     placeholder={"******"}
                     type={"password"}
                     value={password}
                     onChange={setPassword}
                     error={passwordError}
                     disabled={wait}
                  />
                  <div className={form__buttons}>
                     <Button variant={"gray"} onClick={() => onCloasDelete()}>
                        Отмена
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        Удалить
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isAddCard ? (
            <PopUp
               title={"Предоставить виртуальную карту"}
               onCloase={() => onCloasAddCard()}
            >
               <form onSubmit={onAddCard} className={form}>
                  <small>Внимательно укажите данные карты клиента.</small>
                  <Input
                     label={"Номер карты"}
                     type="number"
                     placeholder={"0000 0000 0000 0000"}
                     mask={"XXXX XXXX XXXX XXXX"}
                     value={cardNumber}
                     onChange={(e) => setCardNumber(e)}
                     error={ErrorCardNumber}
                  />
                  <div className={form__fields}>
                     <Input
                        className={form__buttons_min}
                        label={"CVC код"}
                        type="number"
                        placeholder={"123"}
                        mask={"XXX"}
                        value={cvc}
                        onChange={(e) => setCVC(e)}
                        error={ErrorCvc}
                     />
                     <Input
                        className={form__buttons_min}
                        label={"Месяц"}
                        type="number"
                        placeholder={"03"}
                        mask={"XX"}
                        value={cardMonth}
                        onChange={(e) => setCardMonth(e)}
                        error={ErrorCardMonth}
                     />
                     <Input
                        className={form__buttons_min}
                        label={"Год"}
                        type="number"
                        placeholder={"26"}
                        mask={"XX"}
                        value={cardYear}
                        onChange={(e) => setCardYear(e)}
                        error={ErrorCardYear}
                     />
                  </div>
                  <Input
                     label={"Лимит по карте"}
                     type="number"
                     placeholder={"120000"}
                     value={cardLimit}
                     onChange={(e) => setCardLimit(e)}
                     error={ErrorCardLimit}
                  />
                  <div className={form__buttons}>
                     <Button variant={"gray"} onClick={() => onCloasAddCard()}>
                        Отмена
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        Предоставить
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isCancelCard ? (
            <PopUp
               title={"Отключить карту от Slon Finance"}
               onCloase={() => onCloasCancelCard()}
            >
               <form onSubmit={onCancelCard} className={form}>
                  <p>
                     Отключение карты приведет к её удалению из системы, однако, если
                     пользователь добавил карту в Apple Pay или Google Pay (и др.
                     платёжные кошельки), он сможет пользоваться ей. <br />
                     <br />
                     Обратите внимание, что карта будет удалена из системы Slon Finance.
                     Для блокировки карты, обратитесь в банк, выпустивший карту.
                  </p>
                  <div className={form__buttons}>
                     <Button variant={"gray"} onClick={() => onCloasCancelCard()}>
                        Отмена
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        Отменить услугу
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
      </div>
   ) : (
      <div
         style={{
            minHeight: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
         }}
      >
         <ReactLoading type="spin" color="#2D9614" />
      </div>
   );
}

export default Customer;
