import React from "react";
import { useRef } from "react";
import Slider from "react-slick";
import IconArrowSlider from "../Icons/ArrowSlider";
import IconBanknote from "../Icons/Banknote";
import IconClock from "../Icons/Clock";
import IconLaptop from "../Icons/Laptop";

import classes from "./index.module.scss";
import "./slick.scss";

const {
   instruction,
   instruction__title,
   instruction__wrapper,
   slider__slide,
   slider__header,
   slider__title,
   slider__subtitle,
} = classes;

function MainInstruction({
   data,

   blockIndex = -1,
   onChange = () => {},
   editable = false
}) {

   const settings = {
      dots: true,
      infinite: false,
      fade: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
   };

   const icons = [<IconLaptop />, <IconClock />, <IconBanknote />];

   const defaultHeader = useRef(data.title).current;
   const defaultData = [];

   data.steps.forEach (step => defaultData.push({
      Title: () => useRef(step.title).current,
      Text: () => useRef(step.text).current
   }));

   return (
      <section className={instruction}>
         <h2
            className={instruction__title}
            contentEditable={editable} suppressContentEditableWarning={true}
            value={data.title}
            onChange={e => onChange(blockIndex, "title", e.target.textContent)}
            onBlur={e => onChange(blockIndex, "title", e.target.textContent)}
         >
            {defaultHeader}
         </h2>
         <div className={instruction__wrapper}>
            <Slider {...settings}>
               {data.steps.map((step, key) => (
                  <div className={slider__slide} key={key}>
                     {icons[key]}
                     <div className={slider__header}>
                        <h3
                           className={slider__title}

                           contentEditable={editable} suppressContentEditableWarning={true}
                           value={step.title}

                           onChange = {e => onChange(blockIndex, "steps."+key+".title", e.target.textContent)}
                           onBlur = {e => onChange(blockIndex, "steps."+key+".title", e.target.textContent)}
                        >
                           {defaultData[key].Title()}
                        </h3>
                        <span
                           className={slider__subtitle}

                           contentEditable={editable} suppressContentEditableWarning={true}
                           value={step.description}

                           onChange = {e => onChange(blockIndex, "steps."+key+".text", e.target.textContent)}
                           onBlur = {e => onChange(blockIndex, "steps."+key+".text", e.target.textContent)}
                        >
                           {defaultData[key].Text()}
                        </span>
                     </div>
                  </div>
               ))}
            </Slider>
         </div>
      </section>
   );
}

export default MainInstruction;

function SampleNextArrow(props) {
   const { className, onClick } = props;
   return (
      <div className={className} onClick={onClick}>
         <IconArrowSlider />
      </div>
   );
}

function SamplePrevArrow(props) {
   const { className, onClick } = props;
   return (
      <div className={className} onClick={onClick}>
         <IconArrowSlider />
      </div>
   );
}
