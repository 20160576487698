import React from "react";

export default function IconPensive(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" fill="none" viewBox="0 0 64 65" {...props}>
         <path
            fill="url(#paint0_radial_751_3795)"
            d="M31.998 60.496c18.668 0 27.998-12.536 27.998-28 0-15.46-9.33-27.996-28-27.996C13.33 4.5 4 17.036 4 32.498c0 15.462 9.328 27.998 27.998 27.998z"
         ></path>
         <path
            fill="url(#paint1_radial_751_3795)"
            d="M31.998 60.496c18.668 0 27.998-12.536 27.998-28 0-15.46-9.33-27.996-28-27.996C13.33 4.5 4 17.036 4 32.498c0 15.462 9.328 27.998 27.998 27.998z"
         ></path>
         <path
            fill="url(#paint2_radial_751_3795)"
            d="M31.998 60.496c18.668 0 27.998-12.536 27.998-28 0-15.46-9.33-27.996-28-27.996C13.33 4.5 4 17.036 4 32.498c0 15.462 9.328 27.998 27.998 27.998z"
         ></path>
         <path
            fill="url(#paint3_radial_751_3795)"
            fillOpacity="0.6"
            d="M31.998 60.496c18.668 0 27.998-12.536 27.998-28 0-15.46-9.33-27.996-28-27.996C13.33 4.5 4 17.036 4 32.498c0 15.462 9.328 27.998 27.998 27.998z"
         ></path>
         <path
            fill="url(#paint4_radial_751_3795)"
            d="M31.998 60.496c18.668 0 27.998-12.536 27.998-28 0-15.46-9.33-27.996-28-27.996C13.33 4.5 4 17.036 4 32.498c0 15.462 9.328 27.998 27.998 27.998z"
         ></path>
         <path
            fill="url(#paint5_radial_751_3795)"
            d="M31.998 60.496c18.668 0 27.998-12.536 27.998-28 0-15.46-9.33-27.996-28-27.996C13.33 4.5 4 17.036 4 32.498c0 15.462 9.328 27.998 27.998 27.998z"
         ></path>
         <path
            fill="url(#paint6_radial_751_3795)"
            d="M31.998 60.496c18.668 0 27.998-12.536 27.998-28 0-15.46-9.33-27.996-28-27.996C13.33 4.5 4 17.036 4 32.498c0 15.462 9.328 27.998 27.998 27.998z"
         ></path>
         <path
            fill="url(#paint7_radial_751_3795)"
            d="M31.998 60.496c18.668 0 27.998-12.536 27.998-28 0-15.46-9.33-27.996-28-27.996C13.33 4.5 4 17.036 4 32.498c0 15.462 9.328 27.998 27.998 27.998z"
         ></path>
         <path
            stroke="url(#paint8_linear_751_3795)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M14 34.5s2 2 6 2 6-2 6-2"
         ></path>
         <path
            stroke="url(#paint9_linear_751_3795)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M38 34.5s2 2 6 2 6-2 6-2"
         ></path>
         <path
            stroke="url(#paint10_linear_751_3795)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.25"
            d="M21 17.5s-1 6-8 6"
         ></path>
         <path
            stroke="url(#paint11_radial_751_3795)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.25"
            d="M21 17.5s-1 6-8 6"
         ></path>
         <path
            stroke="url(#paint12_radial_751_3795)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.25"
            d="M43 17.5s1 6 8 6"
         ></path>
         <path stroke="url(#paint13_linear_751_3795)" strokeLinecap="round" strokeWidth="2" d="M26 50.5h12"></path>
         <defs>
            <radialGradient
               id="paint0_radial_751_3795"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(132.839 21.463 20.38) scale(75.0066)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFF478"></stop>
               <stop offset="0.475" stopColor="#FFB02E"></stop>
               <stop offset="1" stopColor="#F70A8D"></stop>
            </radialGradient>
            <radialGradient
               id="paint1_radial_751_3795"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(131.878 21.368 20.636) scale(77.8974)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFF478"></stop>
               <stop offset="0.475" stopColor="#FFB02E"></stop>
               <stop offset="1" stopColor="#F70A8D"></stop>
            </radialGradient>
            <radialGradient
               id="paint2_radial_751_3795"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(101.31 5.547 25.866) scale(35.6932 45.7162)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.788" stopColor="#F59639" stopOpacity="0"></stop>
               <stop offset="0.973" stopColor="#FF7DCE"></stop>
            </radialGradient>
            <radialGradient
               id="paint3_radial_751_3795"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-58 58 -58 -58 36 28.5)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.315" stopOpacity="0"></stop>
               <stop offset="1"></stop>
            </radialGradient>
            <radialGradient
               id="paint4_radial_751_3795"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(77.692 -5.42 37.118) scale(56.2938)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.508" stopColor="#7D6133" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#715B32"></stop>
            </radialGradient>
            <radialGradient
               id="paint5_radial_751_3795"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(15.00002 21.99992 -15.94666 10.87277 33 33.5)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFB849"></stop>
               <stop offset="1" stopColor="#FFB847" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint6_radial_751_3795"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(22.99996 4 -4 22.99996 41 36.5)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFA64B"></stop>
               <stop offset="0.9" stopColor="#FFAE46" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint7_radial_751_3795"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(43.971 -20.273 58.596) scale(118.106)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.185" stopOpacity="0"></stop>
               <stop offset="1" stopOpacity="0.4"></stop>
            </radialGradient>
            <linearGradient
               id="paint8_linear_751_3795"
               x1="22.4"
               x2="21.93"
               y1="34"
               y2="36.822"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#52383E"></stop>
               <stop offset="1" stopColor="#432A35"></stop>
            </linearGradient>
            <linearGradient
               id="paint9_linear_751_3795"
               x1="46.4"
               x2="45.93"
               y1="34"
               y2="36.822"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#52383E"></stop>
               <stop offset="1" stopColor="#432A35"></stop>
            </linearGradient>
            <linearGradient
               id="paint10_linear_751_3795"
               x1="19"
               x2="19"
               y1="16.5"
               y2="19.5"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.03" stopColor="#524049"></stop>
               <stop offset="1" stopColor="#4A2C42"></stop>
            </linearGradient>
            <radialGradient
               id="paint11_radial_751_3795"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(5 7 -7 5 14 16.5)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.542" stopColor="#493032"></stop>
               <stop offset="0.807" stopColor="#5E445A"></stop>
               <stop offset="0.955" stopColor="#4A2C42"></stop>
            </radialGradient>
            <radialGradient
               id="paint12_radial_751_3795"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(125.538 20.755 21.116) scale(8.60232)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C2B4B5"></stop>
               <stop offset="0.887" stopColor="#462B45"></stop>
               <stop offset="1" stopColor="#4A2C42"></stop>
            </radialGradient>
            <linearGradient id="paint13_linear_751_3795" x1="0" x2="0" y1="0" y2="0" gradientUnits="userSpaceOnUse">
               <stop stopColor="#4F3C43"></stop>
               <stop offset="1" stopColor="#512756"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}
