import React from "react";
import classes from "./index.module.scss";

const { image } = classes;

function Picture({ className, fileName, secondImges, alt, lazy, ftype = "jpg" }) {
   return (
      <picture>
         {ftype === "png" ? (
            <>
               <source
                  media="(max-width: 526px)"
                  srcSet={
                     fileName
                        ? `/assets/${secondImges[1]}@1x.webp 1x, /assets/${secondImges[1]}@2x.webp 2x`
                        : ""
                  }
                  type="image/webp"
               />
               <source
                  media="(max-width: 526px)"
                  srcSet={
                     fileName
                        ? `/assets/${secondImges[1]}@1x.png 1x, /assets/${secondImges[1]}@2x.png 2x`
                        : ""
                  }
                  type="image/png"
               />
               <source
                  media="(max-width: 921px)"
                  srcSet={
                     fileName
                        ? `/assets/${secondImges[0]}@1x.webp 1x, /assets/${secondImges[0]}@2x.webp 2x`
                        : ""
                  }
                  type="image/webp"
               />
               <source
                  media="(max-width: 921px)"
                  srcSet={
                     fileName
                        ? `/assets/${secondImges[0]}@1x.png 1x, /assets/${secondImges[0]}@2x.png 2x`
                        : ""
                  }
                  type="image/png"
               />
            </>
         ) : null}
         <source
            srcSet={
               fileName
                  ? `/assets/${fileName}@1x.webp 1x, /assets/${fileName}@2x.webp 2x`
                  : ""
            }
            type="image/webp"
         />
         <img
            className={className ? className : image}
            src={fileName ? `/assets/${fileName}@1x.${ftype}` : ""}
            alt={alt ? alt : ""}
            srcSet={
               fileName
                  ? `/assets/${fileName}@1x.${ftype} 1x, /assets/${fileName}@2x.${ftype} 2x`
                  : ""
            }
            loading={lazy ? "lazy" : ""}
            decoding="async"
         />
      </picture>
   );
}

export default Picture;
