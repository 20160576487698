import IconElephant from "../Icons/Elephant";
import IconPushpin from "../Icons/Pushpin";
import IconFlash from "../Icons/Flash";

import css from "./styles.module.scss";
import { useRef } from "react";

const {
   advantages,
   advantages__baseLayout,
   advantages__innerLayout,
   advantages__card,
   advantages__card_vertical,
   advantages__cardInfo,
   advantages__title,
   advantages__text,
   advantages__icon,
} = css;

const MainAdvantages = ({ data, blockIndex = -1, editable = false, onChange = () => {}, onClick = () => {} }) => {
   const advantagesArray = data.advantages;

   const defaultA1H = useRef(advantagesArray[0].title);
   const defaultA2H = useRef(advantagesArray[1].title);
   const defaultA3H = useRef(advantagesArray[2].title);

   const changeA1HText = (e) => {
      onChange(blockIndex, "advantages.0.title", e.target.innerHTML);
   }
   const changeA2HText = (e) => {
      onChange(blockIndex, "advantages.1.title", e.target.innerHTML);
   }
   const changeA3HText = (e) => {
      onChange(blockIndex, "advantages.2.title", e.target.innerHTML);
   }

   const defaultA1T = useRef(advantagesArray[0].text);
   const defaultA2T = useRef(advantagesArray[1].text);
   const defaultA3T = useRef(advantagesArray[2].text);

   const changeA1Text = (e) => {
      onChange(blockIndex, "advantages.0.text", e.target.innerHTML);
   }
   const changeA2Text = (e) => {
      onChange(blockIndex, "advantages.1.text", e.target.innerHTML);
   }
   const changeA3Text = (e) => {
      onChange(blockIndex, "advantages.2.text", e.target.innerHTML);
   }

   return (
      <section className={advantages}>
         <div className={advantages__baseLayout}>
            <div className={advantages__innerLayout}>
               <div className={advantages__card}>
                  <div className={advantages__cardInfo}>
                     <h2
                        className={advantages__title}
                        contentEditable={editable} suppressContentEditableWarning={true}
                        value={advantagesArray[0].title}
                        onInput={changeA1HText}
                        onBlur={changeA1HText}
                     >{defaultA1H.current}</h2>

                     <p
                        className={advantages__text}
                        contentEditable={editable} suppressContentEditableWarning={true}
                        value={advantagesArray[0].text}
                        onInput={changeA1Text}
                        onBlur={changeA1Text}
                     >{defaultA1T.current}</p>
                  </div>
                  <IconElephant className={advantages__icon} />
               </div>
               <div className={advantages__card}>
                  <div className={advantages__cardInfo}>
                     <h2
                        className={advantages__title}
                        contentEditable={editable} suppressContentEditableWarning={true}
                        value={advantagesArray[1].title}
                        onInput={changeA2HText}
                        onBlur={changeA2HText}
                     >{defaultA2H.current}</h2>

                     <p
                        className={advantages__text}
                        contentEditable={editable} suppressContentEditableWarning={true}
                        value={advantagesArray[1].text}
                        onInput={changeA2Text}
                        onBlur={changeA2Text}
                     >{defaultA2T.current}</p>
                  </div>
                  <IconPushpin className={advantages__icon} />
               </div>
            </div>
            <div className={advantages__card + " " + advantages__card_vertical}>
               <div className={advantages__cardInfo}>
                  <h2
                     className={advantages__title}
                     contentEditable={editable} suppressContentEditableWarning={true}
                     value={advantagesArray[2].title}
                     onInput={changeA3HText}
                     onBlur={changeA3HText}
                  >{defaultA3H.current}</h2>

                  <p
                     className={advantages__text}
                     contentEditable={editable} suppressContentEditableWarning={true}
                     value={advantagesArray[2].text}
                     onInput={changeA3Text}
                     onBlur={changeA3Text}
                  >{defaultA3T.current}</p>
               </div>
               <IconFlash className={advantages__icon} />
            </div>
         </div>
      </section>
   );
};

export default MainAdvantages;
