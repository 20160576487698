import React from "react";

export default function IconMoneyWind(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" fill="none" viewBox="0 0 64 65" {...props}>
         <g filter="url(#filter0_i_751_3755)">
            <path fill="#CBC8C7" d="M18.162 22.05v-3.56H7.764a.5.5 0 00-.5.5v.06a3 3 0 003 3h7.9-.002z"></path>
         </g>
         <g filter="url(#filter1_i_751_3755)">
            <path
               fill="#BDBFBD"
               d="M18.162 25.572V22.05h-7.64c-.277 0-.5.22-.5.494v.06c0 1.64 1.344 2.968 3 2.968h5.14z"
            ></path>
         </g>
         <g filter="url(#filter2_i_751_3755)">
            <path
               fill="#D6D2D1"
               d="M5.166 14.968a.5.5 0 00-.5.5v.06a3 3 0 003 3h8.6c.44 0 .8.36.8.8v5.244a1 1 0 001 1h4.168a5.302 5.302 0 100-10.604H5.164h.002z"
            ></path>
            <path
               fill="url(#paint0_radial_751_3755)"
               d="M5.166 14.968a.5.5 0 00-.5.5v.06a3 3 0 003 3h8.6c.44 0 .8.36.8.8v5.244a1 1 0 001 1h4.168a5.302 5.302 0 100-10.604H5.164h.002z"
            ></path>
         </g>
         <path
            fill="url(#paint1_linear_751_3755)"
            d="M36.72 10.462l-24.14 24.14a4.4 4.4 0 000 6.223L26.294 54.54a4.4 4.4 0 006.222 0l24.14-24.14a4.4 4.4 0 000-6.223L42.944 10.462a4.4 4.4 0 00-6.222 0z"
         ></path>
         <path
            fill="url(#paint2_linear_751_3755)"
            d="M36.72 10.462l-24.14 24.14a4.4 4.4 0 000 6.223L26.294 54.54a4.4 4.4 0 006.222 0l24.14-24.14a4.4 4.4 0 000-6.223L42.944 10.462a4.4 4.4 0 00-6.222 0z"
         ></path>
         <path
            fill="url(#paint3_linear_751_3755)"
            d="M36.72 10.462l-24.14 24.14a4.4 4.4 0 000 6.223L26.294 54.54a4.4 4.4 0 006.222 0l24.14-24.14a4.4 4.4 0 000-6.223L42.944 10.462a4.4 4.4 0 00-6.222 0z"
         ></path>
         <path
            fill="url(#paint4_radial_751_3755)"
            d="M36.72 10.462l-24.14 24.14a4.4 4.4 0 000 6.223L26.294 54.54a4.4 4.4 0 006.222 0l24.14-24.14a4.4 4.4 0 000-6.223L42.944 10.462a4.4 4.4 0 00-6.222 0z"
         ></path>
         <path
            fill="url(#paint5_radial_751_3755)"
            d="M36.72 10.462l-24.14 24.14a4.4 4.4 0 000 6.223L26.294 54.54a4.4 4.4 0 006.222 0l24.14-24.14a4.4 4.4 0 000-6.223L42.944 10.462a4.4 4.4 0 00-6.222 0z"
         ></path>
         <path
            fill="url(#paint6_radial_751_3755)"
            d="M32.8 6.544L8.66 30.684a4.4 4.4 0 000 6.223l13.714 13.714a4.4 4.4 0 006.222 0l24.14-24.14a4.4 4.4 0 000-6.223L39.024 6.544a4.4 4.4 0 00-6.222 0z"
         ></path>
         <g filter="url(#filter3_iii_751_3755)">
            <path
               fill="url(#paint7_linear_751_3755)"
               fillRule="evenodd"
               d="M35.11 8.866L10.984 32.994a1.157 1.157 0 000 1.636L24.65 48.296a1.156 1.156 0 001.634 0l24.128-24.128a1.155 1.155 0 000-1.636L36.746 8.866a1.157 1.157 0 00-1.636 0zm-24.944 23.31a2.314 2.314 0 000 3.272l13.666 13.664a2.314 2.314 0 003.27 0L51.23 24.986a2.314 2.314 0 000-3.272L37.564 8.05a2.314 2.314 0 00-3.27 0L10.166 32.176z"
               clipRule="evenodd"
            ></path>
         </g>
         <path
            fill="#289D5B"
            d="M40.629 27.95c2.785-2.785 2.96-7.125.392-9.694-2.57-2.57-6.91-2.394-9.695.391-2.785 2.786-2.96 7.126-.391 9.695 2.568 2.569 6.909 2.393 9.694-.392z"
         ></path>
         <path
            fill="url(#paint8_linear_751_3755)"
            d="M40.629 27.95c2.785-2.785 2.96-7.125.392-9.694-2.57-2.57-6.91-2.394-9.695.391-2.785 2.786-2.96 7.126-.391 9.695 2.568 2.569 6.909 2.393 9.694-.392z"
         ></path>
         <path
            fill="url(#paint9_radial_751_3755)"
            d="M40.629 27.95c2.785-2.785 2.96-7.125.392-9.694-2.57-2.57-6.91-2.394-9.695.391-2.785 2.786-2.96 7.126-.391 9.695 2.568 2.569 6.909 2.393 9.694-.392z"
         ></path>
         <g filter="url(#filter4_f_751_3755)">
            <path fill="#127531" d="M20.096 20.23l4.534-4.534L44.074 35.14l-4.704 4.704L20.096 20.23z"></path>
         </g>
         <g filter="url(#filter5_f_751_3755)">
            <path fill="#0B3F2E" d="M43.271 43.372l4.516-4.516-3.714-3.714-4.704 4.7 3.902 3.53z"></path>
         </g>
         <path
            fill="url(#paint10_linear_751_3755)"
            d="M19.709 19.636l4.771-4.772L48.337 38.72l-4.772 4.772-23.855-23.855z"
         ></path>
         <path
            fill="url(#paint11_linear_751_3755)"
            d="M19.709 19.636l4.771-4.772L48.337 38.72l-4.772 4.772-23.855-23.855z"
         ></path>
         <g filter="url(#filter6_iii_751_3755)">
            <path
               fill="url(#paint12_linear_751_3755)"
               d="M19.15 32.214a.946.946 0 011.338-1.336l.736.736c1.36-.826 2.744-.49 3.54.158.454.368.508.872.186 1.27a.926.926 0 01-1.302.134c-.26-.212-1.07-.77-2.074.236-1.44 1.44.426 3.374 2.096 1.704.714-.716 1.886-1.06 2.704-.924a3.009 3.009 0 011.98 1.19c.71 1.126.616 2.31-.026 3.336l.708.708a.946.946 0 01-1.336 1.338l-.694-.694c-1.134.734-2.466.728-3.606-.17-.4-.318-.5-.868-.184-1.268s.9-.47 1.3-.154c.2.158.522.34.872.376.298.034.67-.044 1.126-.5.57-.57.662-1.08.572-1.458-.196-1.082-1.506-1.356-2.28-.584-1.076.992-1.846 1.238-2.708 1.2a2.916 2.916 0 01-1.914-.83 2.946 2.946 0 01-.83-1.732 3.114 3.114 0 01.512-2.02l-.716-.716z"
            ></path>
         </g>
         <g filter="url(#filter7_ii_751_3755)">
            <path fill="#C8C2C3" d="M42.313 46.946h3.561v10.4a.5.5 0 01-.5.5h-.06a3 3 0 01-3-3v-7.9h-.002z"></path>
         </g>
         <g filter="url(#filter8_ii_751_3755)">
            <path fill="#C8C2C3" d="M38.752 46.946h3.56v7.64a.5.5 0 01-.5.5h-.06a3 3 0 01-3-3v-5.14z"></path>
         </g>
         <g filter="url(#filter9_ii_751_3755)">
            <path
               fill="#DBD7D7"
               d="M44.074 37.574a5.312 5.312 0 00-5.322 5.3v4.17a1 1 0 001.004 1h5.26a.802.802 0 01.804.8v8.598c0 1.656 1.348 3 3.012 3h.06a.5.5 0 00.504-.5V42.876a5.315 5.315 0 00-3.288-4.901 5.313 5.313 0 00-2.034-.401z"
            ></path>
         </g>
         <defs>
            <filter
               id="filter0_i_751_3755"
               width="11.65"
               height="3.81"
               x="6.514"
               y="18.24"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.75" dy="-0.25"></feOffset>
               <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.647059 0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_751_3755"></feBlend>
            </filter>
            <filter
               id="filter1_i_751_3755"
               width="8.891"
               height="3.772"
               x="9.271"
               y="21.8"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.75" dy="-0.25"></feOffset>
               <feGaussianBlur stdDeviation="0.375"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.580392 0 0 0 0 0.611765 0 0 0 0 0.584314 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_751_3755"></feBlend>
            </filter>
            <filter
               id="filter2_i_751_3755"
               width="22.869"
               height="10.854"
               x="4.666"
               y="14.718"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="-0.25"></feOffset>
               <feGaussianBlur stdDeviation="0.375"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.658824 0 0 0 0 0.623529 0 0 0 0 0.627451 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_751_3755"></feBlend>
            </filter>
            <filter
               id="filter3_iii_751_3755"
               width="42.82"
               height="42.615"
               x="9.288"
               y="7.374"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="0.2"></feOffset>
               <feGaussianBlur stdDeviation="0.125"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.0941176 0 0 0 0 0.509804 0 0 0 0 0.254902 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_751_3755"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.2"></feOffset>
               <feGaussianBlur stdDeviation="0.125"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.0941176 0 0 0 0 0.509804 0 0 0 0 0.254902 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_751_3755" result="effect2_innerShadow_751_3755"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="0.2"></feOffset>
               <feGaussianBlur stdDeviation="0.125"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.172549 0 0 0 0 0.709804 0 0 0 0 0.4 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect2_innerShadow_751_3755" result="effect3_innerShadow_751_3755"></feBlend>
            </filter>
            <filter
               id="filter4_f_751_3755"
               width="24.779"
               height="24.948"
               x="19.696"
               y="15.296"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_751_3755" stdDeviation="0.2"></feGaussianBlur>
            </filter>
            <filter
               id="filter5_f_751_3755"
               width="8.918"
               height="8.73"
               x="39.119"
               y="34.892"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_751_3755" stdDeviation="0.125"></feGaussianBlur>
            </filter>
            <filter
               id="filter6_iii_751_3755"
               width="10.593"
               height="10.594"
               x="18.797"
               y="30.524"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.1" dy="0.1"></feOffset>
               <feGaussianBlur stdDeviation="0.075"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.0901961 0 0 0 0 0.521569 0 0 0 0 0.223529 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_751_3755"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="0.1" dy="-0.1"></feOffset>
               <feGaussianBlur stdDeviation="0.05"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.784314 0 0 0 0 0.760784 0 0 0 0 0.752941 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_751_3755" result="effect2_innerShadow_751_3755"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.1" dy="0.1"></feOffset>
               <feGaussianBlur stdDeviation="0.05"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.996078 0 0 0 0 0.992157 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect2_innerShadow_751_3755" result="effect3_innerShadow_751_3755"></feBlend>
            </filter>
            <filter
               id="filter7_ii_751_3755"
               width="4.162"
               height="11.2"
               x="42.013"
               y="46.646"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="0.3" dy="-0.3"></feOffset>
               <feGaussianBlur stdDeviation="0.375"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.658824 0 0 0 0 0.631373 0 0 0 0 0.635294 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_751_3755"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.3" dy="-0.3"></feOffset>
               <feGaussianBlur stdDeviation="0.375"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.721569 0 0 0 0 0.682353 0 0 0 0 0.690196 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_751_3755" result="effect2_innerShadow_751_3755"></feBlend>
            </filter>
            <filter
               id="filter8_ii_751_3755"
               width="4.161"
               height="8.44"
               x="38.452"
               y="46.646"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="0.3" dy="-0.3"></feOffset>
               <feGaussianBlur stdDeviation="0.375"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.611765 0 0 0 0 0.603922 0 0 0 0 0.596078 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_751_3755"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.3" dy="-0.3"></feOffset>
               <feGaussianBlur stdDeviation="0.375"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.635294 0 0 0 0 0.611765 0 0 0 0 0.607843 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_751_3755" result="effect2_innerShadow_751_3755"></feBlend>
            </filter>
            <filter
               id="filter9_ii_751_3755"
               width="11.294"
               height="23.018"
               x="38.252"
               y="37.424"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="0.15" dy="-0.15"></feOffset>
               <feGaussianBlur stdDeviation="0.25"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.678431 0 0 0 0 0.686275 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_751_3755"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.5"></feOffset>
               <feGaussianBlur stdDeviation="0.375"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.964706 0 0 0 0 0.964706 0 0 0 0 0.956863 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_751_3755" result="effect2_innerShadow_751_3755"></feBlend>
            </filter>
            <radialGradient
               id="paint0_radial_751_3755"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(15.5847 0 0 2.7573 7.932 15.374)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E3E1E1"></stop>
               <stop offset="1" stopColor="#E3E1E1" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint1_linear_751_3755"
               x1="56.462"
               x2="29.132"
               y1="27.935"
               y2="55.264"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#12724A"></stop>
               <stop offset="1" stopColor="#116245"></stop>
            </linearGradient>
            <linearGradient
               id="paint2_linear_751_3755"
               x1="57.484"
               x2="54.588"
               y1="25.003"
               y2="27.899"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#1C8F60"></stop>
               <stop offset="1" stopColor="#1C8F60" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint3_linear_751_3755"
               x1="26.985"
               x2="29.983"
               y1="55.231"
               y2="52.232"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.557" stopColor="#195840"></stop>
               <stop offset="1" stopColor="#195840" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint4_radial_751_3755"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(114.806 7.374 33.593) scale(8.19347 7.77412)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.307" stopColor="#0E593C"></stop>
               <stop offset="1" stopColor="#0E593C" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint5_radial_751_3755"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-7.56252 6.75004 -1.43134 -1.60363 40.836 43.495)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.396" stopColor="#0F4F35"></stop>
               <stop offset="1" stopColor="#0F4F35" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint6_radial_751_3755"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(3.259 -592.536 139.925) scale(29.9688 39.3)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#31DE74"></stop>
               <stop offset="1" stopColor="#20CF5E"></stop>
            </radialGradient>
            <linearGradient
               id="paint7_linear_751_3755"
               x1="33.1"
               x2="29.76"
               y1="9.992"
               y2="45.364"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#24A35B"></stop>
               <stop offset="1" stopColor="#27A05A"></stop>
            </linearGradient>
            <linearGradient
               id="paint8_linear_751_3755"
               x1="34.87"
               x2="36.813"
               y1="25.532"
               y2="23.589"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.228" stopColor="#269553"></stop>
               <stop offset="1" stopColor="#269553" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint9_radial_751_3755"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(.54529 -7.42897 7.302 .53597 36.712 24.033)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.587" stopColor="#249755" stopOpacity="0"></stop>
               <stop offset="0.987" stopColor="#16793D"></stop>
            </radialGradient>
            <linearGradient
               id="paint10_linear_751_3755"
               x1="23.901"
               x2="42.781"
               y1="19.056"
               y2="37.936"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFD425"></stop>
               <stop offset="1" stopColor="#FFAD24"></stop>
            </linearGradient>
            <linearGradient
               id="paint11_linear_751_3755"
               x1="47.108"
               x2="42.916"
               y1="39.947"
               y2="35.755"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.894" stopColor="#CC8139"></stop>
               <stop offset="1" stopColor="#CC8139" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint12_linear_751_3755"
               x1="19.142"
               x2="29.028"
               y1="30.886"
               y2="40.772"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#EFEAEA"></stop>
               <stop offset="1" stopColor="#EAE3E3"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}
