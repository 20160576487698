import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router";

import Button from "../../components/Button";
import ConfirmTelephone from "../../components/ConfirmTelephone";
import ContentHead from "../../components/ContentHead";
import DocumentList from "../../components/DocumentList";
import DropDownList from "../../components/DropDownList";
import IconSbp from "../../components/Icons/Sbp";
import InfoCards from "../../components/InfoCards";
import Input from "../../components/Input";
import InputRadio from "../../components/InputRadio";
import List from "../../components/List";
import checkAuth from "../../helpers/checkAuth";
import api from "../../helpers/fetch";
import formatPhone from "../../helpers/formatPhone";
import logOut from "../../helpers/logout";

import scss from "./index.module.scss";

const {
   approved,
   approved__header,
   approved__confirm,
   approved__document,
   approved__service,
   approved__form,
   block,
   block__sbp,
   block__text,
   container,
   container__button,
} = scss;

function Approved() {

   const navigate = useNavigate();
   const [loanData, setLoanData] = useState(null);

   const [isOnReg, setIsOnReg] = useState(1);
   const yesNoArray = ["Да", "Нет"];

   const [bank, setBank] = useState(0);
   const [telephone, setTelephone] = useState(0);

   const [codeSended, setCodeSended] = useState(false);
   const [codeError, setCodeError]   = useState(false);

   const [phoneError, setPhoneError] = useState(false);

   const getLoanData = async () => {
      const auth = checkAuth();

      if (!auth) logOut();

      const resp = await new api("sign", auth).send();

      if (resp.status === "success") {
         setLoanData(resp.data);
      }
      else {
         navigate("/profile");
      }
   }

   useEffect(() => {
      getLoanData();
   }, []);

   const confirm = async (code = 0) => {
      if (/^[0-9]{6}$/.test(code)) {
         setCodeSended(true);

         const body = new FormData();
         body.append("code", code);

         const auth = checkAuth();

         if (!auth) logOut();

         const resp = await new api("sign", auth, {
            method: "POST",
            body
         }).send();

         if (resp.status === "success") {
            setLoanData({
               phone_number: loanData.phone_number,
               banks: resp.data.banks
            });
            setCodeSended(false);
         }
         else {
            setCodeSended(false);
            if (
               resp.message === "Code is empty" ||
               resp.message === "Code is invalid"
            )
            {
               setCodeError("Указанный код не совпадает с кодом, отправленным в СМС.");
            }
            else {
               navigate("/profile");
            }
         }
      }
   }

   const phoneInput = (e) => {
      setPhoneError(false);
      setTelephone(e);
   }

   const payout = async (e) => {
      e.preventDefault();

      const auth = checkAuth();
      if (!auth) logOut();

      const body = new FormData();

      const phone = isOnReg === 0 ? loanData.phone_number : telephone;

      body.append("phone", phone);
      body.append("bank", loanData.banks[bank]);

      const resp = await new api("payout", auth, {
         method: "POST",
         body
      }).send();

      if (resp.status === "success") {
         localStorage.setItem("resultPage", JSON.stringify({
            title: "Договор подписан",
            text: "Заём будет зачислен на Ваш счёт в&nbsp;банке <b style=\"white-space: nowrap;\">\""+loanData.banks[bank]+"\"</b> через систему быстрых платежей по&nbsp;номеру <b style=\"white-space: nowrap;\">"+formatPhone(phone)+"</b> в&nbsp;течении 24 часов.",
            buttonText: "Вернуться в личный кабинет",
            buttonLink: "/profile",
         }));
         navigate("/message");
      }
      else {
         if (resp.message === "Phone is empty" || resp.message === "Phone is invalid") {
            setPhoneError("Проверьте правильно ли указан номер телефона.");
         }
         else if (resp.message === "Bank is empty" || resp.message === "Bank is invalid") {

         }
         else if (resp.message === "Can't payout loan") {
            navigate(0);
         }
         else {
            navigate("/profile");
         }
      }
   }

   return loanData !== null ? (
      !loanData.banks ? (
         <div className={approved}>
            <section className={approved__header}>
               <ContentHead
                  title={"Поздравляем! Вам одобрен заём на лучших условиях!"}
                  subtitle={"Займ №" + loanData.number}
                  topSubtitle
               />
               <InfoCards data={[
                  {
                     title: "Сумма займа: ",
                     subtitle: loanData.total_amount / 100,
                     symbol: "₽"
                  },
                  {
                     title: "Ставка в день: ",
                     subtitle: loanData.rate,
                     symbol: "%"
                  }
               ]} />
            </section>
            <section className={approved__confirm}>
               <ConfirmTelephone
                  number={loanData.phone_number}
                  onConfirm={confirm}
                  disableInput={codeSended}
                  inputError={codeError}
               />
            </section>
            {
               loanData.documents.length > 0 ? (
                  <section className={approved__document}>
                     <ContentHead
                        title={"Информация по договору"}
                        subtitle={"Пожалуйста ознакомитесь с пакетом документов, который для вас сформирован:"}
                        bottomSubtitle
                     />
                     <DocumentList data={loanData.documents} />
                  </section>
               ) : ""
            }
            <section className={approved__service}>
               <ContentHead
                  title={"*В рамках дополнительной услуги \"Улучшение кредитной истории\""}
                  subtitle={
                     "Вы сможете улучшить свою кредитную историю и показать свою финансовую дисциплину для получения лучших условий по кредиту или займу."
                  }
                  bottomSubtitle
               />
               <List
                  text={"А ещё Ваша кредитная история:"}
                  data={[
                     "Будет восстановлена после успешного погашения займа.",
                     "Получить возможность пользоваться персональной процентной ставкой.",
                  ]}
                  subtext={"Подробнее ознакомиться со всеми условиями вы можете на нашем сайте на странице: Дополнительные услуги в рамках улучшения кредитной истории."}
               />
            </section>
         </div>
      ) : (
         <div className={approved}>
            <section className={approved__header}>
               <ContentHead title={"Выплата займа"} />
               <div className={block}>
                  <span className={block__text}>
                     Выплата займа производится через систему быстрых платежей. Деньги поступят на Ваш счёт в течении 24
                     часов.
                  </span>
                  <IconSbp className={block__sbp} />
               </div>
            </section>
            <form action="" method="POST" className={approved__form} onSubmit={payout}>
               <InputRadio
                  className={""}
                  label="Номер телефона в СБП совпадает с номером телефона указанным при регистрации?"
                  options={yesNoArray}
                  onChange={setIsOnReg}
                  name={"isOnReg"}
                  value={isOnReg}
               />
               <div className={container}>
                  <Input
                     label={"Номер телефона"}
                     placeholder="+7 (900) 800-70-60"
                     value={isOnReg === 0 ? loanData.phone_number.toString() : telephone}
                     key={isOnReg}
                     onChange={isOnReg === 0 ? () => {} : phoneInput}
                     mask="+7 (XXX) XXX-XX-XX"
                     regex={/^[0-9]$/}
                     error={phoneError}
                     disabled={!isOnReg ? true : false}
                  />
                  <DropDownList
                     label={"Банк получателя"}
                     options={loanData.banks}
                     value={bank}
                     onChange={setBank}
                  />
               </div>
               <Button
                  className={container__button}
                  type="submit"
                  disabled={
                     (
                        (!/^[0-9]{10}$/.test(telephone) && isOnReg === 1) ||
                        (!/^[0-9]{10}$/.test(loanData.phone_number) && isOnReg === 0)
                     ) ||
                     !/^[0-9а-яА-ЯёЁa-zA-Z \-.()]{3,100}$/.test(loanData.banks[bank])
                  }
               >
                  Получить
               </Button>
            </form>
         </div>
      )
   ) : (
      <div style={{minHeight: "70vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
         <ReactLoading type="spin" color="#2D9614" />
      </div>
   );
}

export default Approved;
