import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "../Button";

import IconLogo from "../Icons/Logo";
import IconBurger from "../Icons/Burger";

import css from "./styles.module.scss";
import ClientHeaderBlock from "../PersonalArea/ClientHeaderBlock";

const {
   header,
   header_white,
   header__logo,
   header__logoImg,
   header__nav,
   header__navList,
   header__navListItem,
   header__navListItemLink,
   header__button,
   header__menuButton,
   header__mobileMenuWrapper,
   header__mobileMenuWrapper_shown,
   header__mobileMenuBackground,
   header__mobileMenu,
   header__mobileMenu_shown,
   header__mobileMenuItem,
   header__mobileMenuList,
   header__mobileMenuButtons,
} = css;

const initState = window.initState
   ? window.initState
   : {
        menu_items: [
           {
              id_page: 0,
              title: "О нас",
              pathname: "o-nas",
           },
           {
              id_page: 1,
              title: "Документы",
              pathname: "dokumenti",
           },
           {
              id_page: 2,
              title: "Дополнительные услуги",
              pathname: "dopolnitelnie-uslugi",
           },
        ],
     };

function Header() {
   const location = useLocation();
   const [headerState, setHeaderState] = useState("white");

   const [mobileMenuState, setMobileMenuState] = useState([false, false]);

   const [menuItems] = useState(initState.menu_items ? initState.menu_items : []);

   const startMenuShowing = () => {
      setMobileMenuState([true, false]);
      document.body.style.overflow = "hidden";
      const timeOut = setTimeout(() => {
         setMobileMenuState([true, true]);
         clearTimeout(timeOut);
      }, 1);
   };

   const endMenuShowing = () => {
      setMobileMenuState([true, false]);
      document.body.style.overflow = null;
      const timeOut = setTimeout(() => {
         setMobileMenuState([false, false]);
         clearTimeout(timeOut);
      }, 300);
   };

   useEffect(() => {
      if (location.pathname === "/") {
         setHeaderState("white");
      } else {
         setHeaderState("default");
      }
   }, [location.pathname]);

   return (
      <>
         <header className={header + (headerState === "white" ? " " + header_white : "")}>
            <Link aria-label="Главная" to="/" className={header__logo}>
               <IconLogo className={header__logoImg} />
            </Link>

            <nav className={header__nav}>
               <ul className={header__navList}>
                  {menuItems.map((item) => (
                     <li className={header__navListItem} key={item.id_page}>
                        <Link
                           to={item.pathname !== "/" ? "/" + item.pathname : "/"}
                           className={header__navListItemLink}
                        >
                           {item.title}
                        </Link>
                     </li>
                  ))}
                  <li className={header__navListItem}>
                     <Link aria-label="Оплатить" to="/oplatit" className={header__navListItemLink}>
                        Оплатить
                     </Link>
                  </li>
               </ul>
            </nav>

            <ClientHeaderBlock variant={headerState === "white" ? "white" : ""} className={header__button}>
               <Button
                  aria-label="Личный кабинет"
                  href={"/profile"}
                  className={header__button}
                  variant={headerState === "white" ? "white" : ""}
               >
                  Личный кабинет
               </Button>
            </ClientHeaderBlock>

            <button className={header__menuButton} type="button" onClick={startMenuShowing}>
               <IconBurger />
            </button>
         </header>
         <div
            className={header__mobileMenuWrapper + (mobileMenuState[1] ? " " + header__mobileMenuWrapper_shown : "")}
            style={{ display: mobileMenuState[0] ? "flex" : "none" }}
         >
            <div className={header__mobileMenuBackground} onClick={endMenuShowing} />
            <div className={header__mobileMenu + (mobileMenuState[1] ? " " + header__mobileMenu_shown : "")}>
               <div className={header__mobileMenuList}>
                  <Button
                     aria-label="Главная"
                     href="/"
                     className={header__mobileMenuItem}
                     onClick={endMenuShowing}
                     variant="gray"
                  >
                     Главная
                  </Button>
                  <Button
                     aria-label="О нас"
                     href="/o-nas"
                     className={header__mobileMenuItem}
                     onClick={endMenuShowing}
                     variant="gray"
                  >
                     О нас
                  </Button>
                  <Button
                     aria-label="Документы"
                     href="/dokumenti"
                     className={header__mobileMenuItem}
                     onClick={endMenuShowing}
                     variant="gray"
                  >
                     Документы
                  </Button>
                  <Button
                     aria-label="Оплатить"
                     href="/oplatit"
                     className={header__mobileMenuItem}
                     onClick={endMenuShowing}
                     variant="gray"
                  >
                     Оплатить
                  </Button>
                  <Button
                     aria-label="Дополнительные услуги"
                     href="/dopolnitelnie-uslugi"
                     className={header__mobileMenuItem}
                     onClick={endMenuShowing}
                     variant="gray"
                  >
                     Дополнительные услуги
                  </Button>
               </div>
               <div className={header__mobileMenuButtons}>
                  <Button aria-label="Оформить займ" href="/application" className="" onClick={endMenuShowing}>
                     Оформить заём
                  </Button>
                  <ClientHeaderBlock variant="gray" onLogOut={endMenuShowing} onClick={endMenuShowing}>
                     <Button aria-label="Личный кабинет" href="/profile" className="" onClick={endMenuShowing}>
                        Личный кабинет
                     </Button>
                  </ClientHeaderBlock>
               </div>
            </div>
         </div>
      </>
   );
}

export default Header;
