import { useRef } from "react";
import Button from "../Button";
import IconAttach from "../Icons/Attach";
import ToolTipWrapper, { createInputToolTip } from "../ToolTipWrapper";
import classes from "./index.module.scss";

const { servicesCard, servicesCard__title, servicesCard__wrapper, card__wrapper, card__title, card__content } = classes;

function ServicesCard({
   data,

   editable = false,
   blockIndex = -1,
   onChange = () => {}
}) {

   const defaultHeader = useRef(data.title).current;
   const defaultCards = [];

   data.cards.forEach(card => {
      defaultCards.push({
         Title: () => useRef(card.title).current,
         Text: () => useRef(card.text).current,
         Info: () => useRef(card.info).current,
      });
   });

   return (
      <section className={servicesCard}>
         <h2
            className={servicesCard__title}

            contentEditable={editable} suppressContentEditableWarning={true}
            value={data.title}
            onChange={e => onChange(blockIndex, "title", e.target.textContent)}
            onBlur={e => onChange(blockIndex, "title", e.target.textContent)}
         >{defaultHeader}</h2>

         <div className={servicesCard__wrapper}>
            {data.cards.map((card, key) => (
               <article className={card__wrapper} key={key}>
                  <h3
                     className={card__title}

                     contentEditable={editable} suppressContentEditableWarning={true}
                     value={card.title}
                     onChange={e => onChange(blockIndex, "cards."+key+".title", e.target.textContent)}
                     onBlur={e => onChange(blockIndex, "cards."+key+".title", e.target.textContent)}
                  >{card.title}</h3>
                  <div className={card__content}>
                     <p
                        contentEditable={editable} suppressContentEditableWarning={true}
                        value={card.text}
                        onChange={e => onChange(blockIndex, "cards."+key+".text", e.target.textContent)}
                        onBlur={e => onChange(blockIndex, "cards."+key+".text", e.target.textContent)}
                     >{defaultCards[key].Text()}</p>
                     <span
                        contentEditable={editable} suppressContentEditableWarning={true}
                        value={card.info}
                        onChange={e => onChange(blockIndex, "cards."+key+".info", e.target.textContent)}
                        onBlur={e => onChange(blockIndex, "cards."+key+".info", e.target.textContent)}
                     >{defaultCards[key].Info()}</span>
                  </div>
                  <ToolTipWrapper
                     showOnHover={editable}
                     tooltipContent={createInputToolTip(
                        <IconAttach />,
                        "Ссылка",
                        "text",
                        card.href,
                        val => onChange(blockIndex, "cards."+key+".href", val)
                     )}
                  >
                     <Button
                        href={card.href}

                        contentEditable={editable}
                        value={card.button_text}
                        onChange={e => onChange(blockIndex, "cards."+key+".button_text", e.target.textContent)}
                     >{card.button_text}</Button>
                  </ToolTipWrapper>
               </article>
            ))}
         </div>
      </section>
   );
}

export default ServicesCard;
