import Container from "../Container";
import scss from "./index.module.scss";

const { instruction__list, instruction__item } = scss;

function Instruction({ title, instructions, subtext }) {
   return (
      <Container title={title} subtext={subtext}>
         <ul className={instruction__list}>
            {instructions?.map((item, key) => (
               <li className={instruction__item} key={key}>
                  {item}
               </li>
            ))}
         </ul>
      </Container>
   );
}

export default Instruction;
