import scss from "./index.module.scss";

const { wrapper, wrapper__text, wrapper__text_mrt, list, list__item } = scss;

function List({ text, data, subtext }) {
   return (
      <div className={wrapper}>
         {text ? <p className={wrapper__text}>{text}</p> : null}
         <ul className={list}>
            {data?.map((item, key) => (
               <li className={list__item} key={key}>
                  {item}
               </li>
            ))}
         </ul>
         {subtext ? <p className={wrapper__text + " " + wrapper__text_mrt}>{subtext}</p> : null}
      </div>
   );
}

export default List;
